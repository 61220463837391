import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { apiRoutes } from '@core/constants/api-routes.constants';
import { PrimaryLanguage } from '@views/qualification/constants';
import { ManageStatus } from '@core/services/models/status/manage-status.model';
import { map, Observable } from 'rxjs';
import { ResponseBase } from './models/base/responseBase.model';
import { AssociatedRecordPointer } from './models/micro-credential/associated-record-pointer.model';
import { IMicroCredential } from './models/micro-credential/micro-credential';
import { PostProgrammeResponse } from './models/programme/post-programme-response';
import {
  GetPagedProgrammeParameters,
  GetPagedProgrammes,
  IProgramme,
  IStrictProgramme
} from './models/programme/programme';
import { IQualification } from './models/qualification/qualification';
import { Lookup } from './models/shared/lookup';
import { IStandard } from './models/standard/standard';

@Injectable({
  providedIn: 'root'
})
export class ProgrammeService {
  private languagesOptions: Lookup<number>[] = [
    {
      text: 'English',
      value: PrimaryLanguage.English
    },
    {
      text: 'Māori',
      value: PrimaryLanguage.Maori
    }
  ];

  private fromOptions: Lookup<number>[];
  private toOptions: Lookup<number>[];

  constructor(private http: HttpClient) {}

  create(model: IProgramme): Observable<PostProgrammeResponse> {
    return this.http.post<PostProgrammeResponse>(apiRoutes.programme.create, model);
  }

  createNewDraftVersion(model: IProgramme): Observable<PostProgrammeResponse> {
    return this.http.post<PostProgrammeResponse>(apiRoutes.programme.createDraft(model.progNo!), model);
  }

  update(model: IProgramme): Observable<PostProgrammeResponse> {
    return this.http.put<PostProgrammeResponse>(apiRoutes.programme.update(model.progNo!), model);
  }

  get(progNo: string): Observable<IStrictProgramme[]> {
    return this.http.get<IStrictProgramme[]>(apiRoutes.programme.get(progNo));
  }

  paged(pagingParameters: GetPagedProgrammeParameters): Observable<GetPagedProgrammes> {
    return this.http.get<GetPagedProgrammes>(apiRoutes.programme.paged(pagingParameters.toQueryString()));
  }

  getCreateDraft(progNo: string): Observable<IStrictProgramme[]> {
    return this.http.get<IStrictProgramme>(apiRoutes.programme.createDraft(progNo)).pipe(map(x => [x]));
  }

  getById(progNo: string, id: string): Observable<IProgramme> {
    return this.http.get<IProgramme>(apiRoutes.programme.getById(progNo, id));
  }

  getProgrammeLanguagesOptions(): Lookup<number>[] {
    return this.languagesOptions;
  }

  getFromOptions(): Lookup<number>[] {
    this.fromOptions = this.generateLevelRange();
    return this.fromOptions;
  }

  getToOptions(minLevel: number = 1): Lookup<number>[] {
    this.toOptions = this.generateLevelRange();
    return this.toOptions.map(option => {
      if (option.value < minLevel) option.disabled = true;
      return option;
    });
  }

  generateLevelRange(maxLevel: number = 10): Lookup<number>[] {
    let options: Lookup<number>[] = [];
    for (let option = 1; option <= maxLevel; option++) {
      options.push({
        text: option.toString(),
        value: option
      });
    }
    return options;
  }

  updateStatus(progNo: string, status: ManageStatus) {
    return this.http.patch<ResponseBase>(apiRoutes.programme.updateStatus(progNo), status);
  }

  getAssociatedStandards(programNumber: string, version: number) {
    return this.http.get<IStandard[]>(apiRoutes.programme.standardAssociations(programNumber, version));
  }

  getAssociatedQualifications(progNo: string, verNo: number) {
    return this.http.get<IQualification[]>(apiRoutes.programme.qualificationAssociations(progNo, verNo));
  }

  getAssociatedMicroCredentials(progNo: string, verNo: number) {
    return this.http.get<IMicroCredential[]>(apiRoutes.programme.microCredentialAssociations(progNo, verNo));
  }

  updateAssociatedRecords(
    progNo: string,
    verNo: number,
    associatedRecords: AssociatedRecordPointer[]
  ): Observable<AssociatedRecordPointer[]> {
    return this.http.patch<AssociatedRecordPointer[]>(
      apiRoutes.programme.updateAssociatedRecords(progNo!, verNo),
      associatedRecords
    );
  }
}
