import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ControlValueAccessorConnector } from '../control-value-accessor-connector';

@Component({
  selector: 'app-text-area',
  templateUrl: './text-area.component.html',
  styleUrls: ['./text-area.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextAreaComponent),
      multi: true
    }
  ]
})
export class TextAreaComponent extends ControlValueAccessorConnector implements OnInit, ControlValueAccessor {
  @Input() height: number;
  @Input() hideWarning: boolean = false;
  @Input() value: string;
  @Input() attributeId = this.formControlName;
  @Input() labelMaori: string | null;

  get maxLengthError(): string {
    return `The maximum length of this field is ${this.maxlength} characters.`;
  }

  /**
   * This works somehow, but I honestly don't know why.
   * It must have something to do with the control value accessor.
   */
  @Input() maxlength: number = 1000;

  settings: any;

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.settings = {
      base_url: '/tinymce', // Root for resources
      suffix: '.min', // Suffix to use when loading resources
      tagName: this.formControlName,
      height: this.height,
      menubar: false,
      plugins: ['lists', 'link', 'help'],
      toolbar:
        !this.formGroup || this.control.disabled
          ? []
          : ['bold | italic | underline | bullist | numlist | link | unlink | undo | redo | help'],
      branding: false,
      //style the background color of the editor when disabled
      content_style: '.mce-content-readonly { background-color: #e9ecef; }',
      elementpath: false,
      help_accessibility: false
    };
  }
}
