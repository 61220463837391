import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('@views/search/search.module').then(m => m.SearchModule)
  },
  {
    path: 'search',
    loadChildren: () => import('@views/search/search.module').then(m => m.SearchModule)
  },
  {
    path: 'browse',
    loadChildren: () => import('@views/browse/browse.module').then(m => m.BrowseModule)
  },
  {
    path: 'accreditation',
    loadChildren: () => import('@views/accreditation/accreditation.module').then(m => m.AccreditationModule)
  },
  {
    path: 'classification',
    loadChildren: () => import('@views/classification/classification.module').then(m => m.ClassificationModule)
  },
  {
    path: 'standard',
    loadChildren: () => import('@views/standard/standard.module').then(m => m.StandardModule)
  },
  {
    path: 'programme',
    loadChildren: () => import('@views/programme/programme.module').then(m => m.ProgrammeModule)
  },
  {
    path: 'micro-credential',
    loadChildren: () => import('@views/micro-credential/micro-credential.module').then(m => m.MicroCredentialModule)
  },
  {
    path: 'qualification',
    loadChildren: () => import('@views/qualification/qualification.module').then(m => m.QualificationModule)
  },
  {
    path: 'notes',
    loadChildren: () => import('@views/note/notes.module').then(m => m.NotesModule)
  },
  {
    path: 'create',
    loadChildren: () => import('@views/create/create.module').then(m => m.CreateModule)
  },
  {
    path: 'consent',
    loadChildren: () => import('@views/consent-to-assess/consent-to-assess.module').then(m => m.ConsentToAssessModule)
  },
  {
    path: 'cmr',
    loadChildren: () => import('@views/cmr/cmr.module').then(m => m.CmrModule)
  },
  {
    path: 'multiple-consents',
    loadChildren: () => import('@views/consent-to-assess/consent-to-assess.module').then(m => m.ConsentToAssessModule)
  },
  {
    path: 'bulk-update',
    loadChildren: () => import('@views/bulk-update/bulk-update.module').then(m => m.BulkUpdateModule)
  },
  {
    path: 'administration',
    loadChildren: () => import('@views/administration/administration.module').then(m => m.AdministrationModule)
  }
];

export const routingConfiguration: ExtraOptions = {
  paramsInheritanceStrategy: 'always'
};

@NgModule({
  imports: [RouterModule.forRoot(routes, routingConfiguration)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
