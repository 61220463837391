import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ClassificationTreeDto, FolderTreeRootNode } from '@components/folder-tree/folder-tree/FolderTreeRootNode';
import { apiRoutes } from '@core/constants/api-routes.constants';
import { folderTreeArrayMapper } from '@core/helpers/utils';
import { CmrStatusEnum, CmrVersionStatusEnum } from '@views/cmr/constants';
import { ManageStatus } from '@core/services/models/status/manage-status.model';
import { map, Observable } from 'rxjs';
import { CreateCmr, ICmr } from './models/cmr/cmr';
import { PostCmrResponse } from './models/cmr/post-cmr-response';
import { AssociatedRecordPointer } from './models/micro-credential/associated-record-pointer.model';
import { Lookup } from './models/shared/lookup';
import { IStandard } from './models/standard/standard';

@Injectable({
  providedIn: 'root'
})
export class CmrService {
  constructor(private http: HttpClient) {}

  create(model: CreateCmr): Observable<PostCmrResponse> {
    return this.http.post<PostCmrResponse>(apiRoutes.cmr.create, model);
  }

  get(cmrNo: string): Observable<ICmr[]> {
    return this.http.get<ICmr[]>(apiRoutes.cmr.get(cmrNo));
  }

  getCreateDraft(cmrNo: string): Observable<ICmr[]> {
    return this.http.get<ICmr>(apiRoutes.cmr.createDraft(cmrNo)).pipe(map(x => [x]));
  }

  updateStatus(cmrNo: string, model: ManageStatus): Observable<ICmr> {
    return this.http.patch<ICmr>(apiRoutes.cmr.updateStatus(cmrNo), model);
  }

  update(model: ICmr, cmrNo: string): Observable<PostCmrResponse> {
    return this.http.put<PostCmrResponse>(apiRoutes.cmr.update(cmrNo!), model);
  }

  createNewDraftVersion(model: ICmr): Observable<PostCmrResponse> {
    return this.http.post<PostCmrResponse>(apiRoutes.cmr.createDraft(model.cmrNo!), model);
  }

  getAssociatedStandards(cmrNumber: string, verNo: number) {
    return this.http.get<IStandard[]>(apiRoutes.cmr.standardAssociations(cmrNumber, verNo));
  }

  getAssociatedClassifications(cmrNumber: string, verNo: number) {
    return this.http
      .get<FolderTreeRootNode<ClassificationTreeDto>[]>(apiRoutes.cmr.classificationAssociations(cmrNumber, verNo))
      .pipe(map(folderTreeArrayMapper<ClassificationTreeDto>(ClassificationTreeDto)));
  }

  updateAssociatedRecords(
    cmrNo: string,
    verNo: number,
    associatedRecords: AssociatedRecordPointer[]
  ): Observable<AssociatedRecordPointer[]> {
    return this.http.patch<AssociatedRecordPointer[]>(
      apiRoutes.cmr.updateAssociatedRecords(cmrNo!, verNo),
      associatedRecords
    );
  }

  public static statusOptions: Lookup<number>[] = [
    {
      text: 'Draft',
      value: CmrStatusEnum.Draft
    },
    {
      text: 'Current',
      value: CmrStatusEnum.Current
    },
    {
      text: 'Expiring',
      value: CmrStatusEnum.Expiring
    },
    {
      text: 'Discontinued',
      value: CmrStatusEnum.Discontinued
    },
    {
      text: 'Withdrawn',
      value: CmrStatusEnum.Withdrawn
    }
  ];

  public static verStatusOptions: Lookup<number>[] = [
    {
      text: 'Draft',
      value: CmrVersionStatusEnum.Draft
    },
    {
      text: 'Approved',
      value: CmrVersionStatusEnum.Approved
    },
    {
      text: 'Superseded',
      value: CmrVersionStatusEnum.Superseded
    },
    {
      text: 'Withdrawn',
      value: CmrVersionStatusEnum.Withdrawn
    },
    {
      text: 'Discontinued',
      value: CmrVersionStatusEnum.Discontinued
    }
  ];
}
