import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatRadioModule } from '@angular/material/radio';
import { RouterModule } from '@angular/router';
import { ContextMenuItemComponent } from '@components/context-menu-item/context-menu-item.component';
import { ContextMenuComponent } from '@components/context-menu/context-menu.component';
import { ErrorModalComponent } from '@components/error-modal/error-modal.component';
import { FormComponentsQcrModule } from '@components/form-components-qcr/form-components-qcr.module';
import { FormComponentsModule } from '@components/form-components/form-components.module';
import { LayoutsModule } from '@components/layouts/layouts.module';
import { MessageModalComponent } from '@components/message-modal/message-modal.component';
import { SingleInputComponent } from '@components/modal/single-input/single-input.component';
import { NotesButtonComponent } from '@components/notes-button/notes-button.component';
import { SuccessModalComponent } from '@components/success-modal/success-modal.component';
import { ToggleCheckboxElementComponent } from '@components/toggle-checkbox-group/toggle-checkbox-element.component';
import { ToggleCheckboxGroupComponent } from '@components/toggle-checkbox-group/toggle-checkbox-group.component';
import { ToggleRadioElementComponent } from '@components/toggle-radio-group/toggle-radio-element.component';
import { ToggleRadioGroupComponent } from '@components/toggle-radio-group/toggle-radio-group.component';
import { TranslationHeaderComponent } from '@components/translation-header/translation-header.component';
import { WarningBannerComponent } from '@components/warning-banner/warning-banner.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxPopperjsModule } from 'ngx-popperjs';

@NgModule({
  declarations: [
    ErrorModalComponent,
    ContextMenuComponent,
    ContextMenuItemComponent,
    MessageModalComponent,
    SuccessModalComponent,
    NotesButtonComponent,
    WarningBannerComponent,
    ToggleRadioGroupComponent,
    ToggleRadioElementComponent,
    TranslationHeaderComponent,
    SingleInputComponent,
    ToggleCheckboxGroupComponent,
    ToggleCheckboxElementComponent
  ],
  exports: [
    ErrorModalComponent,
    ContextMenuComponent,
    ContextMenuItemComponent,
    MessageModalComponent,
    SuccessModalComponent,
    NotesButtonComponent,
    WarningBannerComponent,
    ToggleRadioGroupComponent,
    ToggleRadioElementComponent,
    TranslationHeaderComponent,
    ToggleCheckboxGroupComponent,
    ToggleCheckboxElementComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    NgbModule,
    NgxPopperjsModule,
    LayoutsModule,
    FormComponentsQcrModule,
    ReactiveFormsModule,
    MatRadioModule,
    MatExpansionModule,
    FormComponentsModule
  ],
  providers: []
})
export class ComponentsModule {}
