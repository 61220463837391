import { SearchTextPagingParameters } from '../paging/paging-parameters.model';
import { GetClassification } from './get-classification.model';

export interface IGetPagedClassificationDto {
  items: GetClassification[];
  totalCount: number;
}

export class GetClassificationPagingParameters extends SearchTextPagingParameters {
  public constructor(init?: Partial<GetClassificationPagingParameters>) {
    super();
    Object.assign(this, init);
  }

  cfSystemType: number | null;
  depthLevel: number | null;
  parent: string | null;
  statuses: number[] | null;
}
