import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { BaseTreeDto } from '@components/folder-tree/folder-tree/FolderTreeRootNode';
import { EnumToLookup } from './models/shared/lookup';
import { ClassificationStatus } from '@views/classification/constants';

@Injectable({
  providedIn: 'root'
})
export class FolderTreeService {
  private itemClick: Subject<{ item: BaseTreeDto; recordType: string }> = new Subject();
  selectedFilters: Subject<number[]> = new BehaviorSubject<number[]>([...EnumToLookup.toNumbers(ClassificationStatus)]);

  nodeClicked(node: BaseTreeDto, recordType: string) {
    this.itemClick.next({ item: node, recordType: recordType });
  }

  filtersSelected(filters: number[]) {
    this.selectedFilters.next(filters);
  }

  getTreeNodeClick(): Observable<{ item: BaseTreeDto; recordType: string }> {
    return this.itemClick.asObservable();
  }
}
