// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.label-height {
  height: 2rem;
}

.input-group-append .input-group-text {
  border-radius: 0rem 0.25rem 0.25rem 0rem;
}

.input-group-prepend .input-group-text {
  border-radius: 0.25rem 0rem 0rem 0.25rem;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImxhYmVsLWFkai1kYXRlLmNvbXBvbmVudC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0UsWUFBQTtBQUNGOztBQUdFO0VBQ0Usd0NBQUE7QUFBSjs7QUFLRTtFQUNFLHdDQUFBO0FBRkoiLCJmaWxlIjoibGFiZWwtYWRqLWRhdGUuY29tcG9uZW50LnNjc3MiLCJzb3VyY2VzQ29udGVudCI6WyIubGFiZWwtaGVpZ2h0IHtcbiAgaGVpZ2h0OiAycmVtO1xufVxuXG4uaW5wdXQtZ3JvdXAtYXBwZW5kIHtcbiAgLmlucHV0LWdyb3VwLXRleHQge1xuICAgIGJvcmRlci1yYWRpdXM6IDByZW0gMC4yNXJlbSAwLjI1cmVtIDByZW07XG4gIH1cbn1cblxuLmlucHV0LWdyb3VwLXByZXBlbmQge1xuICAuaW5wdXQtZ3JvdXAtdGV4dCB7XG4gICAgYm9yZGVyLXJhZGl1czogMC4yNXJlbSAwcmVtIDByZW0gMC4yNXJlbTtcbiAgfVxufVxuIl19 */`, "",{"version":3,"sources":["webpack://./src/app/components/form-components/label-adj-date/label-adj-date.component.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;AACF;;AAGE;EACE,wCAAA;AAAJ;;AAKE;EACE,wCAAA;AAFJ;AACA,4oBAA4oB","sourcesContent":[".label-height {\n  height: 2rem;\n}\n\n.input-group-append {\n  .input-group-text {\n    border-radius: 0rem 0.25rem 0.25rem 0rem;\n  }\n}\n\n.input-group-prepend {\n  .input-group-text {\n    border-radius: 0.25rem 0rem 0rem 0.25rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
