import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-warning-banner',
  templateUrl: './warning-banner.component.html',
  styleUrls: ['./warning-banner.component.scss']
})
export class WarningBannerComponent {
  /**
   * Whether to display the banner or not
   */
  displayBanner: boolean = true;

  @Input() message: string;
  @Output() closeMessageEvent: EventEmitter<void> = new EventEmitter<void>();
  @Output() rightActionClicked: EventEmitter<void> = new EventEmitter<void>();
  @Input() showCloseButton: boolean = true;
  @Input() rightActionMessageText: string;
  @Input() size: 'md' | 'lg' | 'sm' = 'lg';

  closeMessage() {
    //if no one is listening to the event, then we can just hide the banner
    //when the x is clicked.  Otherwise, we need to let the parent component
    // Important: There is currently no way to re-display the banner after this unless the
    // component is re-rendered.  Because of this it's best to subscribe to the Output event
    if (!this.closeMessageEvent.observed) {
      this.displayBanner = false;
    } else {
      this.closeMessageEvent.emit();
    }
  }

  rightActionMessageClicked() {
    this.rightActionClicked.next();
  }
}
