export class QueryBase {
  toQueryParams(): URLSearchParams {
    let params = new URLSearchParams();
    let keys = Object.keys(this);
    for (let i = 0; i < keys.length; i++) {
      let key = keys[i] as keyof this;
      let val = this[key];
      if (val != null && val != undefined) {
        //By default this is serialized as key=value1,value2 etc
        //But .NET requires key=value1&key=value2
        if (val instanceof Array) {
          val.forEach(element => {
            params.append(key.toString(), element);
          });
        } else {
          params.append(key.toString(), val + '');
        }
      }
    }
    return params;
  }

  toQueryString(): string {
    return this.toQueryParams().toString();
  }

  static toQueryList(list: number[], propertyName: string): string {
    return list
      .map(x => `&${propertyName}=${x}`)
      .join('')
      .substring(1);
  }
}
