import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { AbstractControl, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ControlValueAccessorConnector } from '../control-value-accessor-connector';

@Component({
  selector: 'app-label-adj-text',
  templateUrl: './label-adj-text.component.html',
  styleUrls: ['./label-adj-text.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LabelAdjTextComponent),
      multi: true
    }
  ]
})
export class LabelAdjTextComponent extends ControlValueAccessorConnector implements ControlValueAccessor {
  @Input() placeholder: string = '';
  @Input() autocomplete: boolean = false;
  @Input() maxlength: number | null = null;
  @Input() hideWarning: boolean = false;
  @Input() adjacentText: string = '';
  @Input() rightSide: boolean = false;
  @Input() hideMessages: boolean = false;
  @Input() step: string = '0.1';
  @Input() attributeId = this.formControlName;
  @Input() isEditMode = true;

  /**
   * If true and there is no label provided the component will still occupy the same space as if there was a label.
   * If false then there will be no vertical space above the input
   */
  @Input() labelPlaceholder: boolean = true;

  /**
   * Mark the control as readonly.  Only used if the control is not associated w/ a form group
   */
  @Input() readonly: boolean = false;

  constructor() {
    super();
  }

  hasRequiredValidator(): boolean {
    if (this.control.validator) {
      const validator = this.control.validator({} as AbstractControl);
      if (validator && validator['required']) {
        return true;
      }
    }
    return false;
  }

  /**
   * Get the autocomplete input element value
   * @returns on/off state of the autocomplete
   */
  autoComplete() {
    return this.autocomplete ? 'on' : 'off';
  }
}
