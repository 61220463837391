import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChange,
  SimpleChanges,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator
} from '@angular/forms';
import { ControlValueAccessorConnector } from '@components/form-components/control-value-accessor-connector';
import { RichWarning } from '@components/rich-warning-message/rich-warning-message.component';
import { FormControlWarn } from '@core/forms/form-control-warn';
import { debounceTime, distinctUntilChanged, Observable, OperatorFunction, Subscription, switchMap } from 'rxjs';

@Component({
  selector: 'app-typeahead',
  templateUrl: './typeahead.component.html',
  styleUrls: ['./typeahead.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: TypeaheadComponent,
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: TypeaheadComponent,
      multi: true
    }
  ]
})
export class TypeaheadComponent
  extends ControlValueAccessorConnector
  implements ControlValueAccessor, Validator, OnDestroy, OnInit, AfterViewInit, OnChanges
{
  @Input() lookupFunction: (searchText: string, count: number) => Observable<string[]>;
  @Input() placeHolder: string;
  @Input() maxResult: number = 10;
  @Input() lookupDelay: number = 250;
  @Input() minLookupChar: number = 1;
  @Input() hasError: boolean = false;
  @Input() isEditable: boolean = true;
  @Input() hideWarning: boolean = false;
  @Input() canCancel: boolean = true;
  @Input() hasConfirmationModal: boolean = false;
  @Output() removeEvent: EventEmitter<void> = new EventEmitter<void>();
  @Input() displaySelectedItemBorder = true;
  @Input() hasEventEmitter: boolean = false;
  @Input() attributeId = this.formControlName;
  @Input() isEditMode: boolean = true;
  public warnings: string = '';
  public richWarning?: RichWarning;
  private validityChangeSubscription: Subscription;

  /**
   * The template for the typeahead dropdown.
   * How the result items in the pick list are rendered
   */
  @Input() resultTemplate: TemplateRef<any>;

  /**
   * The function that formats the selected item that is displayed in the input field.
   */
  @Input() inputFormatter: any;

  @ViewChild('myInput') myInput: ElementRef;

  get title(): string {
    return this.myInput?.nativeElement.value;
  }

  searchText: string;
  hasSelectedItemBorder = false;
  valueChange$: Subscription;
  readOnly = false;
  isAlreadyLinkedError = true;

  constructor(private changeDetectorRef: ChangeDetectorRef) {
    super();
  }

  ngAfterViewInit(): void {
    this.changeDetectorRef.detectChanges();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.formGroup) {
      const s = changes.formGroup as SimpleChange;
      const currentValue = (s.currentValue as FormGroup)?.get(this.formControlName)?.value;
      const previousValue = (s.previousValue as FormGroup)?.get(this.formControlName)?.value;
      // Control is being reset, change it to be editable.
      if (previousValue && !currentValue && this.readOnly) {
        this.readOnly = false;
        this.hasSelectedItemBorder = false;
      }
      this.changeDetectorRef.detectChanges();
    }
  }

  ngOnInit(): void {
    this.valueChange$ = this.control?.valueChanges.subscribe(value => {
      if (value) {
        this.hasSelectedItemBorder = true;
      }
      //ngOnChanges is not always fired when the state changes, so we need to check here as well
      else if (this.readOnly && this.control.enabled) {
        this.hasSelectedItemBorder = false;
        this.readOnly = false;
      }
    });

    /**
     * Listening for control validity changes and updating the warning message to be displayed if
     * necessary, having a synchronous and and an asynchronous validators on the same component wasn't
     * really easy to get to work correctly this is why there are calls to detectChanges and updateValueAndValidity
     * and also this is why there is a listener for validity change not just a getter for the component warnings
     */
    this.validityChangeSubscription = this.control?.statusChanges.subscribe(v => {
      const controlWarnings = (this.control as FormControlWarn).warnings;
      if (controlWarnings) {
        const warning = controlWarnings['warning'];
        if ((warning as RichWarning)?.warningHeader && this.control.value) {
          this.richWarning = warning;
        } else {
          this.warnings = warning;
        }
      } else {
        this.warnings = '';
      }
      this.changeDetectorRef.detectChanges();
      this.formGroup.updateValueAndValidity();
    });

    if (this.control?.value && this.displaySelectedItemBorder) {
      this.hasSelectedItemBorder = true;
      this.readOnly = true;
    }
  }

  validate(control: AbstractControl): ValidationErrors | null {
    if (this.myInput?.nativeElement?.value && !this.isEditable) {
      return control?.value ? null : { noMatch: true };
    }
    this.richWarning = undefined;
    return null;
  }

  search: OperatorFunction<any, string[]> = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(this.lookupDelay),
      distinctUntilChanged(),
      switchMap(searchText =>
        searchText.length < this.minLookupChar ? [] : this.lookupFunction(searchText, this.maxResult)
      )
    );

  removeSelection() {
    if (this.control?.value && this.hasConfirmationModal) {
      this.removeEvent.emit();
    } else if (this.control?.value && this.hasEventEmitter) {
      this.formGroup.markAsDirty();
      this.control.markAsDirty();
      this.control.setValue(null);
      this.control.updateValueAndValidity();
      this.removeEvent.emit();
    } else {
      this.formGroup.markAsDirty();
      this.control.markAsDirty();
      this.control.setValue(null);
      this.control.updateValueAndValidity();
    }
    this.hasSelectedItemBorder = false;
    this.readOnly = false;
  }

  onSelect(event: any) {
    this.hasSelectedItemBorder = true;
    if (this.displaySelectedItemBorder) {
      this.readOnly = true;
    }
    this.isAlreadyLinkedError = true;

    //blur event triggers the ellipsis to show up
    this.myInput.nativeElement.blur();
  }

  ngOnDestroy(): void {
    this.valueChange$?.unsubscribe();
    if (this.validityChangeSubscription) {
      this.validityChangeSubscription.unsubscribe();
    }
  }

  closeWarningMessage(): void {
    this.isAlreadyLinkedError = false;
  }
}
