import { StandardGroupTypeEnum } from '@core/services/models/standard-groups/standard-groups';
import { IStandard } from '@core/services/models/standard/standard';
import { ClassificationStatus } from '@views/classification/constants';

export interface FolderTreeRootNode<Item> {
  item: Item;
  children: FolderTreeRootNode<Item>[];
  /* If set to true, the tree node will not show */
  hidden?: boolean;
}

export abstract class BaseTreeDto {
  constructor(obj?: BaseTreeDto) {
    Object.assign(this, obj);
  }
  id: string;
  nodeId: string;
  depthLevel: string;
  depth: number;
  parent: null | string;
  parentId: null | string;
  abstract get itemName(): string;
  tableData?: any[];
  /* Used to specify if the node is at maximum depth, used for Rule Group tree nodes because they have a complicated 
  structure and the depth can not be determined by an Enum like Classifications and Standard Group trees */
  isLeafNode?: boolean;
  /* If set to true, clicking the node event will not be triggered,  used in Rule Groups tree for nodes that do not have
  dedicated details pages */
  noNavigation?: boolean;
  /* Title nodes are used to group sibling nodes on the same level below an upper case title that has no navigation */
  isTitleNode?: boolean;
  /* Used to specify if the navigation should use the id of the parent, rather than the current node id */
  parentNavigation?: boolean;
}

export class ClassificationTreeDto extends BaseTreeDto {
  constructor(obj?: ClassificationTreeDto) {
    super(obj);
  }

  override get itemName(): string {
    return this.primaryName;
  }
  cfSystemType: string;
  internalId: string;
  primaryName: string;
  status: ClassificationStatus;
  standards?: IStandard[];
  override tableData?: any[] = this.standards!;
}

export class StandardGroupTreeDto extends BaseTreeDto {
  constructor(obj?: StandardGroupTreeDto) {
    super(obj);
  }

  override get itemName(): string {
    return this.title;
  }

  title: string;
  type: StandardGroupTypeEnum;
}
export class RuleGroupTreeDto extends BaseTreeDto {
  constructor(obj?: RuleGroupTreeDto) {
    super(obj);
  }

  override get itemName(): string {
    return this.title;
  }

  title: string;
  type: StandardGroupTypeEnum;
}
