import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { apiRoutes } from '../constants/api-routes.constants';
import { IStandard } from './models/standard/standard';
import { Lookup } from './models/shared/lookup';
import {
  StandardAssessmentEnum,
  StandardContextEnum,
  StandardStatusEnum,
  StandardVersionStatusEnum
} from '@views/standard/constants';
import { ManageStatus } from '@core/services/models/status/manage-status.model';
import { PrimaryLanguage } from '@views/qualification/constants';
import { map, Observable } from 'rxjs';
import { IGetTransitionalStandardDto } from '@views/standard/manage-date/manage-date.model';
import { StandardGroup } from './models/standard-groups/standard-groups';
import { GetStandardPagingParameters, IGetPagedStandardDto } from './models/standard/IGetPagedStandardDto';
import { genericArrayReMapper } from '@core/helpers/utils';
import { IQualification } from './models/qualification/qualification';
import { IProgramme } from './models/programme/programme';
import { IMicroCredential } from './models/micro-credential/micro-credential';
import { ICmr } from './models/cmr/cmr';
import { GetPagedStandardParameters } from '@components/paged-records-associated/standard-group-associated-standards/get-paged-standard-parameters';

@Injectable({
  providedIn: 'root'
})
export class StandardService {
  getAssociatedStandardGroups(stdNo: string): Observable<StandardGroup[]> {
    return this.http
      .get<StandardGroup[]>(apiRoutes.standardGroup.standardGroupsForStandard(stdNo))
      .pipe(map(genericArrayReMapper<StandardGroup>(StandardGroup)));
  }

  get(recordNo: string): Observable<IStandard[]> {
    return this.http.get<IStandard[]>(apiRoutes.standard.get(recordNo));
  }

  getStandards(
    searchText: string,
    count: number,
    status?: StandardStatusEnum[],
    verStatus?: StandardVersionStatusEnum[],
    searchOnlyByNumber: boolean = false,
    isBrowseStandardGroup: boolean = false
  ): Observable<IGetPagedStandardDto> {
    let params = new GetStandardPagingParameters({
      searchText: searchText,
      limit: count,
      offset: 0,
      status: status ?? [],
      verStatus: verStatus ?? [],
      searchOnlyByNumber,
      isBrowseStandardGroup
    });
    return this.http.get<IGetPagedStandardDto>(apiRoutes.standard.getStandards(params.toQueryString()));
  }
  create(model: IStandard): Observable<IStandard> {
    return this.http.post<IStandard>(apiRoutes.standard.create, model);
  }

  createNewDraftVersion(model: IStandard): Observable<IStandard> {
    return this.http.post<IStandard>(apiRoutes.standard.createDraft(model.stdNo!), model);
  }

  update(model: IStandard): Observable<IStandard> {
    return this.http.put<IStandard>(apiRoutes.standard.update(model.stdNo!), model);
  }

  editAssessment(model: IStandard) {
    return this.http.patch<IStandard>(apiRoutes.standard.editAssessment(model.stdNo!, model.verNo!), model);
  }

  updateStatus(recordNo: string, model: ManageStatus): Observable<IStandard> {
    return this.http.patch<IStandard>(apiRoutes.standard.updateStatus(recordNo), model);
  }

  getCreateDraft(stdNo: string): Observable<IStandard[]> {
    return this.http.get<IStandard>(apiRoutes.standard.getCreateDraft(stdNo)).pipe(map(x => [x]));
  }

  updateDates(recordNo: string, versions: IGetTransitionalStandardDto[]): Observable<void> {
    return this.http.patch<void>(apiRoutes.standard.updateDates(recordNo), versions);
  }

  getAssociatedQualifications(standardNumber: string, version: number) {
    return this.http.get<IQualification[]>(apiRoutes.standard.qualificationAssociations(standardNumber, version));
  }

  getAssociatedProgrammes(standardNumber: string, version: number) {
    return this.http.get<IProgramme[]>(apiRoutes.standard.programmeAssociations(standardNumber, version));
  }

  getAssociatedMicroCredentials(standardNumber: string, version: number) {
    return this.http.get<IMicroCredential[]>(apiRoutes.standard.microCredentialAssociations(standardNumber, version));
  }

  constructor(private http: HttpClient) {}

  private statusOptions: Lookup<number>[] = [
    {
      text: 'Draft',
      value: StandardStatusEnum.Draft
    },
    {
      text: 'Current',
      value: StandardStatusEnum.Current
    },
    {
      text: 'Expiring',
      value: StandardStatusEnum.Expiring
    },
    {
      text: 'Discontinued',
      value: StandardStatusEnum.Discontinued
    },
    {
      text: 'Withdrawn',
      value: StandardStatusEnum.Withdrawn
    },
    {
      text: 'Declined',
      value: StandardStatusEnum.Declined
    }
  ];

  private languagesOptions: Lookup<number>[] = [
    {
      text: 'English',
      value: PrimaryLanguage.English
    },
    {
      text: 'Māori',
      value: PrimaryLanguage.Maori
    }
  ];

  private assessmentOptions: Lookup<number>[] = [
    {
      text: 'Internally Assessed',
      value: StandardAssessmentEnum.InternallyAssessed
    },
    {
      text: 'Externally Assessed',
      value: StandardAssessmentEnum.ExternallyAssessed
    }
  ];

  private contextOptions: Lookup<number>[] = [
    {
      text: 'No Context',
      value: StandardContextEnum.NoContext
    },
    {
      text: 'Optional Context',
      value: StandardContextEnum.OptionalContext
    },
    {
      text: 'Mandatory Context',
      value: StandardContextEnum.MandatoryContext
    },
    {
      text: 'Optional Context - Multiples Allowed',
      value: StandardContextEnum.OptionalContextMultiplesAllowed
    },
    {
      text: 'Mandatory Context - Multiples Allowed',
      value: StandardContextEnum.MandatoryContextMultiplesAllowed
    }
  ];

  getStatusOptions(): Lookup<number>[] {
    return this.statusOptions;
  }

  getLanguageOptions(): Lookup<number>[] {
    return this.languagesOptions;
  }

  getContextOptions(): Lookup<number>[] {
    return this.contextOptions;
  }

  getAssessmentOptions(): Lookup<number>[] {
    return this.assessmentOptions;
  }

  getAssociatedCMRs(stdNo: string, verNo: number, cmrNoToIgnore?: string): Observable<ICmr[]> {
    return this.http.get<ICmr[]>(apiRoutes.standard.cmrAssociations(stdNo, verNo, cmrNoToIgnore));
  }

  paged(params: GetPagedStandardParameters): Observable<IGetPagedStandardDto> {
    return this.http.get<IGetPagedStandardDto>(apiRoutes.standard.paged(params.toQueryString()));
  }
}
