import { GenericForm } from '@core/forms/form-group-extensions';
import { Classification, IStandard } from '../standard/standard';
import { CosmoBaseModel } from '../base/cosmo-base.model';

export type StandardGroupForm = GenericForm<IStandardGroup>;

export interface IStandardGroup {
  id: string;
  type: StandardGroupTypeEnum;
  title: string;
  sg: SgReference;
  owner: StandardGroupOwnerEnum;
  associatedClassificationRecords: StandardGroupAssociatedClassificationRecord;
  associatedStandardRecords: StandardGroupAssociatedStandardRecord;
  get typeName(): string;
}

export class StandardGroup implements IStandardGroup {
  constructor(obj?: IStandardGroup) {
    Object.assign(this, obj);
  }
  id: string;
  type: StandardGroupTypeEnum;
  title: string;
  sg: SgReference;
  owner: StandardGroupOwnerEnum;
  associatedClassificationRecords: StandardGroupAssociatedClassificationRecord;
  associatedStandardRecords: StandardGroupAssociatedStandardRecord;
  get typeName(): string {
    return getDisplayName(this.type);
  }
}

function getDisplayName(type: StandardGroupTypeEnum): string {
  switch (type) {
    case StandardGroupTypeEnum.LearningArea:
      return 'Learning Area';
    case StandardGroupTypeEnum.Moderation:
      return 'Moderation';
    case StandardGroupTypeEnum.SegSubjectGroups:
      return 'SEG Subject Groups';
    case StandardGroupTypeEnum.QualificationRule:
      return 'Qualification Rule';
    case StandardGroupTypeEnum.TopScholarProject:
      return 'Top Scholar Subject';
    case StandardGroupTypeEnum.UniversityEntrance:
      return 'University Entrance';
    case StandardGroupTypeEnum.VisualArt:
      return 'Visual Art';
    default:
      throw new Error('Type not found');
  }
}

export interface IPatchStandardGroupAssociations extends CosmoBaseModel {
  id: string;
  type: StandardGroupTypeEnum;
  associatedClassificationRecords?: StandardGroupAssociatedClassificationRecord;
  associatedStandardRecords?: StandardGroupAssociatedStandardRecord;
}

export enum StandardGroupTypeEnum {
  LearningArea = 9401,
  Moderation = 9402,
  SegSubjectGroups = 9403,
  QualificationRule = 9404,
  TopScholarProject = 9405,
  UniversityEntrance = 9406,
  VisualArt = 9407
}

export interface SgReference {
  id: string;
  name: string;
}

export enum StandardGroupOwnerEnum {
  IndustryTrainingOrganisation = 9501,
  SecondaryEducationGroup = 9502,
  NotApplicable = 9503,
  TertiaryEducationGroup = 9504
}

export interface StandardGroupAssociatedStandardRecord {
  standards: AssociatedStandard[];
}

export interface AssociatedStandard {
  record: IStandard;
  isDeleted: boolean;
  isNew: boolean;
}

export interface StandardGroupAssociatedClassificationRecord {
  classifications: AssociatedClassification[];
}

export interface AssociatedClassification {
  record: Classification;
  isDeleted: boolean;
  isNew: boolean;
}
