import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Lookup } from '@core/services/models/shared/lookup';
import { ControlValueAccessorConnector } from '../control-value-accessor-connector';

@Component({
  selector: 'app-label-adj-select',
  templateUrl: './label-adj-select.component.html',
  styleUrls: ['./label-adj-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LabelAdjSelectComponent),
      multi: true
    }
  ]
})
export class LabelAdjSelectComponent extends ControlValueAccessorConnector implements ControlValueAccessor {
  @Input() options: any[];
  @Input() showBlankOption: boolean = false;
  @Input() blankOptionText: string | null = null;
  @Input() textOptions: string[] = [];
  @Input() numberOptions: number[] = [];
  @Input() lookupOptions: Lookup<any>[] = [];
  @Input() adjacentText: string;
  @Input() hideMessages: boolean = false;
  @Input() title: string;
  @Input() attributeId = this.formControlName;

  constructor() {
    super();
  }
}
