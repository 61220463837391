import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject, debounceTime } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  private isLoading$$ = new BehaviorSubject<boolean>(false);
  isLoading$ = this.isLoading$$.asObservable();

  /**
   * Emits when a http request is started.  This resets the timer for clearing the spinner
   *
   * @memberof LoadingService
   */
  loadStarted$ = new Subject<void>();

  /**
   * The number of http requests that are currently in progress
   *
   * @private
   * @type {number}
   * @memberof LoadingService
   */
  private requestCount: number = 0;

  /**
   * The amount of time to wait before clearing the spinner if the http request is not completed
   *
   * @type {number}
   * @memberof LoadingService
   */
  readonly timeToWaitBeforeClearingSpinner: number = 10000;

  constructor() {
    this.loadStarted$.pipe(debounceTime(this.timeToWaitBeforeClearingSpinner)).subscribe(() => {
      if (this.requestCount > 0) {
        this.requestCount = 0;
        this.isLoading$$.next(false);
      }
    });
  }

  httpStartLoading() {
    this.requestCount++;
    this.isLoading$$.next(this.requestCount > 0);
    this.loadStarted$.next();
  }

  httpStopLoading() {
    if (this.requestCount > 1) {
      this.requestCount--;
    } else {
      this.requestCount = 0;
    }

    this.isLoading$$.next(this.requestCount > 0);
  }
}
