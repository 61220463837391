import { ChangeDetectorRef, Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessorConnector } from '@components/form-components/control-value-accessor-connector';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { IStandard, Standard } from '@core/services/models/standard/standard';
import { StandardService } from '@core/services/standard.service';
import { map, Observable } from 'rxjs';
import { StandardStatusEnum, StandardVersionStatusEnum } from '@views/standard/constants';
import { ITypeaheadBase } from '../typeahead-base';
import { genericArrayReMapper } from '@core/helpers/utils';
import { MessageModalService } from '@components/message-modal/message-modal.service';
import { ButtonLabels } from '@core/constants/button-labels';
import { Router } from '@angular/router';

@Component({
  selector: 'app-standard-typeahead',
  templateUrl: './standard-typeahead.component.html',
  styleUrls: ['./standard-typeahead.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => StandardTypeaheadComponent),
      multi: true
    }
  ]
})
export class StandardTypeaheadComponent
  extends ControlValueAccessorConnector
  implements OnInit, ControlValueAccessor, ITypeaheadBase
{
  @Input() removeConfirmationMessage: string = '';
  @Input() hasConfirmationModal: boolean = false;
  @Output() removeEvent: EventEmitter<string> = new EventEmitter<string>();
  @Input() excludedIds: string[] = [];
  @Input() searchStatuses: StandardStatusEnum[] | undefined;
  @Input() searchOnlyByNumber: boolean = false;
  @Input() confirmationButtons: string[] = [ButtonLabels.Ok];

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private service: StandardService,
    private modalService: MessageModalService,
    private router: Router
  ) {
    super();
  }

  ngOnInit(): void {
    this.changeDetectorRef?.detectChanges();
  }

  /**
   * Query api based on search text
   * @param term
   * @param count
   * @returns
   */
  searchFunc = (term: string, count: number): Observable<any[]> => {
    return this.service
      .getStandards(
        term,
        count,
        this.searchStatuses || [StandardStatusEnum.Current],
        [StandardVersionStatusEnum.Approved],
        this.searchOnlyByNumber,
        this.router.isBrowseStandardGroup()
      )
      .pipe(
        map(response => {
          const items = response.items.filter(item => this.excludedIds?.indexOf(item.id!) == -1);
          /* Passing the resulted items through the re-mapper to be able to use
          the model getters and functions */
          return genericArrayReMapper<IStandard>(Standard)(items);
        })
      );
  };

  /**
   * Format the search results
   * @param x
   * @returns
   */
  formatter = (x: IStandard) => `${(x.stdTypeDisplayName, '|', x.stdNo, '|', x.title)}`;

  showConfirmationModal(): void {
    if (this.control.valid && this.removeConfirmationMessage) {
      this.modalService.open(this.removeConfirmationMessage, true, this.confirmationButtons).result.then(response => {
        this.removeEvent.emit(response);
      });
    } else {
      this.removeEvent.emit(ButtonLabels.Ok);
    }
  }
}
