import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { apiRoutes } from '@core/constants/api-routes.constants';
import { genericArrayReMapper } from '@core/helpers/utils';
import { MicroCredentialQualityAssuranceBodies } from '@views/micro-credential/constants';
import { AssociatedRecordTypeEnum } from '@views/pre-requisites/constants';
import { PrimaryLanguage } from '@views/qualification/constants';
import { ManageStatus } from '@core/services/models/status/manage-status.model';
import { map, Observable, Subject } from 'rxjs';
import { ResponseBase } from './models/base/responseBase.model';
import { IGetPagedMicroCredentialsDto } from './models/micro-credential/IGetPagedMicroCredentialsDto';
import { GetPagedMicroCredParameters, IMicroCredential } from './models/micro-credential/micro-credential';
import { AssociatedRecordPointer } from './models/micro-credential/associated-record-pointer.model';
import { PostMicroCredentialResponse } from './models/micro-credential/post-micro-credential-response';
import { IProgramme } from './models/programme/programme';
import { IQualification } from './models/qualification/qualification';
import { Lookup } from './models/shared/lookup';
import { IStandard } from './models/standard/standard';

@Injectable({
  providedIn: 'root'
})
export class MicroCredentialService {
  onUpdatePrerequisites: Subject<AssociatedRecordPointer[]> = new Subject();

  private languagesOptions: Lookup<number>[] = [
    {
      text: 'English',
      value: PrimaryLanguage.English
    },
    {
      text: 'Māori',
      value: PrimaryLanguage.Maori
    }
  ];

  static qualityAssuranceBodiesOptions: Lookup<number>[] = [
    {
      text: 'Committee of University Academic Programmes',
      value: MicroCredentialQualityAssuranceBodies.CommitteeOfUniversityAcademicProgrammes
    },
    {
      text: 'New Zealand Qualifications Authority',
      value: MicroCredentialQualityAssuranceBodies.NewZealandQualificationsAuthority
    }
  ];

  private fromOptions: Lookup<number>[];
  private toOptions: Lookup<number>[];

  constructor(private http: HttpClient) {}

  create(model: IMicroCredential): Observable<PostMicroCredentialResponse> {
    return this.http.post<PostMicroCredentialResponse>(apiRoutes.microCredential.create, model);
  }

  get(mcNo: string): Observable<IMicroCredential[]> {
    return this.http.get<IMicroCredential[]>(apiRoutes.microCredential.get(mcNo));
  }

  createNewDraftVersion(model: IMicroCredential): Observable<PostMicroCredentialResponse> {
    return this.http.post<PostMicroCredentialResponse>(apiRoutes.microCredential.createDraft(model.mcNo!), model);
  }

  update(model: IMicroCredential, mcNo: string, verNo: number): Observable<PostMicroCredentialResponse> {
    return this.http.put<PostMicroCredentialResponse>(apiRoutes.microCredential.update(mcNo!, verNo), model);
  }

  paged(params: GetPagedMicroCredParameters): Observable<IGetPagedMicroCredentialsDto> {
    return this.http.get<IGetPagedMicroCredentialsDto>(apiRoutes.microCredential.paged(params.toQueryString()));
  }

  updateStatus(mcNo: string, status: ManageStatus) {
    return this.http.patch<ResponseBase>(apiRoutes.microCredential.updateStatus(mcNo), status);
  }

  getCreateDraft(mcNo: string): Observable<IMicroCredential[]> {
    return this.http.get<IMicroCredential>(apiRoutes.microCredential.createDraft(mcNo)).pipe(map(x => [x]));
  }

  savePreRequisites(
    mcNo: string,
    verNo: number,
    preRequisitesToAdd: AssociatedRecordPointer[]
  ): Observable<AssociatedRecordPointer[]> {
    return this.http
      .patch<AssociatedRecordPointer>(
        apiRoutes.microCredential.addPreReqsForMicroCredential(mcNo, verNo),
        preRequisitesToAdd
      )
      .pipe(map(x => [x]));
  }

  getPreRequisites(
    mcNo: string,
    verNo: number,
    recordType: AssociatedRecordTypeEnum
  ): Observable<AssociatedRecordPointer[]> {
    return this.http
      .get<AssociatedRecordPointer[]>(apiRoutes.microCredential.getPrerequisites(mcNo, verNo, recordType))
      .pipe(map(genericArrayReMapper<AssociatedRecordPointer>(AssociatedRecordPointer)));
  }

  getAllPreRequisites(mcNo: string, verNo: number): Observable<AssociatedRecordPointer[]> {
    return this.http
      .get<AssociatedRecordPointer[]>(apiRoutes.microCredential.getAllPrerequisites(mcNo, verNo))
      .pipe(map(genericArrayReMapper<AssociatedRecordPointer>(AssociatedRecordPointer)));
  }

  getLanguagesOptions(): Lookup<number>[] {
    return this.languagesOptions;
  }

  getQualityAssuranceBodiesOptions(): Lookup<number>[] {
    return MicroCredentialService.qualityAssuranceBodiesOptions;
  }

  getLevelOptions(maxLevel: number = 10): Lookup<number>[] {
    let options: Lookup<number>[] = [];
    for (let option = 1; option <= maxLevel; option++) {
      options.push({
        text: option.toString(),
        value: option
      });
    }
    return options;
  }

  getFromOptions(): Lookup<number>[] {
    this.fromOptions = this.getLevelOptions();
    return this.fromOptions;
  }

  getToOptions(minLevel: number = 1): Lookup<number>[] {
    this.toOptions = this.getLevelOptions();
    return this.toOptions.map(option => {
      if (option.value < minLevel) option.disabled = true;
      return option;
    });
  }

  getTranslation(mcNumber: string, verNo: number): Observable<IMicroCredential> {
    return this.http.get<IMicroCredential>(apiRoutes.microCredential.getTranslation(mcNumber, verNo));
  }

  updatePrerequisites(newPrerequisites: AssociatedRecordPointer[]) {
    this.onUpdatePrerequisites.next(newPrerequisites);
  }

  updateTranslation(model: IMicroCredential, mcNo: string): Observable<PostMicroCredentialResponse> {
    return this.http.put<PostMicroCredentialResponse>(apiRoutes.microCredential.updateTranslation(mcNo!), model);
  }

  getAssociatedMicroCredentials(mcNumber: string, version: number) {
    return this.http.get<IMicroCredential[]>(apiRoutes.microCredential.microCredentialAssociations(mcNumber, version));
  }

  getAssociatedQualifications(mcNumber: string, version: number) {
    return this.http.get<IQualification[]>(apiRoutes.microCredential.qualificationAssociations(mcNumber, version));
  }

  getAssociatedProgrammes(mcNumber: string, verNo: number) {
    return this.http.get<IProgramme[]>(apiRoutes.microCredential.programmeAssociations(mcNumber, verNo));
  }

  getAssociatedStandards(mcNumber: string, verNo: number) {
    return this.http.get<IStandard[]>(apiRoutes.microCredential.standardAssociations(mcNumber, verNo));
  }

  updateAssociatedRecords(
    mcNo: string,
    verNo: number,
    associatedRecords: AssociatedRecordPointer[]
  ): Observable<AssociatedRecordPointer[]> {
    return this.http.patch<AssociatedRecordPointer[]>(
      apiRoutes.microCredential.updateAssociatedRecords(mcNo!, verNo),
      associatedRecords
    );
  }
}
