import { Lookup } from '../shared/lookup';

export class LookupListItem extends Lookup<number> {
  public id: string;
  public category: LookupListCategory;
  public etag: string | null | undefined;
}

export enum LookupListCategory {
  ProgrammeType,
  ProgrammeLeadsTo,
  MicroCredentialLeadsTo,
  StandardProcess,
  StandardGradingScheme,
  AchievementStandardGradingScheme,
  StandardGradingType,
  AchievementStandardGradingType,
  CMRProcess
}
