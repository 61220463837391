import { GenericForm } from '@core/forms/form-group-extensions';
import { MicroCredentialStatusEnum, MicroCredentialVersionStatusEnum } from '@views/micro-credential/constants';
import { PrimaryLanguage, QualityAssuranceBodies } from '@views/qualification/constants';
import { SearchTextPagingParameters } from '../paging/paging-parameters.model';
import { PagingResponse } from '../paging/paging-response.model';
import { IClassificationReference } from '../programme/nzsced-classification';
import { IQualificationDeveloper } from '../shared/qualification-developer';
import { CosmoBaseModel } from '../base/cosmo-base.model';

export interface IMicroCredential {
  id?: string | null;
  mcNo?: string;
  mcNoIdentity?: number;
  title?: string | null;
  teReoMaoriTitle?: string | null;
  status?: MicroCredentialStatusEnum | null;
  verNo?: number;
  verStatus?: MicroCredentialVersionStatusEnum | null;
  caseId?: string | null;
  reviewDate?: string | null;
  mcDeveloper?: IQualificationDeveloper | null;
  nzsced?: IClassificationReference | null;
  levelFrom?: number | null;
  levelTo?: number | null;
  dasCreditsFrom?: number | null;
  dasCreditsTo?: number | null;
  nonDASCreditsFrom?: number | null;
  nonDASCreditsTo?: number | null;
  totalCreditsFrom?: number | null;
  totalCreditsTo?: number | null;
  totalStudyHoursFrom?: number | null;
  totalStudyHoursTo?: number | null;
  language?: PrimaryLanguage | null;
  translationReady?: boolean | null;
  teHonooTeKahurangiQAProcess?: boolean | null;
  qualityAssuranceBody?: QualityAssuranceBodies | null;
  internationalStudent?: boolean | null;
  siteSpecific?: boolean | null;
  approvedForOffshoreDelivery?: boolean | null;
  industryTrainingMicroCredential?: boolean | null;
  entryRequirements?: string | null;
  prerequisites?: boolean | null;
  assessment?: string | null;
  purpose?: string | null;
  content?: string | null;
  maoriContent?: boolean | null;
  outcome?: string | null;
  leadsTo?: number | null;
  mcQualificationOutCome?: string | null;
  mcProgrammeOutCome?: string | null;
  expiryDate?: string | null;
  lastDateforAssessment?: string | null;
  mcContactDetails?: string | null;
  evdSupportForMC?: string | null;
  evdMeetUnMeetNeeds?: string | null;
  mcIncludesUSApp?: string | null;
  approvalCriteria?: string | null;
  created?: string | null;
  modified?: string | null;
  statusDate?: string | null;
  verStatusDate?: string | null;
  etag?: string | null | undefined;
  isNew?: boolean | null;
}

export class MicroCredential extends CosmoBaseModel implements Partial<IMicroCredential> {
  id?: string | null = null;
  mcNo?: string;
  mcNoIdentity?: number;
  title?: string | null = '';
  teReoMaoriTitle?: string | null;
  status?: MicroCredentialStatusEnum | null;
  verNo?: number;
  verStatus?: MicroCredentialVersionStatusEnum | null;
  caseId?: string | null = '';
  reviewDate?: string | null;
  mcDeveloper?: IQualificationDeveloper | null;
  nzsced?: IClassificationReference | null;
  levelFrom?: number | null = null;
  dasCreditsFrom?: number | null;
  dasCreditsTo?: number | null;
  nonDASCreditsFrom?: number | null;
  nonDASCreditsTo?: number | null;
  totalCreditsFrom?: number | null = null;
  totalCreditsTo?: number | null = null;
  totalStudyHoursFrom?: number | null;
  totalStudyHoursTo?: number | null;
  language?: PrimaryLanguage | null;
  translationReady?: boolean | null;
  teHonooTeKahurangiQAProcess?: boolean | null;
  qualityAssuranceBody?: QualityAssuranceBodies | null = null;
  internationalStudent?: boolean | null;
  siteSpecific?: boolean | null;
  approvedForOffshoreDelivery?: boolean | null;
  industryTrainingMicroCredential?: boolean | null;
  entryRequirements?: string | null;
  prerequisites?: boolean | null;
  assessment?: string | null;
  purpose?: string | null;
  content?: string | null;
  maoriContent?: boolean | null;
  outcome?: string | null;
  leadsTo?: number | null;
  mcQualificationOutCome?: string | null;
  mcProgrammeOutCome?: string | null;
  expiryDate?: string | null;
  lastDateforAssessment?: string | null;
  mcContactDetails?: string | null;
  evdSupportForMC?: string | null;
  evdMeetUnMeetNeeds?: string | null;
  mcIncludesUSApp?: string | null;
  approvalCriteria?: string | null;
  created?: string | null;
  modified?: string | null;
  statusDate?: string | null;
  verStatusDate?: string | null;
  isNew?: boolean | null = false;
}

export type MicroCredentialForm = GenericForm<MicroCredential>;

export class GetPagedMicroCredParameters extends SearchTextPagingParameters {
  constructor(init?: Partial<GetPagedMicroCredParameters>) {
    super();
    Object.assign(this, init);
  }

  ver?: number;
  type?: string;
  status?: MicroCredentialStatusEnum[];
  mcNo: string;
  excludeMcNo: string | null;
}

export class GetPageMicroCredentials extends PagingResponse<IMicroCredential> {}
