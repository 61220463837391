import { RecordTypeEnum } from '@components/constants';
import { QualificationStatus } from '@views/qualification/constants';
import { SearchTextPagingParameters } from '../paging/paging-parameters.model';

export class GetPagedQualificationsParameters extends SearchTextPagingParameters {
  constructor(init?: Partial<GetPagedQualificationsParameters>) {
    super();
    Object.assign(this, init);
  }
  ver: number;
  type: string;
  status: QualificationStatus;
  ctgry: string;
  linkTargetType?: RecordTypeEnum;
  classificationInternalId?: string;
  latestApprovedOrVer1Draft: boolean;
}
