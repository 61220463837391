import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'defaultValue'
})
export class DefaultValuePipe implements PipeTransform {
  transform(value: string | number | null | undefined, defaultVal: string): string {
    if (!value) {
      return defaultVal;
    } else {
      return value.toString();
    }
  }
}
