// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `select[disabled] {
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 0.01px;
  text-overflow: "";
}

.label-height {
  height: 2rem;
}

.input-group-append .input-group-text {
  border-radius: 0rem 0.25rem 0.25rem 0rem;
}

.input-group-prepend .input-group-text {
  border-radius: 0.25rem 0rem 0rem 0.25rem;
}

legend {
  font-size: 1rem;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImxhYmVsLWFkai1zZWxlY3QuY29tcG9uZW50LnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDRSx3QkFBQTtFQUNBLHFCQUFBO0VBQ0EsbUJBQUE7RUFDQSxpQkFBQTtBQUNGOztBQUVBO0VBQ0UsWUFBQTtBQUNGOztBQUdFO0VBQ0Usd0NBQUE7QUFBSjs7QUFLRTtFQUNFLHdDQUFBO0FBRko7O0FBS0E7RUFDRSxlQUFBO0FBRkYiLCJmaWxlIjoibGFiZWwtYWRqLXNlbGVjdC5jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbInNlbGVjdFtkaXNhYmxlZF0ge1xuICAtd2Via2l0LWFwcGVhcmFuY2U6IG5vbmU7XG4gIC1tb3otYXBwZWFyYW5jZTogbm9uZTtcbiAgdGV4dC1pbmRlbnQ6IDAuMDFweDtcbiAgdGV4dC1vdmVyZmxvdzogJyc7XG59XG5cbi5sYWJlbC1oZWlnaHQge1xuICBoZWlnaHQ6IDJyZW07XG59XG5cbi5pbnB1dC1ncm91cC1hcHBlbmQge1xuICAuaW5wdXQtZ3JvdXAtdGV4dCB7XG4gICAgYm9yZGVyLXJhZGl1czogMHJlbSAwLjI1cmVtIDAuMjVyZW0gMHJlbTtcbiAgfVxufVxuXG4uaW5wdXQtZ3JvdXAtcHJlcGVuZCB7XG4gIC5pbnB1dC1ncm91cC10ZXh0IHtcbiAgICBib3JkZXItcmFkaXVzOiAwLjI1cmVtIDByZW0gMHJlbSAwLjI1cmVtO1xuICB9XG59XG5sZWdlbmQge1xuICBmb250LXNpemU6IDFyZW07XG59XG4iXX0= */`, "",{"version":3,"sources":["webpack://./src/app/components/form-components/label-adj-select/label-adj-select.component.scss"],"names":[],"mappings":"AAAA;EACE,wBAAA;EACA,qBAAA;EACA,mBAAA;EACA,iBAAA;AACF;;AAEA;EACE,YAAA;AACF;;AAGE;EACE,wCAAA;AAAJ;;AAKE;EACE,wCAAA;AAFJ;;AAKA;EACE,eAAA;AAFF;AACA,48BAA48B","sourcesContent":["select[disabled] {\n  -webkit-appearance: none;\n  -moz-appearance: none;\n  text-indent: 0.01px;\n  text-overflow: '';\n}\n\n.label-height {\n  height: 2rem;\n}\n\n.input-group-append {\n  .input-group-text {\n    border-radius: 0rem 0.25rem 0.25rem 0rem;\n  }\n}\n\n.input-group-prepend {\n  .input-group-text {\n    border-radius: 0.25rem 0rem 0rem 0.25rem;\n  }\n}\nlegend {\n  font-size: 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
