interface String {
  /**
   * Trims the string if it is a string.
   */
  trimIfString(): string;
  firstCharToUpperCase(): string;
  isValidGuid(): boolean;
  splitWords(): string;
  replaceAll(toFind: string, toReplace: string): string;
}

String.prototype.trimIfString = function (this: string): string {
  if (typeof this === 'string') {
    return this.trim();
  }

  return this;
};

String.prototype.firstCharToUpperCase = function (this: string): string {
  return this.charAt(0).toUpperCase() + this.slice(1);
};

String.prototype.splitWords = function (this: string): string {
  if (!this) {
    return this;
  }
  return this.replace(/([a-zA-Z])(?=[A-Z])/g, '$1 ');
};

String.prototype.isValidGuid = function (this: string): boolean {
  return checkIfValidUUID(this);
};

function checkIfValidUUID(str: string): boolean {
  // Regular expression to check if string is a valid UUID
  const regexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;

  return regexExp.test(str);
}

String.prototype.replaceAll = function (toFind: string, toReplace: string) {
  let target = this;
  return target.split(toFind).join(toReplace);
};
