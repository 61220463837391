import { Pipe, PipeTransform } from '@angular/core';
import { RecordTypeNumericEnum } from '@components/constants';

/**
 * Pipe to convert a record type numeric enum to a human readable string.
 * Used in the search module.
 */
@Pipe({
  name: 'recordname'
})
export class RecordNamePipe implements PipeTransform {
  transform(value: number | null | undefined): string {
    switch (value as RecordTypeNumericEnum) {
      case RecordTypeNumericEnum.Accreditation:
        return 'Accreditation';
      case RecordTypeNumericEnum.Qualification:
        return 'Qualification';
      case RecordTypeNumericEnum.Classification:
        return 'Classification';
      case RecordTypeNumericEnum.Programme:
        return 'Programme';
      case RecordTypeNumericEnum.Standard:
        return 'Standard';
      case RecordTypeNumericEnum.StandardGroup:
        return 'Standard group';
      case RecordTypeNumericEnum.MicroCredential:
        return 'Micro-credential';
      case RecordTypeNumericEnum.CMR:
        return 'CMR';
      case RecordTypeNumericEnum.Consent:
        return 'Consent to Assess';

      default:
        return 'Unknown';
    }
  }
}
