import { Component, ContentChildren, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ToggleCheckboxElementComponent } from '@components/toggle-checkbox-group/toggle-checkbox-element.component';
import { MatCheckboxChange } from '@angular/material/checkbox';

@Component({
  selector: 'app-toggle-checkbox-group',
  templateUrl: './toggle-checkbox-group.component.html',
  styleUrls: ['./toggle-checkbox-group.component.scss']
})
export class ToggleCheckboxGroupComponent {
  // A list of ToggleCheckboxElementComponent elements that will be rendered as checkbox buttons and related templates
  @ContentChildren(ToggleCheckboxElementComponent) children: ToggleCheckboxElementComponent[];
  // A title to display above the group of Checkbox buttons
  @Input() title: string;
  @Input() formGroup: FormGroup<any>;
  // The host component is responsible for triggering an error message to show as needed
  @Input() showError: boolean = false;
  @Input() errorMessage: string;

  public isCheckboxSelected = (formFieldName: string): boolean => {
    return this.formGroup?.get(formFieldName)?.value as boolean;
  };

  public updateCheckboxButton = (event: MatCheckboxChange, formsControlName: string) => {
    this.formGroup.get(formsControlName)?.setValue(true);
  };

  // This function prevents the panel body from toggling when the panel header is clicked to make hiding/showing
  // the template bodies completely dependent on the Checkbox button
  public onPanelTitleClick = (event: Event) => {
    event.stopPropagation();
  };
}
