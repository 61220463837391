import { RecordTypeEnum } from '@components/constants';
import { IQualificationDeveloper } from '@core/services/models/shared/qualification-developer';
import { AccreditationStatusEnum } from '@views/accreditation/constants';
import { ClassificationStatus } from '@views/classification/constants';
import { CmrStatusEnum } from '@views/cmr/constants';
import { ConsentStatusEnum } from '@views/consent-to-assess/constants';
import { MicroCredentialStatusEnum } from '@views/micro-credential/constants';
import { ProgrammeStatusEnum } from '@views/programme/constants';
import { QualificationStatus } from '@views/qualification/constants';
import { StandardStatusEnum } from '@views/standard/constants';

export class RecordDetails {
  public constructor(init?: Partial<RecordDetails>) {
    Object.assign(this, init);
  }

  type: RecordTypeEnum;
  recordTitle: string | null;
  qualificationNumber: string;
  qualificationStatus: QualificationStatus;
  developer: string;
  qualificationType: string;
  qualificationCategory: string;

  /*Accreditation*/
  cfSystemType?: number | string | null;
  accreditationStatus: AccreditationStatusEnum;

  /*Classification*/
  accType?: number | string | null;
  depthLevel?: number | string | null;
  parent?: string | null;
  classificationStatus: ClassificationStatus;

  /*Programme*/
  progStatus: ProgrammeStatusEnum;
  title: string | undefined;
  progNo: string | undefined;
  progType: string | undefined;
  owner: IQualificationDeveloper | undefined;

  /*Standard*/
  stdType: number | null;
  stdStatus: StandardStatusEnum | null;
  stdTitle: string | undefined | null;
  stdNo: string | undefined | null;
  ssb: IQualificationDeveloper | undefined | null;

  /*Micro-Credential*/
  mcStatus: MicroCredentialStatusEnum;
  mcTitle: string | undefined | null;
  mcNo: string | undefined | null;
  mcDev: IQualificationDeveloper | undefined | null;

  /*Cmr*/
  cmrStatus: CmrStatusEnum;
  cmrTitle: string | undefined | null;
  cmrNo: string | undefined | null;
  cmrDev: IQualificationDeveloper | undefined | null;

  /*Consent*/
  consentRecordTypeTitle: string | undefined | null;
  moeNumber: string | undefined | null;
  consentStatus: ConsentStatusEnum | undefined | null;
}
