export const environment = {
  displayApiErrorDetail: true,
  production: false,
  appVersion: `1.0.0-dev`,
  apiBaseUrl: `https://qcr-apim-dev-enterprise.azure-api.net/qcr/v1`,
  authenticationAudience: `ea8273b6-a108-4074-8753-059efdb49784`,
  authenticationAuthority: `https://login.microsoftonline.com/0ae1ae1f-992e-41c7-965f-b0236201d1af`,
  authenticationAuthorityDomain: '',
  applicationInsightsKey: '',
  authorizationBaseUrl: '',
  authorizationScope: `api://56a745e7-4233-44c6-9365-0f562d2030e4/QCR.API.ReadWrite`,
  accessTokenEndpoint: '',
  logOutEndpoint: '',
  redirectUri: `https://web-qcr-dev.nzqa.govt.nz`
};
