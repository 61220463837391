import { AfterViewChecked, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { LoadingService } from '@core/services/loading.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent implements OnInit, OnDestroy {
  @Input() inProgress: boolean = false;
  isLoading: boolean = false;

  isLoadingSubscription: Subscription;
  constructor(
    public loadingService: LoadingService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.isLoadingSubscription = this.loadingService.isLoading$.subscribe(x => {
      this.isLoading = x;
      this.cd.detectChanges();
    });
  }

  ngOnDestroy(): void {
    this.isLoadingSubscription?.unsubscribe();
  }
}
