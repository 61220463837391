import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { VersionStatusEnum } from '@components/constants';
import {
  QualificationCategories,
  QualificationStatus,
  QualificationTypes,
  QualityAssuranceBodies
} from '@views/qualification/constants';
import {
  IGetTransitionalDto,
  PatchQualificationTransitionalDto
} from '@views/qualification/manage-transition-dates/qualification-transition.model';
import { Observable } from 'rxjs';
import { apiRoutes } from '../constants/api-routes.constants';
import { ValidationResult } from './models/bulk-update-validation-result-model';
import { IMicroCredential } from './models/micro-credential/micro-credential';
import { IProgramme } from './models/programme/programme';
import { GetPagedQualifications } from './models/qualification/get-paged-qualifications';
import { GetPagedQualificationsParameters } from './models/qualification/get-paged-qualifications-parameters';
import { PostQualificationResponse } from './models/qualification/post-qualification-response';
import { IPutQualificationStatusDto, IQualification } from './models/qualification/qualification';
import { UpdateQualification } from './models/qualification/update-qualification';
import { PatchQualificationFundingDto } from './models/qualification/update-qualification-funding.model';
import { UpdateQualificationTranslation } from './models/qualification/update-qualification-translation';
import { Lookup } from './models/shared/lookup';
import { IStandard } from './models/standard/standard';
import { QualificationLogos } from '@views/qualification-logos/qualification-logos.model';

@Injectable({
  providedIn: 'root'
})
export class QualificationService {
  constructor(private http: HttpClient) {}

  static qualityAssuranceBodies: Lookup<number>[] = [
    {
      text: 'New Zealand Qualifications Authority',
      value: QualityAssuranceBodies.NewZealandQualificationsAuthority
    },
    {
      text: 'Committee of University Academic Programmes',
      value: QualityAssuranceBodies.CommitteeOfUniversityAcademicProgrammes
    },
    {
      text: 'Colleges Of Education Accreditation Committee',
      value: QualityAssuranceBodies.CollegesOfEducationAccreditationCommittee
    },
    {
      text: 'Quality Assurance Division NZQA',
      value: QualityAssuranceBodies.QualityAssuranceDivisionNZQA
    },
    {
      text: 'Institutes Of Technology And Polytechnics Quality',
      value: QualityAssuranceBodies.InstitutesOfTechnologyAndPolytechnicsQuality
    }
  ];

  static qualCategories: Lookup<number>[] = [
    {
      text: 'Education Organisation Developed Qualification',
      value: QualificationCategories.EducationOrganisationDevelopedQualification
    },
    {
      text: 'Local Qualification',
      value: QualificationCategories.LocalQualification
    },
    {
      text: 'Legislated Wānanga Qualification',
      value: QualificationCategories.LegislatedWanangaQualification
    },
    {
      text: 'National Qualification',
      value: QualificationCategories.NationalQualification
    },
    {
      text: 'New Zealand Qualification',
      value: QualificationCategories.NewZealandQualification
    }
  ];

  static qualificationTypes: Lookup<number>[] = [
    {
      text: "Bachelor's Degree",
      value: QualificationTypes.BachelorsDegree
    },
    {
      text: 'Bachelor Honours Degree',
      value: QualificationTypes.BachelorHonoursDegree
    },
    {
      text: 'Certificate',
      value: QualificationTypes.Certificate
    },
    {
      text: 'Diploma',
      value: QualificationTypes.Diploma
    },
    {
      text: 'Doctoral Degree',
      value: QualificationTypes.DoctoralDegree
    },
    {
      text: 'Graduate Certificate',
      value: QualificationTypes.GraduateCertificate
    },
    {
      text: 'Graduate Diploma',
      value: QualificationTypes.GraduateDiploma
    },
    {
      text: "Master's Degree",
      value: QualificationTypes.MastersDegree
    },
    {
      text: 'Postgraduate Certificate',
      value: QualificationTypes.PostgraduateCertificate
    },
    {
      text: 'Postgraduate Diploma',
      value: QualificationTypes.PostgraduateDiploma
    }
  ];

  static qualificationStatuses: Lookup<number>[] = [
    {
      text: 'Draft',
      value: QualificationStatus.Draft
    },
    {
      text: 'Current',
      value: QualificationStatus.Current
    },
    {
      text: 'Expiring',
      value: QualificationStatus.Expiring
    },
    {
      text: 'Discontinued',
      value: QualificationStatus.Discontinued
    },
    {
      text: 'Withdrawn',
      value: QualificationStatus.Withdrawn
    },
    {
      text: 'Declined',
      value: QualificationStatus.Declined
    }
  ];

  static versions: Lookup<number>[] = [
    {
      text: 'Draft',
      value: VersionStatusEnum.Draft
    },
    {
      text: 'Approved',
      value: VersionStatusEnum.Approved
    },
    {
      text: 'Superseded',
      value: VersionStatusEnum.Superseded
    },
    {
      text: 'Withdrawn',
      value: VersionStatusEnum.Withdrawn
    },
    {
      text: 'Declined',
      value: VersionStatusEnum.Declined
    },
    {
      text: 'Discontinued',
      value: VersionStatusEnum.Discontinued
    }
  ];

  postQualification(qualification: IQualification): Observable<PostQualificationResponse> {
    const url = '/qualifications';
    return this.http.post<PostQualificationResponse>(url, qualification);
  }

  get(qualNo: string, id: string | null): Observable<IQualification> {
    return id
      ? this.http.get<IQualification>(apiRoutes.qualification.get(qualNo, id))
      : this.http.get<IQualification>(apiRoutes.qualification.getByNumber(qualNo));
  }

  getByQualNo(qualNo: string): Observable<IQualification[]> {
    return this.http.get<IQualification[]>(apiRoutes.qualification.getAll(qualNo));
  }

  getCreateDraft(qualNo: string): Observable<IQualification> {
    return this.http.get<IQualification>(apiRoutes.qualification.getCreateDraft(qualNo));
  }

  getTransitional(qualNo: string, verNo: number): Observable<IGetTransitionalDto> {
    return this.http.get<IGetTransitionalDto>(apiRoutes.qualification.getTransitional(qualNo, verNo));
  }

  updateTransitional(qualNo: string, model: PatchQualificationTransitionalDto[]) {
    return this.http.patch<void>(apiRoutes.qualification.updateTransitional(qualNo), model);
  }

  bulkUpdate(csv: File, isTrialRun: boolean) {
    const formData: FormData = new FormData();
    formData.append('file', csv, csv.name);
    return this.http.post<ValidationResult[]>(apiRoutes.qualification.bulkUpdate(isTrialRun), formData);
  }

  updateFunding(qualNo: string, model: PatchQualificationFundingDto) {
    return this.http.patch<void>(apiRoutes.qualification.updateFunding(qualNo), model);
  }

  paged(parameters: GetPagedQualificationsParameters) {
    return this.http.get<GetPagedQualifications>(apiRoutes.qualification.paged(parameters.toQueryString()));
  }

  update(qualification: UpdateQualification): Observable<string> {
    return this.http.put<string>(apiRoutes.qualification.update(qualification.qualNo), qualification);
  }

  createDraft(qualNo: string, qualification: UpdateQualification): Observable<string> {
    return this.http.post<string>(apiRoutes.qualification.createDraft(qualNo), qualification);
  }

  upsertTranslation(qualNo: string, qualification: UpdateQualificationTranslation): Observable<string> {
    return this.http.put<string>(apiRoutes.qualification.upsertTranslation(qualNo), qualification);
  }

  updateStatus(qualNo: string, model: IPutQualificationStatusDto): Observable<void> {
    return this.http.patch<void>(apiRoutes.qualification.updateStatus(qualNo), model);
  }

  getTranslation(qualNo: string, verNo: number): Observable<IQualification> {
    return this.http.get<IQualification>(apiRoutes.qualification.getTranslation(qualNo, verNo));
  }

  getQualityAssuranceBodies(): Lookup<number>[] {
    return QualificationService.qualityAssuranceBodies;
  }

  getQualificationCategories(): Lookup<number>[] {
    return QualificationService.qualCategories;
  }

  getQualificationTypes(): Lookup<number>[] {
    return QualificationService.qualificationTypes;
  }

  getQualificationStatuses(): Lookup<number>[] {
    return QualificationService.qualificationStatuses;
  }

  getQualificationVersions(): Lookup<number>[] {
    return QualificationService.versions;
  }

  getAssociatedProgrammes(qualNo: string, verNo: number) {
    return this.http.get<IProgramme[]>(apiRoutes.qualification.programmeAssociations(qualNo, verNo));
  }

  getAssociatedMicroCredentials(qualNo: string, verNo: number) {
    return this.http.get<IMicroCredential[]>(apiRoutes.qualification.microCredentialAssociations(qualNo, verNo));
  }

  getDraftVersionOneQualificationStatuses(currentStatus: QualificationStatus): Lookup<number>[] {
    let validStatuses: QualificationStatus[] = [
      currentStatus,
      QualificationStatus.Withdrawn,
      QualificationStatus.Declined,
      QualificationStatus.Current
    ];

    return QualificationService.qualificationStatuses.map(status => {
      if (!validStatuses.includes(status.value)) status.disabled = true;
      return status;
    });
  }

  getDraftVersionOneVersionStatuses(currentVersion: VersionStatusEnum): Lookup<number>[] {
    let validVersions: VersionStatusEnum[] = [currentVersion];

    return QualificationService.versions.map(version => {
      if (!validVersions.includes(version.value)) {
        version.disabled = true;
      }
      return version;
    });
  }

  getCurrentDraftQualificationStatuses(currentStatus: QualificationStatus): Lookup<number>[] {
    let validStatuses: QualificationStatus[] = [currentStatus];

    return QualificationService.qualificationStatuses.map(status => {
      if (!validStatuses.includes(status.value)) {
        status.disabled = true;
      }
      return status;
    });
  }

  getCurrentDraftVersionStatuses(currentVersion: VersionStatusEnum): Lookup<number>[] {
    let validVersions: VersionStatusEnum[] = [
      currentVersion,
      VersionStatusEnum.Withdrawn,
      VersionStatusEnum.Declined,
      VersionStatusEnum.Approved
    ];

    return QualificationService.versions.map(version => {
      if (!validVersions.includes(version.value)) {
        version.disabled = true;
      }
      return version;
    });
  }

  getDefaultQualificationStatuses(currentStatus: QualificationStatus): Lookup<number>[] {
    let validStatuses: QualificationStatus[] = [currentStatus];

    return QualificationService.qualificationStatuses.map(status => {
      if (!validStatuses.includes(status.value)) status.disabled = true;
      return status;
    });
  }

  getDefaultVersionStatuses(currentVersion: VersionStatusEnum): Lookup<number>[] {
    let validVersions: VersionStatusEnum[] = [currentVersion];

    return QualificationService.versions.map(version => {
      if (!validVersions.includes(version.value)) version.disabled = true;
      return version;
    });
  }

  getAssociatedStandards(qualificationNumber: string, version: number) {
    return this.http.get<IStandard[]>(apiRoutes.qualification.standardAssociations(qualificationNumber, version));
  }

  updateLogos(qualNo: string, model: QualificationLogos): Observable<void> {
    return this.http.patch<void>(apiRoutes.qualification.logos(qualNo), model);
  }
}
