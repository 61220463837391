import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'casing'
})
export class CasingPipe implements PipeTransform {
  transform(value: any, ...args: unknown[]): unknown {
    if (!value) {
      return value;
    }
    //capitalize first letter
    const str = value.toString();
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
}
