import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FullScreenModalComponent } from './full-screen-modal/full-screen-modal.component';
import { CreateRecordLayoutComponent } from './create-record-layout/create-record-layout.component';
import { ManageRecordLayoutComponent } from './manage-record-layout/manage-record-layout.component';
import { RecordHeaderModule } from '@components/record-header/record-header.module';
import { BrowseLayoutComponent } from './browse-layout/browse-layout.component';
import { RouterModule } from '@angular/router';
import { MdModalComponent } from './md-modal/md-modal.component';

@NgModule({
  declarations: [
    FullScreenModalComponent,
    CreateRecordLayoutComponent,
    ManageRecordLayoutComponent,
    BrowseLayoutComponent,
    MdModalComponent
  ],
  imports: [CommonModule, RouterModule, RecordHeaderModule],
  exports: [
    FullScreenModalComponent,
    CreateRecordLayoutComponent,
    ManageRecordLayoutComponent,
    BrowseLayoutComponent,
    MdModalComponent
  ]
})
export class LayoutsModule {}
