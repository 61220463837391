import {
  Component,
  OnInit,
  AfterViewInit,
  ViewChild,
  ElementRef,
  ChangeDetectorRef,
  NgZone,
  OnDestroy
} from '@angular/core';
import { Router } from '@angular/router';
import { RecordTypeEnum } from '@components/constants';
import { ClassificationTreeDto, StandardGroupTreeDto } from '@components/folder-tree/folder-tree/FolderTreeRootNode';
import { SizeNotifierService } from '@core/directives/size-notifier.directive';
import { ClassificationService } from '@core/services/classification.service';
import { FolderTreeService } from '@core/services/folder-tree.service';
import { StandardGroupsService } from '@core/services/standard-groups.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-browse-layout',
  templateUrl: './browse-layout.component.html',
  styleUrls: ['./browse-layout.component.scss']
})
export class BrowseLayoutComponent implements OnInit, AfterViewInit {
  /**
   * Reference to the tree element. Used to set the height of the tree scroller
   *
   * @type {ElementRef}
   * @memberof BrowseLayoutComponent
   */
  @ViewChild('browsetree', { static: false }) treeElement: ElementRef;

  /**
   * The height of the tree scroller element
   *
   * @memberof BrowseLayoutComponent
   */
  treeHeight: number;

  constructor(
    private sizeNotifierService: SizeNotifierService,
    private service: FolderTreeService,
    private router: Router,
    private classificationService: ClassificationService,
    private standardGroupService: StandardGroupsService,
    private cdr: ChangeDetectorRef,
    private zone: NgZone
  ) {}

  ngAfterViewInit(): void {
    //setting this value because the tree height is not set until after the view is initialized.
    const initHeaderHeight = 230;
    this.treeHeight = Math.max(100, window.innerHeight - initHeaderHeight);
    this.cdr.detectChanges();
  }

  ngOnInit(): void {
    this.service.getTreeNodeClick().subscribe(({ item, recordType }) => {
      if (recordType === RecordTypeEnum.Classification) {
        this.router.navigate(
          ['/browse', 'classification', (item as ClassificationTreeDto).internalId],
          this.classificationService.getRouterQueryParams(item as ClassificationTreeDto)
        );
      } else if (recordType === RecordTypeEnum.StandardGroup) {
        this.router.navigate(
          ['browse', recordType, item.id],
          this.standardGroupService.getRouterQueryParams(item as StandardGroupTreeDto)
        );
      }
    });

    this.sizeNotifierService.elementHeightChanged.pipe(untilDestroyed(this)).subscribe((height: number) => {
      //Need to run this in a zone because angular doesn't know about the resize event
      this.zone.run(() => {
        this.treeHeight = Math.max(100, height, window.innerHeight - this.getVerticalOffsetOfTree());
      });
    });
  }

  getVerticalOffsetOfTree() {
    let bodyRect = document.body.getBoundingClientRect(),
      elemRect = this.treeElement.nativeElement.getBoundingClientRect(),
      offset = elemRect.top - bodyRect.top;

    return offset;
  }
}
