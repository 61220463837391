import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { apiRoutes } from '@core/constants/api-routes.constants';
import { ManageStatus } from '@core/services/models/status/manage-status.model';
import { Observable } from 'rxjs';
import { IAccreditation } from './models/accreditation/accreditation.model';
import { SearchTextPagingParameters } from './models/paging/paging-parameters.model';
import { Lookup } from './models/shared/lookup';
import { AccreditationStatusEnum, MonitoringStatusEnum } from '@views/accreditation/constants';

@Injectable({
  providedIn: 'root'
})
export class AccreditationService {
  get(accId: string): Observable<IAccreditation> {
    return this.http.get<IAccreditation>(apiRoutes.accreditation.get(accId));
  }

  create(model: IAccreditation): Observable<IAccreditation> {
    return this.http.post<IAccreditation>(apiRoutes.accreditation.create, model);
  }

  update(id: string, model: IAccreditation): Observable<IAccreditation> {
    return this.http.put<IAccreditation>(apiRoutes.accreditation.update(id), model);
  }

  funding(id: string, model: IAccreditation): Observable<IAccreditation> {
    return this.http.patch<IAccreditation>(apiRoutes.accreditation.funding(id), model);
  }

  updateStatus(recordNo: string, model: ManageStatus): Observable<IAccreditation> {
    return this.http.patch<IAccreditation>(apiRoutes.accreditation.updateStatus(recordNo), model);
  }

  getAccreditations(searchText: string, count: number): Observable<IGetPagedAccreditationDto> {
    let params = new GetAccreditationPagingParameters({
      searchText: searchText,
      limit: count,
      offset: 0
    });
    return this.http.get<IGetPagedAccreditationDto>(apiRoutes.accreditation.paged(params.toQueryString()));
  }

  public static statusOptions: Lookup<number>[] = [
    {
      text: 'Draft',
      value: AccreditationStatusEnum.Draft
    },
    {
      text: 'Accredited',
      value: AccreditationStatusEnum.Accredited
    },
    {
      text: 'Not Accredited',
      value: AccreditationStatusEnum.NotAccredited
    },
    {
      text: 'Removed',
      value: AccreditationStatusEnum.Removed
    },
    {
      text: 'Accreditation Withdrawn',
      value: AccreditationStatusEnum.Withdrawn
    },
    { text: 'Lapsed', value: AccreditationStatusEnum.Lapsed },
    {
      text: 'Exempt',
      value: AccreditationStatusEnum.Exempt
    }
  ];

  public static monitoringStatusOptions: Lookup<number>[] = [
    {
      text: 'Annual Programme Evaluation',
      value: MonitoringStatusEnum.AnnualProgrammeEvaluation
    },
    {
      text: 'Monitor With Independant Evaluator',
      value: MonitoringStatusEnum.MonitorWithIndependantEvaluator
    },
    {
      text: 'Monitor Only',
      value: MonitoringStatusEnum.MonitorOnly
    },
    {
      text: 'Not Required',
      value: MonitoringStatusEnum.NotRequired
    },
    {
      text: 'Unknown',
      value: MonitoringStatusEnum.Unknown
    }
  ];

  public static monitoringCycleOptions: Lookup<number>[] = [
    {
      text: '1 year',
      value: 7401
    }
  ];

  constructor(private http: HttpClient) {}
}

export interface IGetPagedAccreditationDto {
  items: IAccreditation[];
  totalCount: number;
}

export class GetAccreditationPagingParameters extends SearchTextPagingParameters {
  public constructor(init?: Partial<GetAccreditationPagingParameters>) {
    super();
    Object.assign(this, init);
  }

  public title?: string;
  public progNo?: string;
  public progVerNo?: number;
  public status?: AccreditationStatusEnum[];
}
