import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {
  transform(value: unknown, ...args: unknown[]): any[] {
    const array = value as any[];
    const field = args[0] as string;
    const fieldValue = args[1];
    return array.filter(i => i[field] == fieldValue);
  }
}
