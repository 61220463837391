import { Component, ElementRef, HostListener, Input, OnInit } from '@angular/core';
import { NgxPopperjsDirective, NgxPopperjsPlacements, NgxPopperjsTriggers } from 'ngx-popperjs';
import { ContextMenuService } from './context-menu.service';

@Component({
  selector: 'app-context-menu',
  templateUrl: './context-menu.component.html',
  styleUrls: ['./context-menu.component.scss']
})
export class ContextMenuComponent implements OnInit {
  popperTriggers = NgxPopperjsTriggers;
  popperPlacements = NgxPopperjsPlacements;
  @Input() disabled: boolean = false;
  @Input() dataTestId: string = 'banner-context-menu';
  @Input() ariaLabel: string;
  @Input() menuId: string;

  /**
   * This is the directive reference that we will use to hide the popper
   * Set when the popper is shown/opened
   */
  popperjsDirective: NgxPopperjsDirective;

  /**
   * User popperjs to position the context menu vs manually positioning it
   */
  @Input() usePopper: boolean = false;

  showOptions: boolean;

  @Input() hasCircle: boolean = false;
  constructor(
    private service: ContextMenuService,
    private elementRef: ElementRef
  ) {}

  @HostListener('document:click', ['$event.target'])
  public onPageClick(targetElement: HTMLElement) {
    const clickedInside = this.elementRef.nativeElement.contains(targetElement);
    if (!clickedInside || targetElement.hasAttribute('context-menu-option')) {
      this.showOptions = false;
    }
  }

  ngOnInit(): void {
    this.service.hideMenu.subscribe(x => {
      this.showOptions = false;
    });
  }

  toggleOptions() {
    this.showOptions = !this.showOptions;
  }

  optionsClick(): void {
    this.popperjsDirective.hide();
  }

  onShown(event: NgxPopperjsDirective) {
    //Save the directive reference so we can hide it later
    this.popperjsDirective = event;
  }
}
