import { formatDate } from '@angular/common';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { FolderTreeRootNode } from '@components/folder-tree/folder-tree/FolderTreeRootNode';
import { TableRowFormGroup } from '@components/paged-records-select/PagedRecordsBase.component';
import { TableDefinitionColumn } from '@components/table/table-definition.model';
import fileDownload from 'js-file-download';

/**
 * Summary
 *   Gets the exact key string for a particular enum value.
 * Example
 *   const tuesday = stringToEnum(daysOfWeek, 2) // for a enum daysOfWeek that has an entry 'Tuesday = 2'
 * @param {any} enumerable
 * @param {unknown} value
 * @returns {string} The key string of the enumerable entry.
 */
export function valueToEnumKey(enumerable: any, value: unknown): string | null {
  for (let k in enumerable) if (enumerable[k] == value) return k;
  return null;
}

export function enumKeyToValue(enumerable: any, value: unknown): string | null {
  for (let k in enumerable) if (k == value) return enumerable[k];
  return null;
}

export function getValueByKeyForStringEnum<T>(value: unknown, enumerable: T): string {
  return Object.entries(enumerable as unknown as GenericEnum).find(([key, val]) => key === value)?.[1] as string;
}

/**
 * Gets the value of a specific object property given the property name, the property name
 * can include a dot to find the value of a nested property, also the returned result
 * can be translated into an enum if an enum is provided.
 */
export function getPropertyValueByName(item: object, propName: string, isDate: boolean = false, toEnum?: any): unknown {
  const value = propName.split('.').reduce((prev: any, curr: any) => {
    return prev ? prev[curr] : undefined;
  }, item || self);
  if (isDate) {
    return value ? formatDate(value, 'dd/MM/yyyy', 'en') : '';
  }
  return toEnum ? toEnum[value as string].splitWords() : value;
}

export type GenericEnum = { [key: number | string]: unknown } | ArrayLike<unknown>;

export function folderTreeMapper<T>(item: FolderTreeRootNode<T>, TCreator: new (item: T) => T): FolderTreeRootNode<T> {
  return {
    item: new TCreator(item.item),
    children: item.children ? item.children.map(child => folderTreeMapper<T>(child, TCreator)) : []
  };
}

export function folderTreeArrayMapper<T>(
  TCreator: new (item: T) => T
): (value: FolderTreeRootNode<T>[], index: number) => FolderTreeRootNode<T>[] {
  return folderTreeArray => {
    return folderTreeArray.map(folderTreeNode => folderTreeMapper<T>(folderTreeNode, TCreator));
  };
}

/**
 *Getters for JS classes only work when you build a new object using new Standard()
 *It does not work when you get it from the API
 *To solve that one can use the utils.genericItemReMapper or the utils.genericArrayReMapper
 *You can use that in the api call to ensure all objects have all the getters in the class and
 *that the constructor is called
 */
export function genericItemReMapper<T>(item: T, TCreator: new (item: T) => T): T {
  return new TCreator(item);
}

/**
 *Getters for JS classes only work when you build a new object using new Standard()
 *It does not work when you get it from the API
 *To solve that one can use the utils.genericItemReMapper or the utils.genericArrayReMapper
 *You can use that in the api call to ensure all objects have all the getters in the class and
 *that the constructor is called
 */
export function genericArrayReMapper<T>(TCreator: new (item: T) => T): (value: T[]) => T[] {
  return array => {
    return array.map(item => genericItemReMapper(item, TCreator));
  };
}

/**
 * Shorthand to generate a table row form group for an entity
 * @param item The record to add in the form group
 * @param isDeleted A boolean to indicate if the record has been flagged for deletion
 * @returns A TableRowFormGroup with the supplied data filled.
 */
export function toTableRowFormGroup<T>(item: T, isDeleted: boolean): TableRowFormGroup<T> {
  return new FormGroup({
    record: new FormControl<T>(item) as FormControl<T>,
    isDeleted: new FormControl<boolean>(isDeleted) as FormControl<boolean>,
    isNew: new FormControl<boolean>(false) as FormControl<boolean>
  }) as TableRowFormGroup<T>;
}

export function toTableRowFormArray<T>(
  data: T[],
  markAsTouched: boolean = true,
  keyProperty: string = 'id'
): FormArray<TableRowFormGroup<T>> {
  const formArray = new FormArray<TableRowFormGroup<T>>([]);
  if (data) {
    data.forEach(i => {
      if (
        !formArray?.value.find(
          r =>
            getPropertyValueByName(r.record! as Object, keyProperty) ===
            getPropertyValueByName(i as Object, keyProperty)
        )
      ) {
        formArray.push(toTableRowFormGroup(i, false));
      }
    });
    markAsTouched && formArray.markAsTouched();
  }
  return formArray;
}

export function toggle(bool: boolean): boolean {
  bool = !bool;
  return bool;
}

export function exportData(data: any[], columns: TableDefinitionColumn[], fileName: string) {
  const dataArray: string[] = [];
  /* Compose a header string of all column names except for the ones that have ignoreInExport set */
  const headerString = columns
    .filter(c => !c.ignoreInExport)
    .map(c => c.displayName)
    .join(',')
    .concat('\n');
  /* Add the header to the array to be exported */
  dataArray.push(headerString);
  data.forEach(d => {
    const lineParts: string[] = [];
    /* Get the values of all columns except the ones that have ignoreInExport set */
    columns
      .filter(c => !c.ignoreInExport)
      .forEach(c => {
        /* Get the cell value from a customFormatter (if any) or normally through a call to getPropertyValueByName */
        const columnValue = c.customFormatter
          ? c.customFormatter(d)
          : (getPropertyValueByName(d as any as object, c.name, c.isDate, c.enum) as string);
        /* Surround the cell value by "" to account for data that already have commas */
        lineParts.push('"'.concat(columnValue || '').concat('"'));
      });
    /* Join all line values with a comma and add a trailing new line character */
    const line = lineParts.join(',').concat('\n');
    dataArray.push(line);
  });
  const blob = new Blob(dataArray);
  fileDownload(blob, fileName);
}

export function equalsIgnoreCase(a: string, b: string): boolean {
  return typeof a === 'string' && typeof b === 'string'
    ? a.localeCompare(b, undefined, { sensitivity: 'base' }) === 0
    : a === b;
}
