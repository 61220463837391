import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ControlValueAccessorConnector } from '../control-value-accessor-connector';

@Component({
  selector: 'app-text',
  templateUrl: './text.component.html',
  styleUrls: ['./text.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextComponent),
      multi: true
    }
  ]
})
export class TextComponent extends ControlValueAccessorConnector implements ControlValueAccessor {
  @Input() placeholder: string = '';
  @Input() autocomplete: boolean = false;
  @Input() maxlength: number | null = null;
  @Input() hideWarning: boolean = false;
  @Input() value: string | null | undefined;
  @Input() readonly: boolean = false;
  @Input() disabledWithWhiteBg: boolean = false;
  @Input() title: boolean = false;
  @Input() attributeId = this.formControlName;
  @Input() labelMaori: string | null = null;
  @Input() isEditMode: boolean = true;

  constructor() {
    super();
  }

  /**
   * Get the autocomplete input element value
   * @returns on/off state of the autocomplete
   */
  autoComplete() {
    return this.autocomplete ? 'on' : 'off';
  }
}
