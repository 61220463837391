export abstract class PagingResponse<T> {
  totalCount: number;
  items: T[];
  versionStatusCounts?: VersionStatusCount[];
  entityStatusCounts?: StatusCount[];
}

export class VersionStatusCount {
  verStatus: number;
  verStatusCount: number;
}

export class StatusCount {
  status: number;
  statusCount: number;
}

/**
 * Paging response with offset to support paging in the UI.
 */
export abstract class PagingOffsetResponse<T> {
  totalCount: number;
  offset: number;
  items: T[];
}
