import { StandardStatusEnum, StandardVersionStatusEnum } from '@views/standard/constants';
import { SearchTextPagingParameters } from '../paging/paging-parameters.model';
import { IStandard } from './standard';

export interface IGetPagedStandardDto {
  items: IStandard[];
  totalCount: number;
}

export class GetStandardPagingParameters extends SearchTextPagingParameters {
  status: StandardStatusEnum[];
  override verStatus: StandardVersionStatusEnum[];

  public constructor(init?: Partial<GetStandardPagingParameters>) {
    super();
    Object.assign(this, init);
  }
}
