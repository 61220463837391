import {
  ControlValueAccessor,
  UntypedFormControl,
  FormControlDirective,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { Component, Input, ViewChild } from '@angular/core';

@Component({
  template: ''
})
// Not a component, but a base class for components that implement ControlValueAccessor
// eslint-disable-next-line @angular-eslint/component-class-suffix
export abstract class ControlValueAccessorConnector implements ControlValueAccessor {
  @ViewChild(FormControlDirective, { static: true }) formControlDirective: FormControlDirective;

  @Input() formControlName: string;

  @Input() label: string;
  @Input() formGroup!: UntypedFormGroup;
  @Input() inputType: string = 'text';
  @Input() dataTestId?: string;

  get control(): UntypedFormControl {
    return this.formGroup?.get(this.formControlName) as UntypedFormControl;
  }

  get hasFormControlRequiredValidator() {
    return this.control?.hasValidator(Validators.required);
  }

  /**
   * Get the max value provided to the max validator.  Used for displaying the error label
   * */
  get validatorMax(): string | undefined {
    if (this.control.errors && this.control.errors['max']) {
      let error = this.control.errors['max'];
      return error ? error['max']?.toString() : undefined;
    }
    return undefined;
  }

  /**
   * Get the min value provided to the min validator.  Used for displaying the error label
   * */
  get validatorMin(): string | undefined {
    if (this.control.errors && this.control.errors['min']) {
      let error = this.control.errors['min'];
      return error ? error['min']?.toString() : undefined;
    }
    return undefined;
  }

  /**
   * Prevent the user to type e exponent when the input type is number
   */
  preventEIfInputTypeIsNumber(e: any) {
    if (this.inputType !== 'number') {
      return;
    }

    if ([69, 187, 188, 189].includes(e.keyCode)) {
      e.preventDefault();
    }
  }

  registerOnTouched(fn: any): void {
    this.formControlDirective?.valueAccessor?.registerOnTouched(fn);
  }

  registerOnChange(fn: any): void {
    this.formControlDirective?.valueAccessor?.registerOnChange(fn);
  }

  writeValue(obj: any): void {
    this.formControlDirective?.valueAccessor?.writeValue(obj);
  }

  setDisabledState(isDisabled: boolean): void {
    this.formControlDirective?.valueAccessor?.setDisabledState ? isDisabled : () => {};
  }
}
