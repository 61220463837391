import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms/';

@Component({
  selector: 'app-rich-warning-message',
  templateUrl: './rich-warning-message.component.html',
  styleUrls: ['./rich-warning-message.component.scss']
})
/***
 * Displays warning messages that would otherwise need to inject custom HTML code from the typescript such as
 * the standards associated to CRMs, it expects a model of type RichWarning to show a main warning message and
 * related grid of information, the RichWarning model could be passed directly or via a form control
 */
export class RichWarningMessageComponent implements OnInit {
  @Input() form?: FormGroup;
  @Input() formControlName?: string = 'warning';
  @Input() richWarning?: RichWarning;
  @Input() defaultWarningSuffix?: string = '+10 conflicts';
  public showTable: boolean = false;

  public ngOnInit(): void {
    if (!this.richWarning && this.form) {
      this.richWarning = this.form.get(this.formControlName!)?.value;
    }
    this.showTable = this.richWarning?.warningValues?.length! < 10;
  }
}

export class RichWarning {
  public warningHeader: string;
  public warningColumnHeaders?: string[];
  public warningValues?: string[][];
}
