import { Lookup } from '@core/services/models/shared/lookup';

export enum QualificationStatus {
  Draft = 1001,
  Current = 1002,
  Expiring = 1003,
  Discontinued = 1004,
  Withdrawn = 1005,
  Declined = 1006
}

export class QualificationStatusString {
  public static get(status: QualificationStatus): string {
    switch (status) {
      case QualificationStatus.Draft:
        return 'Draft';
      case QualificationStatus.Current:
        return 'Current';
      case QualificationStatus.Expiring:
        return 'Expiring';
      case QualificationStatus.Discontinued:
        return 'Discontinued';
      case QualificationStatus.Withdrawn:
        return 'Withdrawn';
      case QualificationStatus.Declined:
        return 'Declined';
    }
  }
}

export enum QualificationVersion {
  Draft = 'Draft'
}

export enum PrimaryLanguage {
  English = 1302,
  Maori = 1301
}

export enum QualityAssuranceBodies {
  CommitteeOfUniversityAcademicProgrammes = 1200,
  NewZealandQualificationsAuthority = 1201,
  CollegesOfEducationAccreditationCommittee = 1202,
  QualityAssuranceDivisionNZQA = 1203,
  InstitutesOfTechnologyAndPolytechnicsQuality = 1204
}

export enum QualificationTypes {
  BachelorsDegree = 1100,
  BachelorHonoursDegree = 1101,
  Certificate = 1102,
  Diploma = 1103,
  DoctoralDegree = 1104,
  GraduateCertificate = 1105,
  GraduateDiploma = 1106,
  MastersDegree = 1107,
  PostgraduateCertificate = 1108,
  PostgraduateDiploma = 1109
}

export enum QualificationCategories {
  EducationOrganisationDevelopedQualification = 1250,
  LocalQualification = 1251,
  LegislatedWanangaQualification = 1252,
  NationalQualification = 1253,
  NewZealandQualification = 1254
}

export enum QualificationFundingStatusEnum {
  Approved = 1501,
  NotApproved = 1502,
  ApprovalWithdrawn = 1503
}
export const QualificationFundingStatusLookup: Lookup<number>[] = [
  { text: 'Approved', value: QualificationFundingStatusEnum.Approved },
  { text: 'Not Approved', value: QualificationFundingStatusEnum.NotApproved },
  { text: 'Approval Withdrawn', value: QualificationFundingStatusEnum.ApprovalWithdrawn }
];
export enum QualificationStudentLoanBorrowingEnum {
  Approved = 1601,
  NotApproved = 1602,
  ApprovalWithdrawn = 1603
}
export const QualificationStudentLoanBorrowingLookup: Lookup<number>[] = [
  { text: 'Approved', value: QualificationStudentLoanBorrowingEnum.Approved },
  { text: 'Not Approved', value: QualificationStudentLoanBorrowingEnum.NotApproved },
  { text: 'Approval Withdrawn', value: QualificationStudentLoanBorrowingEnum.ApprovalWithdrawn }
];
export enum QualificationEligStudentLoanAllowanceEnum {
  Approved = 1701,
  NotApproved = 1702,
  ApprovalWithdrawn = 1703
}
export const QualificationEligStudentLoanAllowanceLookup: Lookup<number>[] = [
  { text: 'Approved', value: QualificationEligStudentLoanAllowanceEnum.Approved },
  { text: 'Not Approved', value: QualificationEligStudentLoanAllowanceEnum.NotApproved },
  { text: 'Approval Withdrawn', value: QualificationEligStudentLoanAllowanceEnum.ApprovalWithdrawn }
];
