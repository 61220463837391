import { Injectable } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ErrorModalComponent } from './error-modal.component';

@Injectable({
  providedIn: 'root'
})
export class ErrorModalService {
  constructor(private modalService: NgbModal) {}

  open(message: string): NgbModalRef {
    const modalRef = this.modalService.open(ErrorModalComponent, {
      backdrop: 'static',
      centered: true,
      windowClass: 'qcr-modal-md',
      ariaLabelledBy: 'modal-title'
    });
    modalRef.componentInstance.message = message;
    modalRef.componentInstance.modalRef = modalRef;
    return modalRef;
  }
}
