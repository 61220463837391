interface Array<T> {
  getElementAtIndex(index: number): T | undefined;
}

Array.prototype.getElementAtIndex = function <T>(index: number): T | undefined {
  if (index < 0 || index >= this.length) {
    return undefined;
  }
  return this[index];
};
