import { Component, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Router } from '@angular/router';
import { RecordTypeEnum } from '@components/constants';
import { ControlValueAccessorConnector } from '@components/form-components/control-value-accessor-connector';
import '@core/extensions/router.extensions';
import { GetPagedEdOrgItemDto } from '@core/services/models/organisation/GetPagedEdOrgDto';
import { EdOrgRoleEnum } from '@core/services/models/paging/paging-parameters.model';
import { OrganisationService } from '@core/services/organisation.service';
import { map, Observable } from 'rxjs';

@Component({
  selector: 'app-qual-developer',
  templateUrl: './qual-developer.component.html',
  styleUrls: ['./qual-developer.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => QualDeveloperComponent),
      multi: true
    }
  ]
})
export class QualDeveloperComponent extends ControlValueAccessorConnector implements ControlValueAccessor {
  @Input() isEditMode: boolean = true;

  constructor(
    private router: Router,
    private service: OrganisationService
  ) {
    super();
  }

  get roles(): EdOrgRoleEnum[] | null {
    switch (this.router.getRecordType()) {
      case RecordTypeEnum.MicroCredential:
      case RecordTypeEnum.Qualification:
        return [EdOrgRoleEnum.QualificationDeveloper];
      case RecordTypeEnum.CMR:
      case RecordTypeEnum.Standard:
        return [EdOrgRoleEnum.SSB];
      default:
        return null;
    }
  }

  /**
   * Query api based on search text
   * @param term
   * @param count
   * @returns
   */
  searchFunc = (term: string, count: number): Observable<any[]> => {
    return this.service.select(term, count, this.roles).pipe(
      map(response => {
        return response.items;
      })
    );
  };

  /**
   * Format the search results
   * @param x
   * @returns
   */
  formatter = (x: GetPagedEdOrgItemDto) => `${x.moeNumber} | ${x.organisationName}`;

  readonlyValue() {
    var value = this.formGroup.get(this.formControlName)?.value;
    return value ? `${value.moeNumber} | ${value.organisationName}` : '';
  }
}
