import { Injectable } from '@angular/core';
import { CfSystemType } from '@views/classification/constants';
import { DepthLevel } from './models/classification/depth-level';
import { Lookup } from './models/shared/lookup';

export const DepthLevelOptions: DepthLevel[] = [
  { systemType: CfSystemType.NZSCED, description: 'Broad', value: 4401 },
  { systemType: CfSystemType.NZSCED, description: 'Narrow', value: 4402 },
  { systemType: CfSystemType.NZSCED, description: 'Detail', value: 4403 },
  { systemType: CfSystemType.SCUNQ, description: 'Field', value: 4401 },
  { systemType: CfSystemType.SCUNQ, description: 'Subfield', value: 4402 },
  { systemType: CfSystemType.SCUNQ, description: 'Domain', value: 4403 }
];

@Injectable({
  providedIn: 'root'
})
export class DepthLevelService {
  public static DepthLevelOptionsValues = {
    NZSCED: {
      Broad: DepthLevelOptions.find(x => x.description === 'Broad')?.value,
      Narrow: DepthLevelOptions.find(x => x.description === 'Narrow')?.value,
      Detail: DepthLevelOptions.find(x => x.description === 'Detail')?.value
    },
    SCUNQ: {
      Field: DepthLevelOptions.find(x => x.description === 'Field')?.value,
      Subfield: DepthLevelOptions.find(x => x.description === 'Subfield')?.value,
      Domain: DepthLevelOptions.find(x => x.description === 'Domain')?.value
    }
  };

  public static depthLevels = DepthLevelOptions;

  public static getDepthLevelDescription(systemType: CfSystemType, depthLevel: number): string | undefined {
    return DepthLevelService.depthLevels.find(x => x.systemType === systemType && x.value === depthLevel)?.description;
  }

  getDepthLevels(systemType: CfSystemType): Lookup<number>[] {
    let selectedDepthLevels = DepthLevelService.depthLevels
      .filter(depthLevel => depthLevel.systemType == systemType)
      .map(depthLevel => this.mapDepthLevels(depthLevel));
    return selectedDepthLevels;
  }

  private mapDepthLevels(depthLevel: DepthLevel): Lookup<number> {
    return {
      text: `${depthLevel.description}`,
      value: depthLevel.value
    };
  }
}
