import { ChangeDetectorRef, Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { minimumInputLength, typeaheadDebounceTime } from '@components/constants';
import { ControlValueAccessorConnector } from '@components/form-components/control-value-accessor-connector';
import { OrganisationService } from '@core/services/organisation.service';
import { debounceTime, map, Observable, of, switchMap } from 'rxjs';

@Component({
  selector: 'app-caseid-typeahead',
  templateUrl: './caseid-typeahead.component.html',
  styleUrls: ['./caseid-typeahead.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CaseIdTypeaheadComponent),
      multi: true
    }
  ]
})
export class CaseIdTypeaheadComponent extends ControlValueAccessorConnector implements OnInit, ControlValueAccessor {
  @Input() hideWarning: boolean = false;
  @Input() attributeId = this.formControlName;
  @Input() getAukahaIds: boolean = false;
  @Input() isEditMode: boolean = true;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private service: OrganisationService
  ) {
    super();
  }

  ngOnInit(): void {
    this.changeDetectorRef.detectChanges();
  }

  /**
   * Query api based on search text
   * @param term
   * @param count
   * @returns
   */
  searchFunc = (term: string, count: number): Observable<any[]> => {
    return of(term).pipe(
      debounceTime(typeaheadDebounceTime),
      switchMap(searchTerm => {
        if (searchTerm.length >= minimumInputLength) {
          return this.service.caseids(searchTerm, count, this.getAukahaIds).pipe(
            map(response => {
              return response.items.map(item => item.caseId);
            })
          );
        } else {
          return of([]);
        }
      })
    );
  };

  /**
   * Format the search results
   * @param x
   * @returns
   */
  formatter = (x: string) => `${x}`;
}
