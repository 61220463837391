import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'splitPascalCase'
})
export class SplitPascalCasePipe implements PipeTransform {
  transform(value: any, ...args: unknown[]): string {
    if (!value) {
      return value;
    }
    //splits pascal case words to words seperated by spaces.
    const words = value.split('').map((letter: string, index: number) => {
      if (index != 0 && letter === letter.toUpperCase()) {
        return ` ${letter}`;
      }
      return letter;
    });
    return words.join('');
  }
}
