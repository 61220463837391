import { Directive, ElementRef, Injectable, OnDestroy, OnInit } from '@angular/core';
import { debounce, interval, Subject } from 'rxjs';

/**
 * This directive is used to notify the service of the height of the element it is attached to.
 * This directive is specific to the browser layout component
 */
@Directive({
  selector: '[appSizeNotifier]'
})
export class SizeNotifierDirective implements OnInit, OnDestroy {
  private resizeObserver: ResizeObserver;

  constructor(
    private el: ElementRef,
    private sizeNotifierService: SizeNotifierService
  ) {}

  ngOnDestroy(): void {
    this.resizeObserver.disconnect();
  }

  ngOnInit(): void {
    //init the resizer
    this.resizeObserver = new ResizeObserver(entries => {
      //get the height of the element
      let itemHeight = entries[0]?.borderBoxSize[0].blockSize;
      //notify the service
      this.sizeNotifierService.elementHeight = itemHeight;
    });

    //attach the observer to the element
    this.resizeObserver.observe(this.el.nativeElement);
  }
}

@Injectable({
  providedIn: 'root'
})
export class SizeNotifierService {
  private _elementHeight: number;

  public set elementHeight(value: number) {
    this._elementHeight = value;
    this._elementHeightChanged.next(this._elementHeight);
  }

  private _elementHeightChanged: Subject<number> = new Subject<number>();

  /**
   * Observable that emits the height of the element that is being observed
   *
   * @readonly
   * @memberof SizeNotifierService
   */
  public get elementHeightChanged() {
    //debounce the event to prevent multiple events from being fired in rapid succession
    return this._elementHeightChanged.pipe(debounce(i => interval(100)));
  }
}
