import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formControlShouldShowValidation',
  pure: false
})
export class FormControlShouldShowValidationPipe implements PipeTransform {
  transform(control: any): boolean {
    //This is *any* because in some cases it's an NGControl and in some cases it's an AbstractControl
    //And it seems to go crazy if I try and do conversions etc
    if (!control) {
      return false;
    }
    return control.invalid && (control.dirty || control.touched);
  }
}

/**
 * Allows you to say the following on an div with a warning message
 * *ngIf="manageForm.controls['lastDateforEntry'] | formControlShouldShowValidationForError: 'date-min'"
 */
@Pipe({
  name: 'formControlShouldShowValidationForError',
  pure: false
})
export class FormControlShouldShowValidationForError implements PipeTransform {
  transform(control: any, error: string): boolean {
    //This is *any* because in some cases it's an NGControl and in some cases it's an AbstractControl
    //And it seems to go crazy if I try and do conversions etc

    if (!control) {
      return false;
    }

    if (!control.errors || !control.errors[error]) {
      return false;
    }

    let validatorErrors = Object.keys(control.errors || {});

    if (error === 'required' && validatorErrors.length > 1) {
      return false;
    }

    return control.invalid && (control.dirty || control.touched);
  }
}

@Pipe({
  name: 'formControlShouldShowWarning',
  pure: false
})
export class FormControlShouldShowWarning implements PipeTransform {
  transform(control: any, error?: string): boolean {
    //This is *any* because in some cases it's an NGControl and in some cases it's an AbstractControl
    //And it seems to go crazy if I try and do conversions etc
    if (!control) {
      return false;
    }

    if (!control.warnings || (error && !control.warnings[error])) {
      return false;
    }

    return !control.errors && (control.dirty || control.touched);
  }
}
