import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'replaceSpaceWithDash'
})
export class ReplaceSpaceWithDash implements PipeTransform {
  transform(value: any, ...args: unknown[]): string {
    if (!value) {
      return value;
    }
    //splits pascal case words to words seperated by spaces.
    const words = value.split(' ');
    return words.join('-');
  }
}
