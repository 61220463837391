import { ChangeDetectorRef, Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ControlValueAccessorConnector } from '@components/form-components/control-value-accessor-connector';
import { MessageModalService } from '@components/message-modal/message-modal.service';
import { ClassificationService } from '@core/services/classification.service';
import { GetClassification } from '@core/services/models/classification/get-classification.model';
import { ClassificationStatus } from '@views/classification/constants';
import { map, Observable } from 'rxjs';
import { ITypeaheadBase } from '../typeahead-base';

@Component({
  selector: 'app-classification-typeahead',
  templateUrl: './classification-typeahead.component.html',
  styleUrls: ['./classification-typeahead.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ClassificationTypeaheadComponent),
      multi: true
    }
  ]
})
export class ClassificationTypeaheadComponent
  extends ControlValueAccessorConnector
  implements OnInit, ControlValueAccessor, ITypeaheadBase
{
  @Input() attributeId: string = this.formControlName;
  @Input() cfSystemType: any;
  @Input() parent: any;
  @Input() depthLevel: any;
  @Input() primaryName: string;
  @Input() internalId: string;
  @Input() removeConfirmationMessage: string = '';
  @Input() hasConfirmationModal: boolean = false;
  @Output() removeEvent: EventEmitter<void> = new EventEmitter<void>();
  @Input() excludedIds: string[] = [];
  @Input() statuses: ClassificationStatus[] = [ClassificationStatus.Registered, ClassificationStatus.Expiring];
  @Input() isEditMode: boolean = true;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private service: ClassificationService,
    private modalService: MessageModalService
  ) {
    super();
  }

  ngOnInit(): void {
    this.changeDetectorRef.detectChanges();
  }

  /**
   * Query api based on search text
   * @param term
   * @param count
   * @returns
   */
  searchFunc = (term: string, count: number): Observable<any[]> => {
    return this.service.select(term, count, this.cfSystemType, this.depthLevel, this.parent, this.statuses).pipe(
      map(response => {
        return response.items.filter(item => this.excludedIds?.indexOf(item.id!) == -1);
      })
    );
  };

  /**
   * Format the search results
   * @param x
   * @returns
   */
  formatter = (x: GetClassification) => `${x.classificationDisplay}`;

  showConfirmationModal(): void {
    this.modalService.open(this.removeConfirmationMessage).result.then(confirmed => {
      if (confirmed) this.removeEvent.emit();
    });
  }

  readonlyValue() {
    var value = this.formGroup.get(this.formControlName)?.value;
    return value ? value.classificationDisplay : '';
  }
}
