import { GenericForm } from '@core/forms/form-group-extensions';
import { valueToEnumKey } from '@core/helpers/utils';
import { DepthLevelOptions } from '@core/services/depth-level.service';
import { PrimaryLanguage } from '@views/qualification/constants';
import {
  StandardContextEnum,
  StandardProcessEnum,
  StandardStatusEnum,
  StandardVersionStatusEnum
} from '@views/standard/constants';
import { IBaseModel } from '../base/base.model';
import { IQualificationDeveloper } from '../shared/qualification-developer';
import { CosmoBaseModel } from '../base/cosmo-base.model';

export type StandardForm = GenericForm<IStandard>;
export interface IStandard extends IBaseModel {
  /* Allows the properties to be indexed using property names */
  [key: string]: any;
  id: string | null;
  stdType: number | null;
  stdTypeDisplayName: string | null;
  stdNo: string | null;
  externalStdNo: number | null;
  stdNoEntity: number | null;
  verNo: number | null;
  status: StandardStatusEnum | null;
  verStatus: StandardVersionStatusEnum | null;
  ssb: IQualificationDeveloper | null;
  expiring: string | null;
  expiry: string | null;
  reviewDate: string | null;
  process: number | null;
  title: string | undefined | null;
  level: number | null;
  caseId: string | null;
  approvedTeHono: boolean | null;
  scunq: Classification | null;
  credits: number | null;
  graded: boolean | null;
  gradingScheme: number | null;
  lastAssessmentDate: string | null;
  primaryLanguage: number | null;
  maoriContent: boolean | null;
  purpose: string | null;
  prerequisites: string | null;
  transReady: boolean | null;
  criteriaForAchievement: string | null;
  criteriaForMerit: string | null;
  criteriaForExcellence: string | null;
  guidanceInformation: string | null;
  replacementInformation: string | null;
  subjRef: string | null;
  assessment: number | null;
  assessDatesFirst: string | null;
  gradingType: number | null;
  maoriPaper: boolean | null;
  maoriAnswer: boolean | null;
  specialAssistance: boolean | null;
  ausRef: string | null;
  ausTitle: string | null;
  ausSsd: string | null;
  ausQualFramework: string | null;
  unitCompetencyCode: string | null;
  unitCompetencyName: string | null;
  stdDeveloper: string | null;
  explanatoryNotes: string | null;
  comment: string | null;
  qualityAssurance: string | null;
  created: string | null;
  modified: string | null;
  cmr: Cmr | null;
  outcomes: StandardOutCome[] | null;
  context: StandardContextEnum | null;
  startDate?: string | null | undefined;
  get standardStatusString(): string;
  get ssbName(): string | undefined;
  get verStatusString(): string;
  associatedCMRs: string[];
  /**
   * (Property of AssociatedStandard, should be moved when differentiating between
   * models and their associated counterparts)
   * Indicates that this standard is associated (to CMR) via classification
   */
  viaClassification: boolean;
  isNew: boolean | null;
  etag?: string | null | undefined;
}

export class Standard extends CosmoBaseModel implements IStandard {
  constructor(obj?: Standard) {
    super();
    Object.assign(this, obj);
  }

  id: string | null = null;
  stdType: number | null = null;
  stdTypeDisplayName: string | null;
  stdNo: string | null = null;
  externalStdNo: number | null = null;
  stdNoEntity: number | null = null;
  verNo: number | null = null;
  status: StandardStatusEnum | null = null;
  verStatus: StandardVersionStatusEnum | null = null;
  ssb: IQualificationDeveloper | null = null;
  expiring: string | null = null;
  expiry: string | null = null;
  reviewDate: string | null = null;
  process: number | null = StandardProcessEnum.New;
  title: string | undefined | null = null;
  level: number | null = null;
  caseId: string | null = null;
  approvedTeHono: boolean | null = null;
  scunq: Classification | null = null;
  credits: number | null = null;
  graded: boolean | null = null;
  gradingScheme: number | null = null;
  lastAssessmentDate: string | null = null;
  primaryLanguage: PrimaryLanguage | null = PrimaryLanguage.English;
  maoriContent: boolean | null = null;
  purpose: string | null = null;
  prerequisites: string | null = null;
  transReady: boolean | null = null;
  criteriaForAchievement: string | null = null;
  criteriaForMerit: string | null = null;
  criteriaForExcellence: string | null = null;
  guidanceInformation: string | null = null;
  replacementInformation: string | null = null;
  subjRef: string | null = null;
  assessment: number | null = null;
  assessDatesFirst: string | null = null;
  gradingType: number | null = null;
  maoriPaper: boolean | null = null;
  maoriAnswer: boolean | null = null;
  specialAssistance: boolean | null = null;
  ausRef: string | null = null;
  ausTitle: string | null = null;
  ausSsd: string | null = null;
  ausQualFramework: string | null = null;
  unitCompetencyCode: string | null = null;
  unitCompetencyName: string | null = null;
  stdDeveloper: string | null = null;
  explanatoryNotes: string | null = null;
  comment: string | null = null;
  qualityAssurance: string | null = null;
  created: string | null = null;
  modified: string | null = null;
  cmr: Cmr | null = null;
  outcomes: StandardOutCome[] | null = [];
  context: StandardContextEnum | null = null;
  startDate?: string | null | undefined;
  /**
   *Getters for JS classes only work when you build a new object using new Standard()
   *It does not work when you get it from the API
   *To solve that one can use the utils.genericItemReMapper or the utils.genericArrayReMapper
   *You can use that in the api call to ensure all objects have all the getters in the class and
   *that the constructor is called
   */

  get standardStatusString(): string {
    return getStandardStatusString(this);
  }

  get ssbName(): string | undefined {
    return this.ssb?.organisationName;
  }

  get verStatusString(): string {
    return getVerStatusString(this) ?? 'ver status error';
  }
  associatedCMRs: string[];
  viaClassification: boolean;
  isNew: boolean | null = false;
}

export function getVerStatusString(standard: IStandard) {
  return valueToEnumKey(StandardVersionStatusEnum, standard.verStatus);
}

export function getStandardStatusString(standard: IStandard): string {
  const standardStatusEnumKey = valueToEnumKey(StandardStatusEnum, standard.status);

  return standardStatusEnumKey!;
}

export class Classification {
  constructor(obj?: Classification) {
    Object.assign(this, obj);
  }

  id: string;
  classificationIdIdentity: number;
  created: string;
  internalId: string;
  externalId: string;
  cfSystemType: number;
  depthLevel: number;
  parent: string;
  caseId: string;
  status: number;
  statusDate: string;
  primaryName: string;
  primaryLanguage: number;
  secondaryName: string;
  secondaryLanguage: number;
  bsas: string;
  sel: string;
  cfdescription: string;
  replacementEffectiveFrom: string;
  replacedBy: string;
  toReplace: string;
  notes: string;
  depthHierarchy: DepthHierarchy;
  classificationDisplay: string;
  startDate?: string | null | undefined;
  get classificationTypeString(): string {
    return getTypeDisplay(this) ?? '';
  }
  isNew?: boolean | null = false;
}

export function getTypeDisplay(item: Classification) {
  return DepthLevelOptions.find(x => x.value === item.depthLevel && x.systemType === item.cfSystemType)?.description;
}

export interface DepthHierarchy {
  depth1Name: string;
  depth2Name: string;
}

export interface Cmr {
  id: string;
  cmrNo: string;
  cmrNoIdentity: number;
  status: number;
  verStatus: number;
  verNo: number;
  caseId: string;
  title: string;
  ssb: string;
  process: string;
  expiring: string;
  expiry: string;
  reviewDate: string;
  created: string;
  modified: string;
}

export interface StandardOutCome {
  title: string;
  range: string;
  performanceCriteria: StandardPerformanceCriteriaItem[];
}

export interface StandardPerformanceCriteriaItem {
  title: string;
  range: string;
}
