import { AssociatedRecordTypeEnum } from '@views/pre-requisites/constants';
import { CosmoBaseModel } from '../base/cosmo-base.model';

export class AssociatedRecordPointer extends CosmoBaseModel {
  constructor(obj?: AssociatedRecordPointer) {
    super();
    Object.assign(this, obj);
  }

  isDeleted: boolean;
  recordNo: string;
  title: string;
  recordType: AssociatedRecordTypeEnum;
  recordId: string;
  get id(): string {
    return this.recordId;
  }
  /**
   * Indicates that this association is achieved through another association
   * such as associating a standard to a CMR via a classification
   */
  indirectAssociation: boolean;
  isNew: boolean = false;
}
