import { Component, Input } from '@angular/core';
import { PrimaryLanguage } from '@views/qualification/constants';

@Component({
  selector: 'app-translation-header',
  templateUrl: './translation-header.component.html',
  styleUrls: ['./translation-header.component.scss']
})
export class TranslationHeaderComponent {
  @Input() primaryLanguage: PrimaryLanguage;
  @Input() recordType: string;
  public primaryLanguageEnum = PrimaryLanguage;
  public messageVisible: boolean = true;
}
