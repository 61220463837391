import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Lookup } from '@core/services/models/shared/lookup';
import { ControlValueAccessorConnector } from '../control-value-accessor-connector';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectComponent),
      multi: true
    }
  ]
})
export class SelectComponent extends ControlValueAccessorConnector implements ControlValueAccessor {
  @Input() options: any[];
  @Input() showBlankOption: boolean = false;
  @Input() blankOptionText: string | null = null;
  @Input() textOptions: string[] = [];
  @Input() numberOptions: number[] = [];
  @Input() set lookupOptions(options: Lookup<any>[]) {
    if (!this.hideDisabledValues) {
      this._lookupOptions = options;
    } else if (options && this.formControlName) {
      const control = this.formGroup.get(this.formControlName)!;
      this._lookupOptions = options.filter(o => !o.disabled || control.value === o.value);
    } else {
      this._lookupOptions = options;
    }
  }
  /**
   * Allow multi selection via checkboxes, uses a material UI select component
   */
  @Input() multiSelection: boolean = false;
  /***
   * When set to true, all disabled values will be hidden except for the one that match the value
   * of the control, allowing the user to keep the disabled value as long as they don't touch the field.
   */
  @Input() hideDisabledValues: boolean = false;
  @Input() attributeId: string;
  @Input() isEditMode: boolean = true;

  private _lookupOptions: Lookup<any>[] = [];

  get lookupOptions() {
    return this._lookupOptions;
  }

  constructor() {
    super();
  }

  readOnlyValue() {
    if (this.lookupOptions && this.lookupOptions.length > 0) {
      return this.lookupOptions?.find(x => x.value === this.control.value)?.text;
    }
    if (this.textOptions && this.textOptions.length > 0) {
      return this.textOptions[this.control.value];
    }

    if (this.numberOptions && this.numberOptions.length > 0) {
      return this.numberOptions[this.control.value];
    }
    return '';
  }
}
