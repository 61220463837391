import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalBroadcastService,
  MsalInterceptor,
  MsalService
} from '@azure/msal-angular';
import { ComponentsModule } from '@components/components.module';
import { SigninModule } from '@components/signin/signin.module';
import { SpinnerModule } from '@components/spinner/spinner.module';
import { SvgModule } from '@components/svg/svg.module';
import {
  msalClientFactory,
  msalGuardConfigFactory,
  msalInterceptorConfigFactory
} from '@core/configuration/msal-config.service';
import '@core/extensions/array.extensions';
import '@core/extensions/string.extensions';
import { ApibaseInterceptor } from '@core/interceptors/apibase.interceptor';
import { HttpErrorInterceptor } from '@core/interceptors/http-error.interceptor';
import { LoadingInterceptor } from '@core/interceptors/loading.interceptor';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { DragulaModule, DragulaService } from 'ng2-dragula';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MATERIAL_SANITY_CHECKS } from '@angular/material/core';

export const imports = [
  BrowserModule,
  AppRoutingModule,
  NgbModule,
  ReactiveFormsModule,
  HttpClientModule,
  CommonModule,
  ComponentsModule,
  SigninModule,
  SpinnerModule,
  SvgModule,
  NgSelectModule,
  BrowserAnimationsModule,
  DragulaModule.forRoot()
];

@NgModule({
  declarations: [AppComponent],
  imports,
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApibaseInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: msalClientFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: msalGuardConfigFactory
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: msalInterceptorConfigFactory
    },
    MsalBroadcastService,
    MsalService,
    DragulaService,
    // suppress material sanity checks
    {
      provide: MATERIAL_SANITY_CHECKS,
      useValue: false
    }
  ],
  bootstrap: [AppComponent],
  exports: [BrowserAnimationsModule]
})
export class AppModule {}
