import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { apiRoutes } from '../constants/api-routes.constants';
import { map, Observable } from 'rxjs';
import { GetClassification } from './models/classification/get-classification.model';
import { IClassification } from './models/classification/classification';
import { ResponseBase } from './models/base/responseBase.model';
import { ClassificationExistsParameters } from './models/classification/classification-exists-parameters.model';
import { ClassificationTreeDto, FolderTreeRootNode } from '@components/folder-tree/folder-tree/FolderTreeRootNode';
import { Lookup } from './models/shared/lookup';
import { CfSystemType, Cftype, ClassificationStatus, DepthLevelEnum } from '@views/classification/constants';
import { StatusUpdateModalModel } from '@views/classification/manage-classification-status/manage-classification-status-modal.model';
import { GetClassificationReplacedByParameters } from './models/classification/get-classification-replaced-by.model';
import {
  GetClassificationPagingParameters,
  IGetPagedClassificationDto
} from './models/classification/IGetPagedClassificationDto';
import { DepthLevelOptions } from './depth-level.service';
import { folderTreeArrayMapper, genericArrayReMapper } from '@core/helpers/utils';
import { ActivatedRoute } from '@angular/router';
import { StandardGroup } from './models/standard-groups/standard-groups';
import { IStandard, Standard } from './models/standard/standard';
import { StandardStatusEnum, StandardVersionStatusEnum } from '@views/standard/constants';
import { GetStandardPagingParameters, IGetPagedStandardDto } from './models/standard/IGetPagedStandardDto';
import { IMicroCredential } from './models/micro-credential/micro-credential';
import KeyValue from './models/keyValue.model';

@Injectable({
  providedIn: 'root'
})
export class ClassificationService {
  private classificationStatusLookup: Lookup<number>[] = [
    {
      text: ClassificationStatus.Draft.toString(),
      value: ClassificationStatus.Draft
    },
    {
      text: ClassificationStatus.Expired.toString(),
      value: ClassificationStatus.Expired
    },
    {
      text: ClassificationStatus.Expiring.toString(),
      value: ClassificationStatus.Expiring
    },
    {
      text: ClassificationStatus.Registered.toString(),
      value: ClassificationStatus.Registered
    }
  ];

  private cfTypes: Cftype[] = [
    {
      primaryName: 'SCUNQ',
      qualityAssuranceBody: 'NZQA Framework Registration',
      depthLevels: []
    },
    {
      primaryName: 'NZSCED',
      qualityAssuranceBody: 'NZQA Framework Registration',
      depthLevels: []
    }
  ];

  get queryCfSystemType(): CfSystemType | undefined {
    let queryParam = this.route.snapshot.queryParams['cfSystemType'] as CfSystemType;
    if (queryParam) {
      return +queryParam;
    }
    return undefined;
  }

  get queryDepthLevel(): DepthLevelEnum | undefined {
    let queryParam = this.route.snapshot.queryParams['depthLevel'] as DepthLevelEnum;
    if (queryParam) {
      return +queryParam;
    }
    return undefined;
  }

  get queryParentId(): string | undefined {
    return this.route.snapshot.queryParams['parentId'] as string;
  }

  constructor(
    private http: HttpClient,
    private route: ActivatedRoute
  ) {}

  getAssociatedStandardGroups(internalId: string): Observable<StandardGroup[]> {
    return this.http
      .get<StandardGroup[]>(apiRoutes.standardGroup.standardGroupsForClassification(internalId))
      .pipe(map(genericArrayReMapper<StandardGroup>(StandardGroup)));
  }

  get(internalId: string, cfSystemType: CfSystemType): Observable<GetClassification> {
    return this.http.get<GetClassification>(apiRoutes.classification.get(internalId, cfSystemType));
  }

  getStatus(internalId: string, cfSystemType: CfSystemType, parent: string): Observable<ClassificationStatus | null> {
    const params = new ClassificationExistsParameters({
      systemType: cfSystemType,
      parent: parent
    });
    return this.http.get<ClassificationStatus | null>(
      apiRoutes.classification.getStatus(internalId, params.toQueryString())
    );
  }

  create(model: IClassification): Observable<ResponseBase> {
    return this.http.post<ResponseBase>(apiRoutes.classification.create, model);
  }

  update(model: IClassification): Observable<ResponseBase> {
    return this.http.put<ResponseBase>(apiRoutes.classification.update(model.internalId), model);
  }

  updateStatus(model: StatusUpdateModalModel): Observable<ResponseBase> {
    return this.http.patch<ResponseBase>(apiRoutes.classification.updateStatus(model.internalId!), model);
  }

  exists(params: ClassificationExistsParameters): Observable<GetClassification[] | null> {
    return this.http.get<GetClassification[]>(apiRoutes.classification.exists(params.toQueryString()));
  }

  getReplacedBy(params: GetClassificationReplacedByParameters): Observable<Array<GetClassification> | null> {
    return this.http.get<Array<GetClassification>>(
      apiRoutes.classification.getReplacedBy(params.internalId, params.toQueryString())
    );
  }

  getTree(nodeId: string, full: boolean = false, type: string = '') {
    return this.http
      .get<FolderTreeRootNode<ClassificationTreeDto>[]>(apiRoutes.classification.tree(nodeId, full, type))
      .pipe(map(folderTreeArrayMapper<ClassificationTreeDto>(ClassificationTreeDto)));
  }

  getSubTree(nodeId: string, includeStandards: boolean = false) {
    return this.http
      .get<FolderTreeRootNode<ClassificationTreeDto>[]>(apiRoutes.classification.subTree(nodeId, includeStandards))
      .pipe(map(folderTreeArrayMapper<ClassificationTreeDto>(ClassificationTreeDto)));
  }

  getSelectLookupValues(): Lookup<number>[] {
    return this.classificationStatusLookup;
  }

  getRouterQueryParams(classification: ClassificationTreeDto | GetClassification) {
    return { queryParams: { cfSystemType: classification.cfSystemType, parentId: classification.parent } };
  }

  select(
    searchText: string,
    count: number,
    cfSystemType: number,
    depthLevel: number,
    parent: string,
    statuses?: ClassificationStatus[]
  ): Observable<IGetPagedClassificationDto> {
    let params = new GetClassificationPagingParameters({
      searchText: searchText,
      limit: count,
      offset: 0,
      cfSystemType: cfSystemType,
      depthLevel: depthLevel,
      parent: parent,
      statuses: statuses
    });
    return this.http.get<IGetPagedClassificationDto>(apiRoutes.classification.paged(params.toQueryString()));
  }

  getCfType(cfType: string): Cftype | undefined {
    let selectedCfType = this.cfTypes.find(type => type?.primaryName?.toLowerCase() == cfType.toLowerCase());
    const cfSystemType = CfSystemType[cfType.toUpperCase() as keyof typeof CfSystemType];

    if (selectedCfType) selectedCfType.depthLevels = DepthLevelOptions.filter(d => d.systemType === cfSystemType);
    return selectedCfType;
  }

  getAssociatedMicroCredentials(classificationNumber: string) {
    return this.http.get<IMicroCredential[]>(
      apiRoutes.classification.getAssociatedMicroCredentials(classificationNumber)
    );
  }

  getAssociatedStandards(
    internalId: string,
    statuses?: StandardStatusEnum[],
    verStatus?: StandardVersionStatusEnum[],
    searchOnlyByNumber: boolean = false
  ): Observable<IGetPagedStandardDto> {
    let params = new GetStandardPagingParameters({
      status: statuses ?? [],
      verStatus: verStatus ?? [],
      searchOnlyByNumber
    });
    return this.http
      .get<IGetPagedStandardDto>(apiRoutes.classification.getAssociatedStandards(internalId, params.toQueryString()))
      .pipe(
        map(result => {
          return {
            totalCount: result.totalCount,
            items: genericArrayReMapper<IStandard>(Standard)(result.items)
          } as IGetPagedStandardDto;
        })
      );
  }

  getStandardAssociatedCMRs(internalId: string, cmrNoToIgnore?: string): Observable<KeyValue[]> {
    return this.http.get<KeyValue[]>(apiRoutes.classification.standardCmrAssociations(internalId, cmrNoToIgnore));
  }
}
