import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-md-modal',
  templateUrl: './md-modal.component.html',
  styleUrls: ['./md-modal.component.scss']
})
export class MdModalComponent {
  @Input() modalRef: NgbModalRef;
  @Input() disableSave?: boolean;
  @Input() saveText: string = 'Save';
  @Input() showResetButton: boolean = false;
  @Input() showSecondaryButton: boolean = false;
  @Input() isFormModal: boolean = false;

  /* Custom button to show between the save and cancel buttons */
  @Input() secondaryButtonText: string = '';
  @Output() saveClickedEvent: EventEmitter<void> = new EventEmitter();
  @Output() cancelClickedEvent: EventEmitter<void> = new EventEmitter();
  @Output() resetClickedEvent: EventEmitter<void> = new EventEmitter();
  @Output() secondaryButtonClicked: EventEmitter<void> = new EventEmitter();

  constructor(private modalService: NgbModal) {}

  onSaveClicked(): void {
    this.saveClickedEvent.emit();
  }

  onResetClicked(): void {
    this.resetClickedEvent.emit();
  }

  onCancelClicked(): void {
    this.cancelClickedEvent.emit();
    this.onClose();
  }

  onClose(): void {
    if (this.modalRef) {
      this.modalRef.close();
    } else {
      this.modalService.dismissAll();
    }
  }

  public onSecondaryButtonClicked = () => {
    this.secondaryButtonClicked.emit();
  };
}
