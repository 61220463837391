import { Component, Input, TemplateRef } from '@angular/core';

@Component({
  selector: 'app-toggle-checkbox-element',
  template: ''
})
/**
 * This component does not have any UI, all it does is providing component children to the component
 * ToggleCheckboxGroupComponent for an easy way to provide the needed configuration via a template
 */
export class ToggleCheckboxElementComponent {
  // The test to be displayed beside the checkbox button
  @Input() title: string;
  // The form field that will be set when the checkbox button is selected
  @Input() formFieldName: string;
  // The template that will be shown when the checkbox button is selected
  @Input() templateReference: TemplateRef<any>;
}
