import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';
/**
 * Focus an element when the component is rendered
 */
@Directive({
  selector: '[appFocusOnLoad]'
})
export class FocusOnLoadDirective implements AfterViewInit {
  @Input('appFocusOnLoad') apply: boolean;

  constructor(private element: ElementRef) {}

  ngAfterViewInit() {
    if (this.apply) {
      this.element.nativeElement.focus();
    }
  }
}
