import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RecordTypeEnum } from '@components/constants';
import { apiRoutes } from '../constants/api-routes.constants';
import { ControllerProvider } from './ControllerProvider';
import { GetPagedRecordViewHistory } from './models/record-view-history/get-paged-record-view-history';
import { GetPagedRecordViewHistoryParameters } from './models/record-view-history/get-paged-record-view-history-parameters';

@Injectable({
  providedIn: 'root'
})
export class RecordViewHistoryService {
  constructor(private http: HttpClient) {}

  paged(parameters: GetPagedRecordViewHistoryParameters) {
    return this.http.get<GetPagedRecordViewHistory>(apiRoutes.recordViewHistory.paged(parameters.toQueryString()));
  }

  upsertViewHistory(recordNo: string, recordId: string | undefined, recordType: RecordTypeEnum) {
    const controller = ControllerProvider.get(recordType);

    return this.http.post<string>(apiRoutes.recordViewHistory.post(recordNo, controller), { recordId });
  }
}
