import { Directive, ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core';
import { GetUserPermissionsParameters } from '@core/services/models/user/get-user-permissions-parameters';
import { UserService } from '@core/services/user.service';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[disableButton]'
})
export class DisableButtonDirective implements OnChanges {
  @Input() set allowedPermissions(model: DisableButtonModel) {
    this.checkForAllowedPermissions(model);
  }

  @Input() set isDisabled(bool: boolean) {
    if (bool) {
      this.el.nativeElement.disabled = true;
    }
  }

  constructor(
    public userService: UserService,
    private el: ElementRef
  ) {}

  checkForAllowedPermissions(model: DisableButtonModel) {
    let params = new GetUserPermissionsParameters({
      recordType: model.recordType,
      recordNumber: model.recordNumber,
      recordId: model.recordId
    });

    this.userService.getUserPermissions(params).subscribe(x => {
      if (!x.includes(model.permission)) {
        if (this.el.nativeElement.classList.contains('btn')) {
          this.el.nativeElement.disabled = true;
        } else {
          this.el.nativeElement.className = 'option edit-mode-disabled';
        }
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['default']) {
      this.el.nativeElement.disabled = changes['default'].currentValue;
    }
  }
}

export class DisableButtonModel {
  public constructor(init?: Partial<DisableButtonModel>) {
    Object.assign(this, init);
  }

  permission: string;
  recordId: string;
  recordNumber: string;
  recordType: string;
}
