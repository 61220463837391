import { PagingParameters } from '../paging/paging-parameters.model';

export class GetUserPermissionsParameters extends PagingParameters {
  public constructor(init?: Partial<GetUserPermissionsParameters>) {
    super();
    Object.assign(this, init);
  }
  recordType: string;
  recordNumber: string;
  recordId: string;
}
