import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-single-input',
  templateUrl: './single-input.component.html',
  styleUrls: ['./single-input.component.scss']
})
/**
 * A dialog with a configurable single text input
 */
export class SingleInputComponent implements OnInit {
  @Input() title: string;
  @Input() subTitle: string;
  @Input() inputLabel: string;
  @Input() inputValue: string = '';
  @Input() onSave: ((value: string) => void) | null = null;
  @Input() onChange: ((value: string) => void) | null = null;
  @Input() maximumInputLength: number = 100;
  @Input() modalRef: NgbModalRef;
  @Input() multiLine: boolean;
  public form: FormGroup;
  public control: FormControl<string>;

  ngOnInit(): void {
    this.control = new FormControl<string>(this.inputValue, [Validators.required]) as FormControl<string>;
    this.form = new FormGroup({ input: this.control });
    if (this.onChange) {
      this.control.valueChanges.pipe(untilDestroyed(this)).subscribe(value => this.onChange!(value));
    }
  }

  public onSaveClicked = () => {
    if (this.onSave) {
      this.onSave(this.form.controls['input'].value);
    }
    this.modalRef.dismiss();
  };

  public onCancel = () => {
    this.modalRef?.dismiss();
  };
}
