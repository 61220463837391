import { AfterViewInit, Component, forwardRef, HostBinding, Input, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ControlValueAccessorConnector } from '../control-value-accessor-connector';

@UntilDestroy()
@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxComponent),
      multi: true
    }
  ]
})
export class CheckboxComponent
  extends ControlValueAccessorConnector
  implements ControlValueAccessor, OnInit, AfterViewInit
{
  @HostBinding('style.display')
  hostDisplay = 'unset';

  /**
   * If true, the checkbox will be FORCED to be displayed inline with the label
   */
  @Input() forceInline: boolean = false;

  /**
   * This index is for giving unique id to the checkbox when being used a a group
   */
  @Input() index: number = 0;

  /**
   * This is for the consumer to give a unique name to the form group for the same form control names
   */
  @Input() attributeId = this.formControlName;

  @Input() labelMaori: string | null;

  @Input() disabled: boolean = false;
  @Input() useLegend: boolean = false;

  @Input() wrapLabel: boolean = false;

  get id(): string {
    return `${this.attributeId}-checkbox-${this.index}`;
  }

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.control?.valueChanges.pipe(untilDestroyed(this)).subscribe(value => {
      this.control?.markAsDirty();
    });
    if (this.forceInline) {
      this.hostDisplay = 'flex';
    }
  }

  ngAfterViewInit(): void {
    if (this.disabled) {
      const timeoutId = setTimeout(() => {
        this.control?.disable();
      });
    }
  }

  toggle(event: MouseEvent): void {
    if (!this.control?.disabled) {
      this.control?.setValue(!this.control.value);
    }
  }
}
