import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { apiRoutes } from '../constants/api-routes.constants';
import { GetUserPermissionsParameters } from './models/user/get-user-permissions-parameters';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  constructor(private http: HttpClient) {}

  getUserPermissions(parameters: GetUserPermissionsParameters) {
    return this.http.get<string[]>(apiRoutes.user.getUserPermissions(parameters.toQueryString()));
  }

  private _isUserAdmin: boolean | null = null;

  isUserAdmin(): Observable<boolean> {
    if (this._isUserAdmin !== null) {
      return of(this._isUserAdmin!);
    }

    return this.http.get<boolean>(apiRoutes.user.isUserAdmin);
  }

  isUserInGroup(groupName: string): Observable<boolean> {
    return this.http.get<boolean>(apiRoutes.user.isUserInGroup(groupName));
  }
}
