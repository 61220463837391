import { AfterViewChecked, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { RecordTypeEnum } from '@components/constants';
import { RecordDetails } from '../record-detail.model';
import { Title } from '@angular/platform-browser';
import { PageTitles } from '@core/constants/page-titles.constants';
import { Router } from '@angular/router';

@Component({
  selector: 'app-record-header-content',
  templateUrl: './record-header-content.component.html',
  styleUrls: ['./record-header-content.component.scss']
})
export class RecordHeaderContentComponent implements OnInit, AfterViewChecked, OnChanges {
  @Input() recordDetails: RecordDetails | undefined;
  @Input() RecordTypeEnum = RecordTypeEnum;
  @Input() isBrowse: boolean;
  @Input() recordNumber: string | undefined | null;

  constructor(
    private titleService: Title,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.setRecordNumber();
  }

  ngAfterViewChecked(): void {
    this.setPageTitle();
  }

  setPageTitle() {
    if (this.isBrowse) {
      this.titleService.setTitle(PageTitles.BrowseRecord);
      return;
    }

    if (!this.recordDetails || this.router.isTranslation()) return;

    let titleType =
      this.recordDetails!.type.toLowerCase() === 'cmr'
        ? 'CMR'
        : this.recordDetails!.type.replace(this.recordDetails!.type[0], this.recordDetails!.type[0].toUpperCase());

    this.router.isEdit() || this.router.isAdminEdit()
      ? this.titleService.setTitle(`${PageTitles.EditRecord} ${titleType} - ${this.recordDetails!.recordTitle}`)
      : this.titleService.setTitle(`${PageTitles.ViewRecord} ${titleType} - ${this.recordDetails!.recordTitle}`);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.recordDetails) {
      this.setRecordNumber();
    }
  }

  setRecordNumber(): void {
    if (!this.recordDetails) {
      this.recordNumber = undefined;
    } else {
      switch (this.recordDetails.type) {
        case this.RecordTypeEnum.Qualification:
          this.recordNumber = this.recordDetails.qualificationNumber;
          break;
        case this.RecordTypeEnum.Standard:
          this.recordNumber = this.recordDetails.stdNo;
          break;
        case this.RecordTypeEnum.MicroCredential:
          this.recordNumber = this.recordDetails.mcNo;
          break;
        case this.RecordTypeEnum.CMR:
          this.recordNumber = this.recordDetails.cmrNo;
          break;
        case this.RecordTypeEnum.Programme:
          this.recordNumber = this.recordDetails.progNo;
          break;
        default:
          this.recordNumber = null;
      }
    }
  }

  get getRecordNumber(): string | undefined | null {
    return this.recordNumber;
  }
}
