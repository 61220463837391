import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpErrorResponse, HttpHandler, HttpEvent } from '@angular/common/http';

import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ErrorModalService } from '@components/error-modal/error-modal.service';
import { environment } from '@environments/environment';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(private errorService: ErrorModalService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.error instanceof Error) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('An error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          // The response body may contain clues as to what went wrong,
          console.error(`Backend returned code ${error.status}, body was: ${JSON.stringify(error)}`);
        }

        const displayApiErrorDetail = environment.displayApiErrorDetail;
        var displayedMessage: string;

        if (error.status === 409) {
          displayedMessage =
            'The record you are trying to update has been modified by another user. Please refresh the page and try again.';
        } else {
          displayedMessage = displayApiErrorDetail
            ? this.getErrorMessageForDevTest(error)
            : this.getErrorMessageForUATProd(error);
        }
        this.errorService.open(displayedMessage).result.then(() => {
          if (error.status === 409) location.reload();
        });
        throw error.error;
      })
    );
  }

  getErrorMessageForDevTest(error: HttpErrorResponse): string {
    return `${error.statusText}(${error.status}.  ${error.message}  ${error.error?.detail}`;
  }

  getErrorMessageForUATProd(error: HttpErrorResponse): string {
    /**
     * If the backend sent an meaningful error message show it to the user
     */
    if (error.status < 500 && error.error.detail) {
      return error.error.detail;
    }
    return `Something went wrong, please try again.
    If it continues contact support.`;
  }
}
