import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';

@Injectable()
export class ApibaseInterceptor implements HttpInterceptor {
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (request.url.startsWith('/assets')) {
      return next.handle(request);
    }
    const apiBaseUrl = environment.apiBaseUrl;
    return next.handle(
      request.clone({
        url: apiBaseUrl + request.url
      })
    );
  }
}
