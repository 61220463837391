export enum ProgrammeStatusEnum {
  Draft = 2001,
  Current = 2002,
  Expiring = 2003,
  Discontinued = 2004,
  Withdrawn = 2005,
  Declined = 2006
}

export enum ProgrammeVersionStatusEnum {
  Approved = 2101,
  Declined = 2102,
  Discontinued = 2103,
  Draft = 2104,
  // DraftSubmitted = 2105,
  Exempt = 2106,
  // Expiring = 2107,
  Superseded = 2108,
  Withdrawn = 2109
}

export enum ProgrammeLeadsToEnum {
  OtherProfessionalRegistration = 2404
}
