// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.warning-message .warning-header {
  margin-bottom: 20px;
}
.warning-message th,
.warning-message td {
  padding-right: 20px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInJpY2gtd2FybmluZy1tZXNzYWdlLmNvbXBvbmVudC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUNFO0VBQ0UsbUJBQUE7QUFBSjtBQUVFOztFQUVFLG1CQUFBO0FBQUoiLCJmaWxlIjoicmljaC13YXJuaW5nLW1lc3NhZ2UuY29tcG9uZW50LnNjc3MiLCJzb3VyY2VzQ29udGVudCI6WyIud2FybmluZy1tZXNzYWdlIHtcbiAgLndhcm5pbmctaGVhZGVyIHtcbiAgICBtYXJnaW4tYm90dG9tOiAyMHB4O1xuICB9XG4gIHRoLFxuICB0ZCB7XG4gICAgcGFkZGluZy1yaWdodDogMjBweDtcbiAgfVxufVxuIl19 */`, "",{"version":3,"sources":["webpack://./src/app/components/rich-warning-message/rich-warning-message.component.scss"],"names":[],"mappings":"AACE;EACE,mBAAA;AAAJ;AAEE;;EAEE,mBAAA;AAAJ;AACA,4dAA4d","sourcesContent":[".warning-message {\n  .warning-header {\n    margin-bottom: 20px;\n  }\n  th,\n  td {\n    padding-right: 20px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
