import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TypeaheadComponent } from './typeahead.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PipesModule } from '@core/pipes/pipes.module';
import { RichWarningMessageModule } from '@components/rich-warning-message/rich-warning-message.module';

@NgModule({
  declarations: [TypeaheadComponent],
  imports: [CommonModule, NgbModule, ReactiveFormsModule, FormsModule, PipesModule, RichWarningMessageModule],
  exports: [TypeaheadComponent]
})
export class TypeaheadModule {}
