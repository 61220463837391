import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { QualDeveloperComponent } from './qual-developer/qual-developer.component';
import { FormComponentsModule } from '@components/form-components/form-components.module';
import { TypeaheadModule } from '@components/typeahead/typeahead.module';
import { PipesModule } from '@core/pipes/pipes.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ClassificationTypeaheadComponent as ClassificationTypeaheadComponent } from './classification-typeahead/classification-typeahead.component';
import { RangeInputComponent } from './range-input/range-input.component';
import { CaseIdTypeaheadComponent } from './caseid/caseid-typeahead.component';
import { StandardTypeaheadComponent } from './standard-typeahead/standard-typeahead.component';
import { MicroCredentialTypeaheadComponent } from './micro-credential-typeahead/micro-credential-typeahead.component';
import { QualificationTypeaheadComponent } from './qualification-typeahead/qualification-typeahead.component';

@NgModule({
  declarations: [
    QualDeveloperComponent,
    ClassificationTypeaheadComponent,
    RangeInputComponent,
    CaseIdTypeaheadComponent,
    StandardTypeaheadComponent,
    MicroCredentialTypeaheadComponent,
    QualificationTypeaheadComponent
  ],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, FormComponentsModule, TypeaheadModule, PipesModule],
  exports: [
    QualDeveloperComponent,
    ClassificationTypeaheadComponent,
    RangeInputComponent,
    CaseIdTypeaheadComponent,
    StandardTypeaheadComponent,
    QualificationTypeaheadComponent
  ]
})
export class FormComponentsQcrModule {}
