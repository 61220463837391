import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { apiRoutes } from '@core/constants/api-routes.constants';
import { map, Observable, of } from 'rxjs';
import { LookupListCategory, LookupListItem } from './models/admin/lookupList.model';
import { RecordTypeNumericEnum } from '@components/constants';
import { PageModel } from './models/type/type';

@Injectable({
  providedIn: 'root'
})
export class AdminService {
  constructor(private http: HttpClient) {}

  private lookupLists: { [category: number]: LookupListItem[] } = {};

  public getLookupList = (category: LookupListCategory, activeOnly: boolean = false): Observable<LookupListItem[]> => {
    if (this.lookupLists[category]?.length) {
      const list = this.lookupLists[category].filter(i => !activeOnly || !i.disabled);
      return of(list);
    } else {
      return this.http.get<LookupListItem[]>(apiRoutes.lookup.getLookupList(category)).pipe(
        map(list => {
          this.lookupLists[category] = list;
          return list.filter(i => !activeOnly || !i.disabled);
        })
      );
    }
  };

  public getAllLookupLists = (): Observable<LookupListItem[]> => {
    return this.http.get<LookupListItem[]>(apiRoutes.admin.getLookupLists());
  };

  public saveLookupListItems = (listItems: LookupListItem[]): Observable<LookupListItem[]> => {
    return this.http.patch<LookupListItem[]>(apiRoutes.admin.saveLookupLists(), listItems);
  };

  public clearCache = () => {
    this.lookupLists = {};
  };

  public getTypes = (recordType: RecordTypeNumericEnum): Observable<PageModel[]> => {
    return this.http.get<PageModel[]>(apiRoutes.admin.getTypes(recordType));
  };
}
