import { Router } from '@angular/router';
import { RecordTypeEnum } from '@components/constants';
import { PageViewType } from '@core/services/models/shared/page-view-type-enum';

declare module '@angular/router' {
  interface Router {
    isEdit(): boolean;
    isAdminEdit(): boolean;
    isEditTransitional(): boolean;
    isEditFunding(): boolean;
    isView(): boolean;
    isCreateDraft(): boolean;
    isEditAssessment(): boolean;
    isTranslation(): boolean;
    isBrowseStandardGroup(): boolean;
    getPageViewType(): PageViewType;
    getRecordNo(): string | null;
    getRecordId(): string | null;
    getRecordType(): RecordTypeEnum | null;
  }
}

Router.prototype.getPageViewType = function (this: Router) {
  const url = this.url.replace('/accreditation/', '');
  if (url.includes('edit/transition')) return PageViewType.EditTransition;
  else if (url.includes('edit/admin')) return PageViewType.AdminEdit;
  else if (url.includes('edit/funding')) return PageViewType.EditFunding;
  else if (url.includes('edit/assessment')) return PageViewType.EditAssessment;
  else if (url.includes('create')) return PageViewType.CreateDraft;
  else if (url.includes('edit')) return PageViewType.Edit;
  else if (url.includes('translation')) return PageViewType.Translation;
  else if (url.includes('browse/standard-group')) return PageViewType.BrowseStandardGroup;
  else return PageViewType.View;
};

Router.prototype.isView = function (this: Router): boolean {
  return this.getPageViewType() == PageViewType.View;
};

Router.prototype.isEdit = function (this: Router): boolean {
  return this.getPageViewType() == PageViewType.Edit;
};

Router.prototype.isAdminEdit = function (this: Router): boolean {
  return this.getPageViewType() == PageViewType.AdminEdit;
};

Router.prototype.isEditTransitional = function (this: Router): boolean {
  return this.getPageViewType() == PageViewType.EditTransition;
};

Router.prototype.isEditFunding = function (this: Router): boolean {
  return this.getPageViewType() == PageViewType.EditFunding;
};

Router.prototype.isTranslation = function (this: Router): boolean {
  return this.getPageViewType() == PageViewType.Translation;
};

Router.prototype.isCreateDraft = function (this: Router): boolean {
  return this.getPageViewType() == PageViewType.CreateDraft;
};

Router.prototype.isBrowseStandardGroup = function (this: Router): boolean {
  return this.getPageViewType() == PageViewType.BrowseStandardGroup;
};

Router.prototype.isEditAssessment = function (this: Router): boolean {
  return this.getPageViewType() == PageViewType.EditAssessment;
};

Router.prototype.getRecordNo = function (this: Router): string | null {
  const url = this.url.replace('/browse', '');

  if (url.startsWith('/create/')) {
    return null;
  }

  const recordType = this.getRecordType();

  if (recordType != null) {
    const recordNo = url.split('/')[2]?.split('?')[0];
    return recordNo;
  }

  return null;
};

Router.prototype.getRecordId = function (this: Router): string | null {
  if (this.isCreateDraft()) {
    return null;
  }

  const url = this.url.replace('/browse', '');
  const recordType = this.getRecordType();

  if (recordType != null) {
    const recordId = url.split('/')[4];
    return recordId;
  }

  return null;
};

Router.prototype.getRecordType = function (this: Router): RecordTypeEnum | null {
  let url = this.url.replace('/browse', '');

  // remove '/create' from start of url
  // create route is in the format /create/qualification
  // convert to /qualification/ for the purposes of this method
  if (url.startsWith('/create/')) {
    url = url.substring(7) + '/';
  }

  if (url.startsWith('/qualification/')) {
    return RecordTypeEnum.Qualification;
  } else if (url.startsWith('/classification/')) {
    return RecordTypeEnum.Classification;
  } else if (url.startsWith('/programme/')) {
    return RecordTypeEnum.Programme;
  } else if (url.startsWith('/standard/')) {
    return RecordTypeEnum.Standard;
  } else if (url.startsWith('/standard-group/')) {
    return RecordTypeEnum.StandardGroup;
  } else if (url.startsWith('/micro-credential/')) {
    return RecordTypeEnum.MicroCredential;
  } else if (url.startsWith('/accreditation/')) {
    return RecordTypeEnum.Accreditation;
  } else if (url.startsWith('/consent/')) {
    return RecordTypeEnum.Consent;
  } else if (url.startsWith('/cmr/')) {
    return RecordTypeEnum.CMR;
  } else if (url.startsWith('/rule-group/')) {
    return RecordTypeEnum.RuleGroup;
  }

  return null;
};
