import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ControlValueAccessorConnector } from '../control-value-accessor-connector';

@Component({
  selector: 'app-text-area-basic',
  templateUrl: './text-area-basic.component.html',
  styleUrls: ['./text-area-basic.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextAreaBasicComponent),
      multi: true
    }
  ]
})
export class TextAreaBasicComponent extends ControlValueAccessorConnector implements ControlValueAccessor {
  @Input() placeholder: string = '';
  @Input() autocomplete: boolean = false;
  @Input() maxlength: number | null = null;
  @Input() hideWarning: boolean = false;
  @Input() value: string | null | undefined;
  @Input() readonly: boolean = false;
  @Input() rows: number = 4;
  @Input() focusOnLoad: boolean = false;
  @Input() attributeId = this.formControlName;
  @Input() ariaLabel: string | null;

  constructor() {
    super();
  }
  /**
   * Get the autocomplete input element value
   * @returns on/off state of the autocomplete
   */
  autoComplete() {
    return this.autocomplete ? 'on' : 'off';
  }
}
