export enum CmrStatusEnum {
  Draft = 6001,
  Current = 6002,
  Expiring = 6003,
  Discontinued = 6004,
  Withdrawn = 6005
}

export enum CmrVersionStatusEnum {
  Draft = 6101,
  Approved = 6102,
  Superseded = 6103,
  Withdrawn = 6104,
  Discontinued = 6105
}
