import { Component, forwardRef, OnInit } from '@angular/core';
import { AbstractControl, ValidationErrors, ValidatorFn, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DateComponent } from './date.component';

@Component({
  selector: 'app-date-min-setter',
  templateUrl: './date.component.html',
  styleUrls: ['./date.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DateMinSetterComponent),
      multi: true
    }
  ]
})
export class DateMinSetterComponent extends DateComponent implements OnInit {
  initialValue: string;

  constructor() {
    super();
  }

  ngOnInit() {
    if (!this.control) {
      return;
    }

    this.min = new Date().addDays(1)!.datePart();
    this.initialValue = this.control.value;

    this.control.valueChanges.subscribe(x => {
      this.control.addValidators([this.dateIsFutureValidator]);
      this.control.updateValueAndValidity({ onlySelf: true, emitEvent: false });
    });
  }

  dateIsFutureCheck(value: string | undefined): boolean {
    const isInFuture = value?.toDate()?.isFuture();

    return isInFuture ?? false;
  }

  dateIsFutureValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    if (!control) {
      return null;
    }
    const isFuture = this.dateIsFutureCheck(control.value);
    return isFuture || this.isEmptyOrDefaultValue(control) ? null : { 'date-minimum': true };
  };

  isEmptyOrDefaultValue(control: AbstractControl): boolean {
    return control.value === null || control.value === '';
  }
}

/// unsubscribe
/// clear validator (if does not exist, add it. if does exist, clear and add new. call update this.control updateand apply changes)
