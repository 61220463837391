import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { RecordTypeEnum, VersionStatusEnum } from '@components/constants';
import '@core/extensions/router.extensions';
import { AccreditationStatusEnum } from '@views/accreditation/constants';
import { CfSystemType, ClassificationStatus } from '@views/classification/constants';
import { CmrVersionStatusEnum } from '@views/cmr/constants';
import { ConsentStatusEnum } from '@views/consent-to-assess/constants';
import { MicroCredentialVersionStatusEnum } from '@views/micro-credential/constants';
import { ProgrammeVersionStatusEnum } from '@views/programme/constants';
import { StandardVersionStatusEnum } from '@views/standard/constants';
import { maxBy } from 'lodash';
import { BehaviorSubject, Observable, Subscription, filter, map, take } from 'rxjs';
import { AccreditationService } from './accreditation.service';
import { ClassificationService } from './classification.service';
import { CmrService } from './cmr.service';
import { ConsentToAssessService } from './consent-to-assess.service';
import { MicroCredentialService } from './micro-credential.service';
import { IVersionedRecord } from './models/IVersionedRecord';
import { IAccreditation } from './models/accreditation/accreditation.model';
import { GetClassification } from './models/classification/get-classification.model';
import { ICmr } from './models/cmr/cmr';
import { IConsentToAssess } from './models/consent-to-assess/consent-to-assess';
import { IMicroCredential } from './models/micro-credential/micro-credential';
import { IProgramme, IStrictProgramme } from './models/programme/programme';
import { IQualification } from './models/qualification/qualification';
import { RecordBrokerModel, RecordBrokerVersionModel, VersionMapFactory } from './models/record-broker.model';
import { IStandard, Standard } from './models/standard/standard';
import { ProgrammeService } from './programme.service';
import { QualificationService } from './qualification.service';
import { RecordViewHistoryService } from './record-view-history.service';
import { StandardGroupsService } from './standard-groups.service';
import { StandardService } from './standard.service';

@Injectable({
  providedIn: 'root'
})
export class RecordBrokerService {
  paramMap$: Subscription;

  private _recordId: string | undefined;
  get recordId() {
    return this._recordId;
  }

  private _latestVerNo: number | undefined;
  private _recordVersionStatus: number | undefined;
  private _selectedVerNo: number | undefined;
  private _recordStatus: number | undefined;
  private _latestApprovedOrDraftRecord: any | undefined;
  private _classificationType?: CfSystemType | null;

  private _currentRecordVersionStatus:
    | StandardVersionStatusEnum
    | MicroCredentialVersionStatusEnum
    | VersionStatusEnum
    | ProgrammeVersionStatusEnum
    | CmrVersionStatusEnum
    | undefined
    | null;

  private _latestRecordVersionStatus:
    | StandardVersionStatusEnum
    | MicroCredentialVersionStatusEnum
    | VersionStatusEnum
    | ProgrammeVersionStatusEnum
    | CmrVersionStatusEnum
    | undefined
    | null;

  private _currentRecordStatus: AccreditationStatusEnum | ConsentStatusEnum | ClassificationStatus | null;

  /**
   * The version status of the latest version of the record.  Not the selected version.
   */
  get recordVersionStatus(): number | undefined {
    return this._recordVersionStatus;
  }
  /**
   * The status of the record
   */
  get recordStatus(): number | undefined {
    return this._recordStatus;
  }

  /**
   * Get the version number of the latest record
   */
  get latestVerNo(): number | undefined {
    return this._latestVerNo;
  }

  /**
   * The version number of the selected version
   */
  get selectedVerNo(): number | undefined {
    return this._selectedVerNo;
  }

  /**
   * The last approved or draft record
   */
  get latestApprovedOrDraftRecord(): any | undefined {
    return this._latestApprovedOrDraftRecord;
  }

  private _recordRetrieved: BehaviorSubject<RecordBrokerModel | null> = new BehaviorSubject<RecordBrokerModel | null>(
    null
  );
  get recordRetrieved() {
    return this._recordRetrieved.asObservable();
  }

  get classificationType() {
    return this._classificationType;
  }

  private _versionUpdated: BehaviorSubject<RecordBrokerVersionModel | null> =
    new BehaviorSubject<RecordBrokerVersionModel | null>(null);

  get versionUpdated() {
    return this._versionUpdated.asObservable();
  }

  get selectedVersion() {
    return this._versionUpdated.value;
  }

  get qualificationVersionUpdated() {
    return this.versionUpdated.pipe(
      filter(x => x?.recordType === RecordTypeEnum.Qualification),
      map((x: RecordBrokerVersionModel | null) => {
        return x!.record as IQualification;
      })
    );
  }

  get classificationVersionUpdated(): Observable<GetClassification> {
    return this.versionUpdated.pipe(
      filter(x => x?.recordType === RecordTypeEnum.Classification),
      map((x: RecordBrokerVersionModel | null) => {
        return x!.record as GetClassification;
      })
    );
  }

  get programmeVersionUpdated(): Observable<IProgramme> {
    return this.versionUpdated.pipe(
      filter(x => x?.recordType === RecordTypeEnum.Programme),
      map((x: RecordBrokerVersionModel | null) => {
        return x!.record as IProgramme;
      })
    );
  }

  get accreditationVersionUpdated(): Observable<IAccreditation> {
    return this.versionUpdated.pipe(
      filter(x => x?.recordType === RecordTypeEnum.Accreditation),
      map((x: RecordBrokerVersionModel | null) => {
        return x!.record as IAccreditation;
      })
    );
  }

  get consentVersionUpdated(): Observable<IConsentToAssess> {
    return this.versionUpdated.pipe(
      filter(x => x?.recordType === RecordTypeEnum.Consent),
      map((x: RecordBrokerVersionModel | null) => {
        return x!.record as IConsentToAssess;
      })
    );
  }

  get microCredentialVersionUpdated(): Observable<IMicroCredential> {
    return this.versionUpdated.pipe(
      filter(x => x?.recordType === RecordTypeEnum.MicroCredential),
      map((x: RecordBrokerVersionModel | null) => {
        return x!.record as IMicroCredential;
      })
    );
  }

  get standardVersionUpdated() {
    return this.versionUpdated.pipe(
      filter(x => x?.recordType === RecordTypeEnum.Standard),
      map((x: RecordBrokerVersionModel | null) => {
        return { ...new Standard(), ...x!.record } as Standard;
      })
    );
  }

  get cmrVersionUpdated(): Observable<ICmr> {
    return this.versionUpdated.pipe(
      filter(x => x?.recordType === RecordTypeEnum.CMR),
      map((x: RecordBrokerVersionModel | null) => {
        return x!.record as ICmr;
      })
    );
  }

  get currentRecordVersionStatus() {
    return this._currentRecordVersionStatus;
  }

  get currentRecordStatus() {
    return this._currentRecordStatus;
  }

  get latestRecordVersionStatus() {
    return this._latestRecordVersionStatus;
  }

  constructor(
    private recordViewHistoryService: RecordViewHistoryService,
    private qualService: QualificationService,
    private standardService: StandardService,
    private standardGroupService: StandardGroupsService,
    private classificationService: ClassificationService,
    private cmrService: CmrService,
    private programmeService: ProgrammeService,
    private accreditationService: AccreditationService,
    private microCredentialService: MicroCredentialService,
    private consentToAssessService: ConsentToAssessService,
    private router: Router,
    private currentRoute: ActivatedRoute
  ) {
    this.setupObservable();
    this.setupRouterListener();
    this.registerVersionUpdated();
  }

  registerVersionUpdated() {
    this.versionUpdated.pipe(filter(x => x != null)).subscribe(x => {
      this.registerViewHistory(x!);
    });
  }

  private setupRouterListener() {
    this.paramMap$ = this.router.events.subscribe(event => {
      this.clear();
      if (event instanceof NavigationEnd) {
        let recordNo = this.router.getRecordNo();
        if (recordNo) {
          this.getRecord(recordNo);
        } else {
          console.warn('No id found in route');
        }
      }
    });
  }

  public refreshRecordFromRoute() {
    let recordNo = this.router.getRecordNo();
    if (recordNo) {
      this.getRecord(recordNo);
    }
  }

  /**
   * Sets up an observable to listen for new records being retrieved
   */
  private setupObservable() {
    this.recordRetrieved.subscribe(x => {
      const recordType = this.router.getRecordType();
      if (!x || !recordType) {
        return;
      }

      this.setRecordVersion(x.records);
      this._classificationType = undefined;

      switch (recordType) {
        case RecordTypeEnum.Classification:
          this.handleClassification(x.records as GetClassification[]);
          break;
        case RecordTypeEnum.Programme:
          this.handleProgramme(x.records as IStrictProgramme[]);
          break;
        case RecordTypeEnum.Qualification:
          this.handleQualification(x.records as IQualification[]);
          break;
        case RecordTypeEnum.Standard:
          this.handleStandard(x.records as IStandard[]);
          break;
        case RecordTypeEnum.MicroCredential:
          this.handleMicroCredential(x.records as IMicroCredential[]);
          break;
        case RecordTypeEnum.Accreditation:
          this.handleAccreditation(x.records as IAccreditation[]);
          break;
        case RecordTypeEnum.Consent:
          this.handleConsent(x.records as IConsentToAssess[]);
          break;
        case RecordTypeEnum.CMR:
          this.handleCmr(x.records as ICmr[]);
          break;
      }
    });
  }

  private getRecord(recordNo: string) {
    const recordType = this.router.getRecordType();

    switch (recordType) {
      case RecordTypeEnum.Accreditation:
        this.accreditationService.get(recordNo).subscribe(x => {
          this._recordRetrieved.next(
            new RecordBrokerModel({
              recordType: RecordTypeEnum.Accreditation,
              records: [x]
            })
          );
        });
        break;
      case RecordTypeEnum.Consent:
        this.consentToAssessService.get(recordNo).subscribe(x => {
          this._recordRetrieved.next(
            new RecordBrokerModel({
              recordType: RecordTypeEnum.Consent,
              records: [x]
            })
          );
        });
        break;
      case RecordTypeEnum.Classification:
        //if the route is for the placeholder classifications, don't get the record as it doesn't exist.
        if (
          this.router.url.toLowerCase().startsWith('/browse/classification/nzsced') ||
          this.router.url.toLocaleLowerCase().startsWith('/browse/classification/scunq')
        ) {
          return;
        }

        let cfSystemType = this.classificationService.queryCfSystemType!;
        this.classificationService.get(recordNo, cfSystemType).subscribe(x => {
          this._recordRetrieved.next(
            new RecordBrokerModel({
              recordType: RecordTypeEnum.Classification,
              records: [x],
              versions: VersionMapFactory.fromClassification([x])
            })
          );
        });
        break;
      case RecordTypeEnum.Programme:
        const programmeProvider: Observable<IStrictProgramme[]> = this.router.isCreateDraft()
          ? this.programmeService.getCreateDraft(recordNo)
          : this.programmeService.get(recordNo);
        programmeProvider.subscribe(x => {
          this._recordRetrieved.next(
            new RecordBrokerModel({
              recordType: RecordTypeEnum.Programme,
              records: x,
              versions: VersionMapFactory.fromRecords<IStrictProgramme>(x, x => x.id)
            })
          );
        });
        break;
      case RecordTypeEnum.MicroCredential:
        const microCredentialProvider: Observable<IMicroCredential[]> = this.router.isCreateDraft()
          ? this.microCredentialService.getCreateDraft(recordNo)
          : this.microCredentialService.get(recordNo);
        microCredentialProvider.subscribe(x => {
          this._recordRetrieved.next(
            new RecordBrokerModel({
              recordType: RecordTypeEnum.MicroCredential,
              records: x,
              versions: VersionMapFactory.fromRecords<IMicroCredential>(x, x => x.id)
            })
          );
        });
        break;
      case RecordTypeEnum.CMR:
        const cmrProvider: Observable<ICmr[]> = this.router.isCreateDraft()
          ? this.cmrService.getCreateDraft(recordNo)
          : this.cmrService.get(recordNo);
        cmrProvider.subscribe(x => {
          this._recordRetrieved.next(
            new RecordBrokerModel({
              recordType: RecordTypeEnum.CMR,
              records: x,
              versions: VersionMapFactory.fromRecords<ICmr>(x, x => x.id)
            })
          );
        });
        break;
      case RecordTypeEnum.Standard:
        const standardProvider: Observable<IStandard[]> = this.router.isCreateDraft()
          ? this.standardService.getCreateDraft(recordNo)
          : this.standardService.get(recordNo);
        standardProvider.subscribe(x => {
          this._recordRetrieved.next(
            new RecordBrokerModel({
              recordType: RecordTypeEnum.Standard,
              records: x,
              versions: VersionMapFactory.fromRecords<IStandard>(x, x => x.id)
            })
          );
        });
        break;
      case RecordTypeEnum.StandardGroup:
        let standardGroupType = this.standardGroupService.queryType!;
        if (!recordNo || !standardGroupType) break;
        if (!recordNo.isValidGuid()) break;

        this.standardGroupService.get(recordNo, standardGroupType).subscribe(x => {
          this._recordRetrieved.next(
            new RecordBrokerModel({
              recordType: RecordTypeEnum.StandardGroup,
              records: [x],
              versions: []
            })
          );
        });
        break;
      case RecordTypeEnum.Qualification:
        this.qualService.getByQualNo(recordNo).subscribe((quals: IQualification[]) => {
          this._recordRetrieved.next(
            new RecordBrokerModel({
              recordType: RecordTypeEnum.Qualification,
              records: quals,
              versions: VersionMapFactory.fromRecords<IQualification>(quals, x => x.id)
            })
          );
        });
        break;
    }
  }

  /**
   * Given a list of standards, find the record for the current route and broadcast it
   * @param standards
   */
  private handleStandard(standards: IStandard[]) {
    let recordId = this.router.getRecordId();
    if (!recordId) {
      recordId = standards[0].id!;
    }

    let standard = standards.find(x => x.id === recordId) ?? standards[0];
    recordId = standard.id!;

    this._currentRecordVersionStatus = standard.verStatus;
    this._latestRecordVersionStatus = standards.sort((a, b) => b.verNo! - a.verNo!)[0].verStatus;
    this._currentRecordStatus = null;

    this.notifyVersionUpdatedSubject(
      new RecordBrokerVersionModel({
        recordType: RecordTypeEnum.Standard,
        record: standard,
        verNo: standard!.verNo!,
        recordId: recordId,
        recordNo: standard!.stdNo!
      })
    );
  }
  /**
   * Given a list of programmes, find the record for the current route and broadcast it
   * @param programmes
   */
  private handleProgramme(programmes: IStrictProgramme[]) {
    let recordId = this.router.getRecordId();
    if (!recordId) {
      recordId = programmes[0].id!;
    }

    let programme = programmes.find(x => x.id === recordId) ?? programmes[0];
    recordId = programme.id!;

    this._currentRecordVersionStatus = programme.verStatus;
    this._currentRecordStatus = null;
    this._latestRecordVersionStatus = programmes.sort((a, b) => b.verNo! - a.verNo!)[0].verStatus;

    this.notifyVersionUpdatedSubject(
      new RecordBrokerVersionModel({
        recordType: RecordTypeEnum.Programme,
        record: programme,
        verNo: programme!.verNo,
        recordId: recordId,
        recordNo: programme!.progNo
      })
    );
  }

  /**
   * Given a list of accreditations, find the record for the current route and broadcast it
   * @param accreditations
   */
  private handleAccreditation(accreditations: IAccreditation[]) {
    let recordId = this.router.getRecordId();
    if (!recordId) {
      recordId = accreditations[0].id!;
    }

    let accreditation = accreditations.find(x => x.id === recordId) ?? accreditations[0];
    recordId = accreditation.id!;

    this._currentRecordVersionStatus = null;
    this._currentRecordStatus = accreditation.status!;

    this.notifyVersionUpdatedSubject(
      new RecordBrokerVersionModel({
        recordType: RecordTypeEnum.Accreditation,
        record: accreditation,
        verNo: 1,
        recordId: recordId,
        recordNo: accreditation!.id!
      })
    );
  }

  /**
   * Given a list of consent to assess, find the record for the current route and broadcast it
   * @param consents
   */
  private handleConsent(consents: IConsentToAssess[]) {
    let recordId = this.router.getRecordId();
    if (!recordId) {
      recordId = consents[0].id!;
    }

    let consent = consents.find(x => x.id === recordId) ?? consents[0];
    recordId = consent.id!;

    this._currentRecordVersionStatus = null;
    this._currentRecordStatus = consent.status!;

    this.notifyVersionUpdatedSubject(
      new RecordBrokerVersionModel({
        recordType: RecordTypeEnum.Consent,
        record: consent,
        verNo: 1,
        recordId: recordId,
        recordNo: consent!.ctaNo!
      })
    );
  }

  /**
   * Given a list of micro-credentials, find the record for the current route and broadcast it
   * @param microCredentials
   */
  private handleMicroCredential(microCredentials: IMicroCredential[]) {
    let recordId = this.router.getRecordId();
    if (!recordId) {
      recordId = microCredentials[0].id!;
    }

    let mc = microCredentials.find(x => x.id === recordId) ?? microCredentials[0];
    recordId = mc.id!;

    this._currentRecordVersionStatus = mc.verStatus;
    this._currentRecordStatus = null;
    this._latestRecordVersionStatus = microCredentials.sort((a, b) => b.verNo! - a.verNo!)[0].verStatus;

    this.notifyVersionUpdatedSubject(
      new RecordBrokerVersionModel({
        recordType: RecordTypeEnum.MicroCredential,
        record: mc,
        verNo: mc!.verNo,
        recordId: recordId,
        recordNo: mc!.mcNo
      })
    );
  }

  /**
   * Given a list of Cmr, find the record for the current route and broadcast it
   * @param cmrs
   */
  private handleCmr(cmrs: ICmr[]) {
    let recordId = this.router.getRecordId();
    if (!recordId) {
      recordId = cmrs[0].id!;
    }

    let mc = cmrs.find(x => x.id === recordId) ?? cmrs[0];
    recordId = mc.id!;

    this._currentRecordVersionStatus = mc.verStatus;
    this._currentRecordStatus = null;
    this._latestRecordVersionStatus = cmrs.sort((a, b) => b.verNo! - a.verNo!)[0].verStatus;

    this.notifyVersionUpdatedSubject(
      new RecordBrokerVersionModel({
        recordType: RecordTypeEnum.CMR,
        record: mc,
        verNo: mc!.verNo,
        recordId: recordId,
        recordNo: mc!.cmrNo
      })
    );
  }

  /**
   * Given a list of qualifications, find the record for the current route and broadcast it
   * @param quals
   */
  private handleQualification(quals: IQualification[]) {
    let recordId = this.router.getRecordId();
    if (!recordId) {
      recordId = quals[0].id!;
    }

    let qual = quals.find(x => x.id === recordId) ?? quals[0];
    recordId = qual.id!;

    this._currentRecordVersionStatus = qual.verStatus;
    this._currentRecordStatus = null;

    this.notifyVersionUpdatedSubject(
      new RecordBrokerVersionModel({
        recordType: RecordTypeEnum.Qualification,
        record: qual,
        verNo: qual!.verNo,
        recordId: recordId,
        recordNo: qual!.qualNo
      })
    );
  }

  /**
   * Given a list of classifications, find the record for the current route and broadcast it
   * @param classifications
   */
  private handleClassification(classifications: GetClassification[]) {
    let recordId = classifications[0].id!;

    let classification = classifications.find(x => x.id === recordId);

    this._currentRecordVersionStatus = null;
    this._currentRecordStatus = classification!.status;

    this.currentRoute.queryParams.pipe(take(1)).subscribe(d => {
      const intValue = parseInt(d.cfSystemType || '');
      this._classificationType = intValue as CfSystemType;
    });

    this.notifyVersionUpdatedSubject(
      new RecordBrokerVersionModel({
        recordType: RecordTypeEnum.Classification,
        record: classification,
        verNo: 1,
        recordId: recordId,
        recordNo: classification!.internalId
      })
    );
  }

  private notifyVersionUpdatedSubject(model: RecordBrokerVersionModel) {
    this._recordId = model.recordId;
    this._selectedVerNo = model.verNo;
    this._versionUpdated.next(model);
  }

  private registerViewHistory(model: RecordBrokerVersionModel) {
    if (model.recordType !== RecordTypeEnum.Classification && !this.router.isCreateDraft()) {
      this.recordViewHistoryService.upsertViewHistory(model.recordNo, model.recordId, model.recordType).subscribe();
    }
  }

  private clear() {
    this._recordRetrieved.next(null);
    this._versionUpdated.next(null);
  }

  /**
   *
   * @param records
   */
  private setRecordVersion(records: unknown[]) {
    let versionedRecords = records as IVersionedRecord[];
    let latestVersion = versionedRecords.length === 1 ? versionedRecords[0] : maxBy(versionedRecords, x => x?.verNo);
    if (latestVersion) {
      this._recordVersionStatus = latestVersion?.verStatus ?? undefined;
      this._latestVerNo = latestVersion?.verNo ?? undefined;
      this._recordStatus = latestVersion.status ?? undefined;
    }

    this.getRecentRecordNotWithdrawnOrDeclined(records);
  }

  // make const array of numbers with all enum version statuses that are withdrawn or declined
  // filter array of records where version status is not in above array
  // get top record and set value to class level property

  private getRecentRecordNotWithdrawnOrDeclined(records: any[]) {
    const withdrawnAndDeclinedEnums = [
      ProgrammeVersionStatusEnum.Withdrawn,
      ProgrammeVersionStatusEnum.Declined,
      MicroCredentialVersionStatusEnum.Withdrawn,
      MicroCredentialVersionStatusEnum.Declined,
      CmrVersionStatusEnum.Withdrawn
    ];

    let recordsThatAreNotWithdrawnOrDeclined = records.filter(
      (x: any) => !withdrawnAndDeclinedEnums.includes(x.verStatus)
    );
    this._latestApprovedOrDraftRecord = recordsThatAreNotWithdrawnOrDeclined.sort(
      (a: any, b: any) => b.verNo! - a.verNo!
    )[0];
  }
}
