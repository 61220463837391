export const PageTitles = {
  HomePage: 'NZQA QCR - Home',
  SearchRecord: 'NZQA QCR - Advanced Search',
  CreateRecord: 'NZQA QCR - Create ',
  CreateRecordDefault: 'NZQA QCR - Create Record',
  BrowseRecord: 'NZQA QCR - Browse',
  ViewRecord: 'NZQA QCR - ',
  EditRecord: 'Edit NZQA QCR - ',
  EditTranslationRecord: 'Edit Translation NZQA QCR - ',
  CreateTranslationRecord: 'Create Translation NZQA QCR - '
};
