import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BubbleTimelineComponent } from './bubble-timeline/bubble-timeline.component';
import { PipesModule } from '@core/pipes/pipes.module';

@NgModule({
  declarations: [BubbleTimelineComponent],
  imports: [CommonModule, PipesModule],
  exports: [BubbleTimelineComponent]
})
export class BubbleTimelineModule {}
