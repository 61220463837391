import { NgModule } from '@angular/core';
import { DisableButtonDirective } from './disable-button.directive';
import { FocusOnLoadDirective } from './focus-on-load.directive';
import { NullDefaultValueDirective } from './reset-to-null.directive';
import { SizeNotifierDirective } from './size-notifier.directive';

@NgModule({
  declarations: [SizeNotifierDirective, FocusOnLoadDirective, DisableButtonDirective, NullDefaultValueDirective],
  exports: [SizeNotifierDirective, FocusOnLoadDirective, DisableButtonDirective, NullDefaultValueDirective],
  imports: []
})
export class DirectivesModule {}
