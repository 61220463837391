import { QueryBase } from '../base/queryBase.model';

export class ClassificationExistsParameters extends QueryBase {
  public constructor(init?: Partial<ClassificationExistsParameters>) {
    super();
    Object.assign(this, init);
  }

  internalId: string;
  systemType: number;
  depth: number;
  status: number[];
  parent: string;
}
