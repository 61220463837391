import { MsalGuardConfiguration, MsalInterceptorConfiguration } from '@azure/msal-angular';
import {
  BrowserCacheLocation,
  Configuration,
  InteractionType,
  IPublicClientApplication,
  LogLevel,
  PublicClientApplication
} from '@azure/msal-browser';
import { environment } from '@environments/environment';

export const msalClientFactory = (): IPublicClientApplication => {
  const loggerCallback = (logLevel: LogLevel, message: string) => {};

  const configuration = {
    auth: {
      clientId: environment.authenticationAudience,
      authority: environment.authenticationAuthority,
      redirectUri: environment.redirectUri,
      postLogoutRedirectUri: '/'
    },

    system: {
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Trace,
        piiLoggingEnabled: true
      }
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage
    }
  } as Configuration;

  return new PublicClientApplication(configuration);
};

export const msalGuardConfigFactory = (): MsalGuardConfiguration => ({
  interactionType: InteractionType.Redirect,
  authRequest: {
    scopes: [environment.authorizationScope]
  }
});

export const msalInterceptorConfigFactory = (): MsalInterceptorConfiguration => ({
  interactionType: InteractionType.Redirect,
  protectedResourceMap: new Map([[environment.apiBaseUrl + '*', [environment.authorizationScope]]])
});
