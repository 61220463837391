import { BubbleTimeLineModel } from '@components/bubble-timeline/bubble-timeline/bubble-timeline.component';

export class Lookup<DataType> {
  text: string;
  value: DataType;
  disabled?: boolean;
}

export class EnumToLookup {
  /**
   * Converts an enum class to an array of Lookup objects for select lists
   * @param enumType
   * @returns
   */
  static toLookup<T>(enumType: object): Lookup<number>[] {
    const k = Object.keys(enumType).filter(v => typeof v == 'string' && isNaN(Number(+v)));
    const v = Object.values(enumType).filter(v => typeof v == 'number');
    return k
      .map((key, index) => {
        return { text: key, value: v[index] };
      })
      .sort((a, b) => a.value - b.value);
  }

  /**
   * Converts an enum value to its name
   * @param enumType The enum type
   * @param value The numeric value of the enum
   * @returns The name of the enum
   */

  static toName(enumType: object, value: number): string | undefined {
    return EnumToLookup.toLookup(enumType).find(x => x.value === value)?.text;
  }

  static display(enumType: object, value: number): string | undefined {
    let result = EnumToLookup.toName(enumType, value);
    result = result?.replace(/((?<=[a-z])[A-Z]|[A-Z](?=[a-z]))/g, ' $1').trim();
    return result;
  }

  static toNumbers(enumType: object): number[] {
    return EnumToLookup.toLookup(enumType).map(x => x.value);
  }

  static toTimeline(enumType: object, value: number): BubbleTimeLineModel[] {
    const lookup = EnumToLookup.toLookup(enumType);
    const result = lookup.map(x => {
      return { name: x.text, complete: x.value <= value };
    });
    return result;
  }

  // Returns an enum given its numeric or strange value so the call
  // FromValue(VersionStatusEnum, 1050) would return VersionStatusEnum.Draft
  static FromValue(enumType: object, value: string | number): any {
    const index = Object.values(enumType)
      .map(v => v.toString())
      .indexOf(value);
    return Object.keys(enumType)[index];
  }
}
