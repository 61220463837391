import { Component, ElementRef, HostListener, OnInit } from '@angular/core';
import { IsActiveMatchOptions, Router } from '@angular/router';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { InteractionStatus } from '@azure/msal-browser';
import { DestroyBaseComponent } from '@components/abstract/DestroyBaseComponent';
import { AltLabel } from '@core/constants/alt-label';
import { UserService } from '@core/services/user.service';
import { filter, switchMap, take, takeUntil } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent extends DestroyBaseComponent implements OnInit {
  apiResponse: string | undefined;
  showOptions: boolean = false;
  public isUserAdmin: boolean = false;
  isLoginInProgress = false;
  logoAlt = AltLabel.Logo;
  footerLogoAlt = AltLabel.FooterLogo;

  myMatchOptions: IsActiveMatchOptions = {
    queryParams: 'ignored',
    matrixParams: 'exact',
    paths: 'subset',
    fragment: 'exact'
  };
  constructor(
    private msalService: MsalService,
    private router: Router,
    private elementRef: ElementRef,
    private userService: UserService,
    private msalBroadcastService: MsalBroadcastService
  ) {
    super();
  }

  @HostListener('document:click', ['$event.target'])
  public onPageClick(targetElement: HTMLElement) {
    const clickedInside = this.elementRef.nativeElement.contains(targetElement);
    if (!clickedInside || !targetElement.hasAttribute('burger-menu')) {
      this.showOptions = false;
    }
  }

  ngOnInit(): void {
    this.setupLoginProcessListeners();
    this.assignUserDetail();
  }

  private setupLoginProcessListeners(): void {
    this.listenForLoginStatusChange(InteractionStatus.HandleRedirect, () => (this.isLoginInProgress = true));
    this.listenForLoginStatusChange(InteractionStatus.None, () => (this.isLoginInProgress = false));
  }

  private assignUserDetail(): void {
    this.msalService
      .initialize()
      .pipe(
        switchMap(() => this.msalService.handleRedirectObservable()),
        filter(response => !!response?.account),
        takeUntil(this.destroy$)
      )
      .subscribe(response => {
        this.msalService.instance.setActiveAccount(response.account);
        this.setIsUserAdmin();
      });
  }

  /**
   * Check if the login is still in progress.
   * When a sign in button is clicked, the spinner should still show until the app sets the user detail
   * @param status Interaction status to listen for; startUp, handleRedirect and None
   * @param callback Callback function
   */
  private listenForLoginStatusChange(status: InteractionStatus, callback: () => void): void {
    this.msalBroadcastService.inProgress$
      .pipe(
        filter((currentStatus: InteractionStatus) => currentStatus === status),
        takeUntil(this.destroy$)
      )
      .subscribe(() => callback());
  }

  private setIsUserAdmin(): void {
    this.userService
      .isUserAdmin()
      .pipe(take(1))
      .subscribe(a => (this.isUserAdmin = a));
  }

  toggleOptions() {
    this.showOptions = !this.showOptions;
  }

  isLoggedIn(): boolean {
    return this.msalService.instance.getActiveAccount() != null;
  }

  userExists(): boolean {
    return this.msalService.instance.getActiveAccount() != null;
  }

  logout() {
    //the router.navigate below is a workaround for the issue where
    //msalService.logoutRedirect() itself does not trigger pendingChangeGuard()
    let url = this.router.url;
    this.router.navigate(['/']).then(() => {
      if (url === '/' || url !== this.router.url) {
        this.msalService.logoutRedirect();
      }
    });
  }

  onClickBulkUpdate() {
    this.showOptions = !this.showOptions;
    this.router.navigate(['bulk-update']);
  }

  onAdministrationMenuClicked() {
    this.showOptions = false;
    this.router.navigate(['administration', 'edit-lookups']);
  }

  getActiveAccountName() {
    return this.msalService.instance.getActiveAccount()?.name;
  }
}
