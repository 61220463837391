import { Component, ContentChildren, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ToggleRadioElementComponent } from '@components/toggle-radio-group/toggle-radio-element.component';
import { MatRadioChange } from '@angular/material/radio';

@Component({
  selector: 'app-toggle-radio-group',
  templateUrl: './toggle-radio-group.component.html',
  styleUrls: ['./toggle-radio-group.component.scss']
})
export class ToggleRadioGroupComponent {
  // A list of ToggleRadioElementComponent elements that will be rendered as radio buttons and related templates
  @ContentChildren(ToggleRadioElementComponent) children: ToggleRadioElementComponent[];
  // A title to display above the group of radio buttons
  @Input() title: string;
  @Input() formGroup: FormGroup<any>;
  // The host component is responsible for triggering an error message to show as needed
  @Input() showError: boolean = false;
  @Input() errorMessage: string;

  public isRadioSelected = (formFieldName: string): boolean => {
    return this.formGroup?.get(formFieldName)?.value as boolean;
  };

  public updateRadioButton = (event: MatRadioChange, formsControlName: string) => {
    this.formGroup.get(formsControlName)?.setValue(true);

    this.children.forEach(child => {
      if (child.formFieldName !== formsControlName) {
        this.formGroup.get(child.formFieldName)?.setValue(false);
      }
    });
  };

  // This function prevents the panel body from toggling when the panel header is clicked to make hiding/showing
  // the template bodies completely dependent on the radio button
  public onPanelTitleClick = (event: Event) => {
    event.stopPropagation();
  };
}
