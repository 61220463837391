export enum PageViewType {
  View,
  Edit,
  AdminEdit,
  EditTransition,
  CreateDraft,
  Translation,
  EditFunding,
  EditAssessment,
  CreateProgrammeVersion,
  BrowseStandardGroup
}
