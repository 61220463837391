export enum MicroCredentialStatusEnum {
  Draft = 3001,
  Current = 3002,
  Expiring = 3003,
  Discontinued = 3004,
  Withdrawn = 3005,
  Declined = 3006
}

export enum MicroCredentialVersionStatusEnum {
  Draft = 3050,
  Approved = 3051,
  Superseded = 3052,
  Withdrawn = 3053,
  Declined = 3054,
  Discontinued = 3055
}

export enum MicroCredentialQualityAssuranceBodies {
  CommitteeOfUniversityAcademicProgrammes = 3201,
  NewZealandQualificationsAuthority = 3202
}
