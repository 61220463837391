import { LookupListCategory } from '@core/services/models/admin/lookupList.model';
import { StandardGroupTypeEnum } from '@core/services/models/standard-groups/standard-groups';
import { CfSystemType } from '@views/classification/constants';
import { AssociatedRecordTypeEnum } from '@views/pre-requisites/constants';

export const apiRoutes = {
  accreditation: {
    simpleSearch: (parameters: string) => `/accreditations/search/simple?${parameters}`,
    get: (accId: string) => `/accreditations/${accId}`,
    create: `/accreditations`,
    update: (accId: string) => `/accreditations/${accId}`,
    funding: (accId: string) => `/accreditations/${accId}/funding`,
    updateStatus: (accId: string) => `/accreditations/${accId}/status`,
    paged: (parameters: string) => `/accreditations?${parameters}`
  },
  classification: {
    get: (internalId: string, cfSystemType: CfSystemType) =>
      `/classifications/${internalId}?cfSystemType=${cfSystemType}`,
    getStatus: (internalId: string, parameters: string) =>
      `/classifications/${internalId}/status/current?${parameters}`,
    create: `/classifications`,
    update: (internalId: string) => `/classifications/${internalId}`,
    updateStatus: (internalId: string) => `/classifications/${internalId}/status`,
    exists: (parameters: string) => `/classifications/exists?${parameters}`,
    getReplacedBy: (internalId: string, parameters: string) =>
      `/classifications/replacedbyme/${internalId}?${parameters}`,
    paged: (parameters: string) => `/classifications?${parameters}`,
    doesConsentExistForClassification: (internalId: string, level: number) =>
      `/classifications/${internalId}/consentvalid/${level}`,
    getAssociatedStandards: (internalId: string, parameters: string) =>
      `/classifications/${internalId}/referenced/standards?${parameters}`,
    getAssociatedMicroCredentials: (internalId: string) =>
      `/classifications/${internalId}/associated/micro-credentials`,
    tree: (internalId: string, full: boolean, type: string) =>
      `/classifications/tree?full=${full}&nodeId=${internalId}&type=${type}`,
    subTree: (internalId: string, includeStandards: boolean) =>
      `/classifications/sub-tree?includeStandards=${includeStandards}&nodeId=${internalId}`,
    standardCmrAssociations: (internalId: string, cmrNoToIgnore?: string) =>
      `/classifications/${internalId}/associated/standard-cmrs?cmrNoToIgnore=${cmrNoToIgnore}`
  },
  cmr: {
    simpleSearch: (parameters: string) => `/cmrs/search/simple?${parameters}`,
    get: (cmrNo: string) => `/cmrs/${cmrNo}/all`,
    createDraft: (cmrNo: string) => `/cmrs/${cmrNo}/draft`,
    create: `/cmrs`,
    update: (cmrNo: string) => `/cmrs/${cmrNo}`,
    updateStatus: (cmrNo: string) => `/cmrs/${cmrNo}/status`,
    standardAssociations: (cmrNo: string, version: number) => `/cmrs/${cmrNo}/associated/standards?verNo=${version}`,
    classificationAssociations: (cmrNo: string, version: number) =>
      `/cmrs/${cmrNo}/associated/classifications?verNo=${version}`,
    updateAssociatedRecords: (cmrNo: string, version: number) => `/cmrs/${cmrNo}/associated-records?verNo=${version}`
  },
  consent: {
    simpleSearch: (parameters: string) => `/consents/search/simple?${parameters}`,
    create: `/consents`,
    bulkUpdate: (isTrialRun: boolean) => `/consents/bulk-upload?isTrialRun=${isTrialRun}`,
    get: (ctaNo: string) => `/consents/${ctaNo}`,
    updateStatus: (ctaNo: string) => `/consents/${ctaNo}/status`,
    validateStatus: (ctaNo: string, statusId: string) => `/consents/${ctaNo}/status/${statusId}/validate`,
    forClassification: (classificationInternalId: string) =>
      `/consents/for-classifications?classification=${classificationInternalId}`,
    forStandard: (stdNo: string, stdId: string | null) => {
      let url = `/consents/for-standards?stdNo=${stdNo}`;
      if (stdId !== null && stdId !== undefined) {
        url += `&stdId=${stdId}`;
      }
      return url;
    }
  },
  microCredential: {
    simpleSearch: (parameters: string) => `/microCredentials/search/simple?${parameters}`,
    create: `/microcredentials`,
    get: (mcNo: string) => `/microcredentials/${mcNo}/all`,
    paged: (parameters: string) => `/microcredentials?${parameters}`,
    update: (mcNo: string, verNo: number) => `/microcredentials/${mcNo}?verNo=${verNo}`,
    createDraft: (mcNo: string) => `/microcredentials/${mcNo}/draft`,
    getById: (mcNo: string, id: string) => `/microcredentials/${mcNo}?id=${id}`,
    updateStatus: (mcNo: string) => `/microcredentials/${mcNo}/status`,
    getPrerequisites: (mcNo: string, verNo: number, recordType: AssociatedRecordTypeEnum) =>
      `/microcredentials/${mcNo}/pre-requisites/${verNo}?associatedRecordType=${recordType}`,
    getAllPrerequisites: (mcNo: string, verNo: number) => `/microcredentials/${mcNo}/pre-requisites/${verNo}`,
    getTranslation: (mcNo: string, verNo: number) => `/microcredentials/${mcNo}/translation/${verNo}`,
    addPreReqsForMicroCredential: (mcNo: string, verNo: number) => `/microcredentials/${mcNo}/pre-requisites/${verNo}`,
    updateTranslation: (mcNo: string) => `/microcredentials/${mcNo}/translation`,
    microCredentialAssociations: (mcNo: string, version: number) =>
      `/microcredentials/${mcNo}/associated/micro-credentials?verNo=${version}`,
    qualificationAssociations: (mcNo: string, version: number) =>
      `/microcredentials/${mcNo}/associated/qualifications?verNo=${version}`,
    programmeAssociations: (mcNo: string, version: number) =>
      `/microcredentials/${mcNo}/associated/programmes?verNo=${version}`,
    updateAssociatedRecords: (mcNo: string, version: number) =>
      `/microcredentials/${mcNo}/associated-records?verNo=${version}`,
    standardAssociations: (mcNo: string, version: number) =>
      `/microcredentials/${mcNo}/associated/standards?verNo=${version}`
  },
  programme: {
    simpleSearch: (parameters: string) => `/programmes/search/simple?${parameters}`,
    create: `/programmes`,
    update: (progNo: string) => `/programmes/${progNo}`,
    get: (progNo: string) => `/programmes/${progNo}/all`,
    paged: (parameters: string) => `/programmes?${parameters}`,
    createDraft: (progNo: string) => `/programmes/${progNo}/draft`,
    getById: (progNo: string, id: string) => `/programmes/${progNo}?id=${id}`,
    updateStatus: (progNo: string) => `/programmes/${progNo}/status`,
    qualificationAssociations: (progNo: string, verNo: number) =>
      `/programmes/${progNo}/associated/qualifications?verNo=${verNo}`,
    standardAssociations: (programmeNumber: string, version: number) =>
      `/programmes/${programmeNumber}/associated/standards?verNo=${version}`,
    microCredentialAssociations: (programmeNumber: string, version: number) =>
      `/programmes/${programmeNumber}/associated/micro-credentials?verNo=${version}`,
    updateAssociatedRecords: (progNo: string, version: number) =>
      `/programmes/${progNo}/associated-records?verNo=${version}`
  },
  qualification: {
    simpleSearch: (parameters: string) => `/qualifications/search/simple?${parameters}`,
    get: (qualNo: string, id: string) => `/qualifications/${qualNo}?id=${id}`,
    getAll: (qualNo: string) => `/qualifications/${qualNo}/all`,
    getByNumber: (qualNo: string) => `/qualifications/${qualNo}`,
    getCreateDraft: (qualNo: string) => `/qualifications/${qualNo}/draft`,
    paged: (parameters: string) => `/qualifications?${parameters}`,
    getVersions: (parameters: string) => `/qualifications/versions?${parameters}`,
    update: (qualNo: string) => `/qualifications/${qualNo}`,
    createDraft: (qualNo: string) => `/qualifications/${qualNo}/draft`,
    updateStatus: (qualNo: string) => `/qualifications/${qualNo}/status`,
    upsertTranslation: (qualNo: string) => `/qualifications/${qualNo}/translation`,
    getTranslation: (qualNo: string, verNo: number) => `/qualifications/${qualNo}/translation/${verNo}`,
    programmeAssociations: (qualNo: string, verNo: number) =>
      `/qualifications/${qualNo}/associated/programmes?verNo=${verNo}`,
    getTransitional: (qualNo: string, verNo: number) => `/qualifications/${qualNo}/transitional?verNo=${verNo}`,
    updateTransitional: (qualNo: string) => `/qualifications/${qualNo}/transitional`,
    bulkUpdate: (isTrialRun: boolean) => `/qualifications/bulk-upload?isTrialRun=${isTrialRun}`,
    updateFunding: (qualNo: string) => `/qualifications/${qualNo}/funding`,
    standardAssociations: (qualificationNumber: string, version: number) =>
      `/qualifications/${qualificationNumber}/associated/standards?verNo=${version}`,
    microCredentialAssociations: (qualificationNumber: string, version: number) =>
      `/qualifications/${qualificationNumber}/associated/micro-credentials?verNo=${version}`,
    logos: (qualNo: string) => `/qualifications/${qualNo}/logo`
  },
  standard: {
    simpleSearch: (parameters: string) => `/standards/search/simple?${parameters}`,
    get: (recordNo: string) => `/standards/${recordNo}/all`,
    getStandards: (parameters: string) => `/standards?${parameters}`,
    maxVersion: (recordNo: string) => `/standards/${recordNo}/versions/max`,
    create: `/standards`,
    createDraft: (recordNo: string) => `/standards/${recordNo}/draft`,
    update: (recordNo: string) => `/standards/${recordNo}`,
    updateStatus: (recordNo: string) => `/standards/${recordNo}/status`,
    updateDates: (recordNo: string) => `/standards/${recordNo}/expiring`,
    getCreateDraft: (recordNo: string) => `/standards/${recordNo}/draft`,
    editAssessment: (recordNo: string, verNo: number) => `/standards/${recordNo}/verno/${verNo}/assessment`,
    standardsForStandardGroup: (stdGroupId: string) => `/standardgroups/${stdGroupId}/associated/standards`,
    qualificationAssociations: (standardNumber: string, version: number) =>
      `/standards/${standardNumber}/associated/qualifications?verNo=${version}`,
    doesConsentExistForStandard: (stdNo: string, moeNumber: string) => `/standards/${stdNo}/${moeNumber}/consentvalid`,
    programmeAssociations: (standardNumber: string, version: number) =>
      `/standards/${standardNumber}/associated/programmes?verNo=${version}`,
    microCredentialAssociations: (standardNumber: string, version: number) =>
      `/standards/${standardNumber}/associated/microcredentials?verNo=${version}`,
    cmrAssociations: (standardNumber: string, version: number, cmrNoToIgnore?: string) =>
      `/standards/${standardNumber}/associated/cmrs?verNo=${version}&cmrNoToIgnore=${cmrNoToIgnore}`,
    paged: (parameters: string) => `/standards?${parameters}`
  },
  standardGroup: {
    get: (recordNo: string, type: number) => `/standardgroups/${recordNo}?type=${type}`,
    getTree: () => `/standardgroups/tree`,
    create: `/standardgroups`,
    update: (recordNo: string) => `/standardgroups/${recordNo}`,
    setAssociatedStandards: (id: string, type: StandardGroupTypeEnum) =>
      `/standardgroups/${id}/associated/standards?type=${type}`,
    setAssociatedClassifications: (id: string, type: StandardGroupTypeEnum) =>
      `/standardgroups/${id}/associated/classifications?type=${type}`,
    addRecordViewHistory: (recordNo: string) => `/standardgroups/${recordNo}/visit`,
    standardGroupsForStandard: (stdNo: string) => `/standards/${stdNo}/associated/standardgroups`,
    standardGroupsForClassification: (internalId: string) => `/classifications/${internalId}/associated/standardgroups`,
    classificationAssociations: (stdNo: string) => `/standardgroups/${stdNo}/associated/classifications`
  },
  note: {
    get: (recordNo: string, controllerName: string) => `/${controllerName}/${recordNo}/notes`
  },
  consistencyReview: {
    getAll: (qualNo: string) => `/qualifications/${qualNo}/consistencyreview`,
    update: (qualNo: string) => `/qualifications/${qualNo}/consistencyreview`
  },
  organisation: {
    get: (parameters: string) => `/organisation?${parameters}`,
    caseids: (parameters: string) => `/organisation/caseids?${parameters}`,
    getDeliverySite: (moeNumber: string) => `/organisation/delivery-sites/${moeNumber}`
  },
  ruleGroups: {
    getTree: () => `/rulegroups/tree`,
    get: (qualNo: string, verNo: number) => `/qualifications/${qualNo}/rules?verNo=${verNo}`,
    put: (qualNo: string, verNo: number) => `/qualifications/${qualNo}/rules?verNo=${verNo}`,
    exists: (qualNo: string, verNo: number) => `/qualifications/${qualNo}/rules/exists?verNo=${verNo}`
  },

  statusHistory: {
    accreditation: (id: string) => `/accreditations/${id}/status`,
    consent: (ctaNo: string) => `/consents/${ctaNo}/status`,
    classification: (internalId: string, cfSystemType: number) =>
      `/classifications/${internalId}/status?cfSystemType=${cfSystemType}`,
    cmr: (cmrNo: string) => `/cmrs/${cmrNo}/status`,
    programme: (id: string) => `/programmes/${id}/status`,
    standard: (id: string) => `/standards/${id}/status`,
    qualification: (qualNo: string) => `/qualifications/${qualNo}/status?includeReference=true`,
    standardGroup: (id: string, recordType: string) =>
      `/standardgroups/${id}/associationhistory?recordType=${recordType}`,
    microCredential: (id: string) => `/microcredentials/${id}/status?includeReference=true`
  },
  recordViewHistory: {
    paged: (parameters: string) => `/recordviewhistories?${parameters}`,
    post: (recordNo: string, controllerName: string) => `/${controllerName}/${recordNo}/visit`
  },
  search: {
    adv: `/search`,
    exportCsv: `/search/export`,
    status: (recordType: string) => `/search/${recordType}/faceted/status`,
    type: (recordType: string) => `/search/${recordType}/faceted/type`,
    verStatus: (recordType: string) => `/search/${recordType}/faceted/verstatus`,
    records: (recordType: string, params: string) => `/search/${recordType}/faceted?${params}`,
    save: `/search`,
    saved: (offset: number, limit: number) => `/search/saved?offset=${offset}&limit=${limit}`,
    delete: (id: string) => `/search/saved?id=${id}`
  },
  user: {
    getUserPermissions: (parameters: string) => `/user/me/permissions?${parameters}`,
    isUserAdmin: `/user/me/isadmin`,
    isUserInGroup: (groupName: string) => `/user/me/in-group?groupName=${groupName}`
  },
  admin: {
    getLookupLists: () => `/admin/lookup-lists`,
    saveLookupLists: () => `/admin/lookup-lists`,
    getTypes: (recordType: number) => `/admin/type?recordType=${recordType}`
  },
  lookup: {
    getLookupList: (category: LookupListCategory) => `/lookup/list/${category}`
  }
};
