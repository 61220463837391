import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DateComponent } from './date/date.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PipesModule } from '@core/pipes/pipes.module';
import { TextComponent } from './text/text.component';
import { TrimInputDirective } from './trim-input.directive';
import { SelectComponent } from './select/select.component';
import { TextAreaComponent } from './text-area/text-area.component';
import { EditorModule } from '@tinymce/tinymce-angular';
import { LabelAdjTextComponent } from './label-adj-text/label-adj-text.component';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { LabelAdjSelectComponent } from './label-adj-select/label-adj-select.component';
import { DateMinSetterComponent } from './date/date-min-setter.component';
import { DirectivesModule } from '@core/directives/directives.module';
import { TextAreaBasicComponent } from './text-area-basic/text-area-basic.component';
import { LabelComponent } from './label/label.component';
import { LabelAdjDateComponent } from './label-adj-date/label-adj-date.component';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';

@NgModule({
  declarations: [
    DateComponent,
    TextComponent,
    TrimInputDirective,
    SelectComponent,
    TextAreaComponent,
    LabelAdjTextComponent,
    LabelAdjSelectComponent,
    CheckboxComponent,
    DateMinSetterComponent,
    TextAreaBasicComponent,
    LabelComponent,
    LabelAdjDateComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    PipesModule,
    EditorModule,
    DirectivesModule,
    MatOptionModule,
    MatSelectModule
  ],
  exports: [
    DateComponent,
    TextComponent,
    SelectComponent,
    TextAreaComponent,
    LabelAdjTextComponent,
    LabelAdjSelectComponent,
    CheckboxComponent,
    DateMinSetterComponent,
    TextAreaBasicComponent,
    LabelComponent,
    LabelAdjDateComponent
  ],
  providers: [
    // This doesn't appear to be needed for eager loading
    // { provide: TINYMCE_SCRIPT_SRC, useValue: 'tinymce/tinymce.min.js' }
  ]
})
export class FormComponentsModule {}
