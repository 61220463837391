import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { DateComponent } from '../date/date.component';

@Component({
  selector: 'app-label-adj-date',
  templateUrl: './label-adj-date.component.html',
  styleUrls: ['./label-adj-date.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LabelAdjDateComponent),
      multi: true
    }
  ]
})
export class LabelAdjDateComponent extends DateComponent {
  @Input() adjacentText: string;
  constructor() {
    super();
  }
}
