import { RecordTypeEnum } from '@components/constants';

export class ControllerProvider {
  static get(recordType: RecordTypeEnum): string {
    switch (recordType) {
      case RecordTypeEnum.Accreditation:
        return 'accreditations';
      case RecordTypeEnum.Qualification:
        return 'qualifications';
      case RecordTypeEnum.Programme:
        return 'programmes';
      case RecordTypeEnum.Classification:
        return 'classifications';
      case RecordTypeEnum.Standard:
        return 'standards';
      case RecordTypeEnum.MicroCredential:
        return 'microcredentials';
      case RecordTypeEnum.Consent:
        return 'consents';
      case RecordTypeEnum.CMR:
        return 'cmrs';
      default:
        throw new Error('Invalid record type');
    }
  }
}
