import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { ControlValueAccessorConnector } from '@components/form-components/control-value-accessor-connector';
import { MessageModalService } from '@components/message-modal/message-modal.service';
import { MicroCredentialService } from '@core/services/micro-credential.service';
import { GetPagedMicroCredParameters, IMicroCredential } from '@core/services/models/micro-credential/micro-credential';
import { map, Observable } from 'rxjs';
import { ITypeaheadBase } from '../typeahead-base';

@Component({
  selector: 'app-micro-credential-typeahead',
  templateUrl: './micro-credential-typeahead.component.html',
  styleUrls: ['./micro-credential-typeahead.component.scss']
})
export class MicroCredentialTypeaheadComponent
  extends ControlValueAccessorConnector
  implements OnInit, ControlValueAccessor, ITypeaheadBase
{
  @Input() mcNo: any;
  @Input() verStatus: any;
  @Input() status: any;
  @Input() ver: number;
  @Input() internalId: string;
  @Input() removeConfirmationMessage: string = '';
  @Input() hasConfirmationModal: boolean = false;
  @Output() removeEvent: EventEmitter<void> = new EventEmitter<void>();
  @Input() excludedIds: string[] = [];
  @Input() onlyLatestVersion: boolean = false;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private service: MicroCredentialService,
    private modalService: MessageModalService
  ) {
    super();
  }

  ngOnInit(): void {
    this.changeDetectorRef.detectChanges();
  }

  /**
   * Query api based on search text
   * @param term
   * @param count
   * @returns
   */
  searchFunc = (term: string, count: number): Observable<any[]> => {
    let params = new GetPagedMicroCredParameters({
      searchText: term,
      limit: count,
      offset: 0,
      ver: this.ver,
      verStatus: this.verStatus,
      status: this.status,
      onlyLatestVersion: this.onlyLatestVersion
    });
    return this.service.paged(params).pipe(
      map(response => {
        return response.items.filter(item => this.excludedIds?.indexOf(item.id!) == -1);
      })
    );
  };

  /**
   * Format the search results
   * @param x
   * @returns
   */
  formatter = (x: IMicroCredential) => `${(x.mcNo, '|', x.title)}`;

  showConfirmationModal(): void {
    this.modalService.open(this.removeConfirmationMessage).result.then(confirmed => {
      if (confirmed) this.removeEvent.emit();
    });
  }
}
