import { NgModule } from '@angular/core';
import { CustomDatePipe } from './custom-date.pipe';
import { CustomDateTimePipe } from './custom-date-time.pipe';
import { DefaultValuePipe } from './default-value.pipe';
import {
  FormControlShouldShowValidationForError,
  FormControlShouldShowValidationPipe,
  FormControlShouldShowWarning
} from './form-control-should-show-validation.pipe';
import { FormControlPipe } from './form-control.pipe';
import { CasingPipe } from './casing.pipe';
import { SplitPascalCasePipe } from './split-pascal-case.pipe';
import { FilterPipe } from './filter.pipe';
import { ReplaceSpaceWithDash } from './replace-space-with-dash.pipe';
import { RecordNamePipe } from './record-name.pipe';

@NgModule({
  declarations: [
    CustomDatePipe,
    CustomDateTimePipe,
    DefaultValuePipe,
    FormControlShouldShowValidationPipe,
    FormControlShouldShowValidationForError,
    FormControlShouldShowWarning,
    FormControlPipe,
    CasingPipe,
    SplitPascalCasePipe,
    FilterPipe,
    ReplaceSpaceWithDash,
    RecordNamePipe
  ],
  exports: [
    CustomDatePipe,
    DefaultValuePipe,
    FormControlShouldShowValidationPipe,
    FormControlShouldShowValidationForError,
    FormControlShouldShowWarning,
    FormControlPipe,
    CustomDateTimePipe,
    CasingPipe,
    SplitPascalCasePipe,
    FilterPipe,
    ReplaceSpaceWithDash,
    RecordNamePipe
  ],
  imports: []
})
export class PipesModule {}
