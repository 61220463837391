import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, UntypedFormControl } from '@angular/forms';
import { Lookup } from '@core/services/models/shared/lookup';
import { FieldComponentTypeEnum } from '@views/create/create-programme/constants';

@Component({
  selector: 'app-range-input',
  templateUrl: './range-input.component.html',
  styleUrls: ['./range-input.component.scss']
})
export class RangeInputComponent {
  @Input() manageForm: FormGroup;
  @Input() fromLabel: string;
  @Input() toLabel: string;

  @Input() fromFormControlName: string;
  @Input() toFormControlName: string;

  @Input() adjacentTextFrom: string;
  @Input() adjacentTextTo: string;

  @Input() lookupOptionFrom: Lookup<any>[] = [];
  @Input() lookupOptionTo: Lookup<any>[] = [];

  @Input() fieldComponentType: string;
  @Input() label: string;
  @Input() validationProperty: string;
  @Input() step: string = '0.1';
  @Input() attributeId = 'default';

  get isFormTouched(): boolean | undefined {
    return (
      this.manageForm.get(this.fromFormControlName)?.touched || this.manageForm.get(this.toFormControlName)?.touched
    );
  }

  public readonly FieldComponentTypeEnum = FieldComponentTypeEnum;

  creditRangeError: boolean;

  get controlFrom(): UntypedFormControl {
    return this.manageForm?.get(this.fromFormControlName) as UntypedFormControl;
  }

  get controlTo(): UntypedFormControl {
    return this.manageForm?.get(this.toFormControlName) as UntypedFormControl;
  }

  get controlFromErrorMessageText(): string {
    return this.manageForm?.errors?.[this.validationProperty]?.fromValidationMessage;
  }

  get controlToErrorMessageText(): string {
    return this.manageForm?.errors?.[this.validationProperty]?.toValidationMessage;
  }
}
