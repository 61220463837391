import { Injectable } from '@angular/core';
import { ButtonLabels } from '@core/constants/button-labels';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { MessageModalComponent } from './message-modal.component';

@Injectable({
  providedIn: 'root'
})
export class MessageModalService {
  constructor(private modalService: NgbModal) {}

  open(message: string, includeCancelButton: boolean = true, buttons: string[] = [ButtonLabels.Ok]): NgbModalRef {
    const modalRef = this.modalService.open(MessageModalComponent, {
      backdrop: 'static',
      centered: true,
      windowClass: 'qcr-modal-md',
      ariaLabelledBy: 'modal-title'
    });
    modalRef.componentInstance.message = message;
    modalRef.componentInstance.modalRef = modalRef;
    modalRef.componentInstance.buttons = buttons;
    modalRef.componentInstance.includeCancelButton = includeCancelButton;
    return modalRef;
  }

  deletePrompt = (message: string, onConfirm: () => void) => {
    const modalRef = this.open(message);

    modalRef.result.then(
      data => {
        if (data == ButtonLabels.Ok) {
          onConfirm();
        }
      },
      reason => {
        modalRef.close();
      }
    );
  };
}
