import { Component, Input, TemplateRef } from '@angular/core';

@Component({
  selector: 'app-toggle-radio-element',
  template: ''
})
/**
 * This component does not have any UI, all it does is providing component children to the component
 * ToggleRadioGroupComponent for an easy way to provide the needed configuration via a template
 */
export class ToggleRadioElementComponent {
  // The test to be displayed beside the radio button
  @Input() title: string;
  // The form field that will be set when the radio button is selected
  @Input() formFieldName: string;
  // The template that will be shown when the radio button is selected
  @Input() templateReference: TemplateRef<any>;
}
