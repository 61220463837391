// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  gap: 5px;
}

input {
  flex: none;
}

legend {
  font-size: 1rem;
}

.label-wrap {
  text-wrap: wrap;
  width: 180px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImNoZWNrYm94LmNvbXBvbmVudC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0UsUUFBQTtBQUNGOztBQUVBO0VBQ0UsVUFBQTtBQUNGOztBQUVBO0VBQ0UsZUFBQTtBQUNGOztBQUVBO0VBQ0UsZUFBQTtFQUNBLFlBQUE7QUFDRiIsImZpbGUiOiJjaGVja2JveC5jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbIjpob3N0IHtcbiAgZ2FwOiA1cHg7IC8vIGlmIGZsZXggZGlzcGxheSBhZGQgZ2FwXG59XG5cbmlucHV0IHtcbiAgZmxleDogbm9uZTtcbn1cblxubGVnZW5kIHtcbiAgZm9udC1zaXplOiAxcmVtO1xufVxuXG4ubGFiZWwtd3JhcCB7XG4gIHRleHQtd3JhcDogd3JhcDtcbiAgd2lkdGg6IDE4MHB4O1xufVxuIl19 */`, "",{"version":3,"sources":["webpack://./src/app/components/form-components/checkbox/checkbox.component.scss"],"names":[],"mappings":"AAAA;EACE,QAAA;AACF;;AAEA;EACE,UAAA;AACF;;AAEA;EACE,eAAA;AACF;;AAEA;EACE,eAAA;EACA,YAAA;AACF;AACA,okBAAokB","sourcesContent":[":host {\n  gap: 5px; // if flex display add gap\n}\n\ninput {\n  flex: none;\n}\n\nlegend {\n  font-size: 1rem;\n}\n\n.label-wrap {\n  text-wrap: wrap;\n  width: 180px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
