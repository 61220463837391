import { NgControl } from '@angular/forms';
import { Directive, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

/**
 * Reset input to null if input value is empty.
 */
@Directive({
  selector: 'input[appNullValue]'
})
export class NullDefaultValueDirective implements OnInit, OnDestroy {
  value$!: Subscription | undefined;
  constructor(private control: NgControl) {}

  ngOnInit(): void {
    this.value$ = this.control.valueChanges?.subscribe(value => {
      if (value === '') {
        this.control.reset();
        this.control.control?.markAsDirty();
      }
    });
  }

  ngOnDestroy(): void {
    this.value$?.unsubscribe();
  }
}
