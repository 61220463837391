import { Lookup } from '@core/services/models/shared/lookup';

export enum MonitoringCycleEnum {
  OneYear = 7401
}

export enum AccreditationTypeEnum {
  Programme = 7101,
  MicroCredential = 7102
}

export const AccreditationTypeLookup: Lookup<number>[] = [
  { text: 'Programme', value: AccreditationTypeEnum.Programme },
  { text: 'Micro-credential', value: AccreditationTypeEnum.MicroCredential }
];

export const getAccreditationStatusString = (status: AccreditationStatusEnum): string => {
  switch (status) {
    case AccreditationStatusEnum.NotAccredited:
      return AccreditationStatusStringEnum.NotAccredited;
    case AccreditationStatusEnum.Draft:
      return AccreditationStatusStringEnum.Draft;
    case AccreditationStatusEnum.Accredited:
      return AccreditationStatusStringEnum.Accredited;
    case AccreditationStatusEnum.Removed:
      return AccreditationStatusStringEnum.Removed;
    case AccreditationStatusEnum.Withdrawn:
      return AccreditationStatusStringEnum.Withdrawn;
    case AccreditationStatusEnum.Lapsed:
      return AccreditationStatusStringEnum.Lapsed;
    case AccreditationStatusEnum.Exempt:
      return AccreditationStatusStringEnum.Exempt;
  }
};

export enum AccreditationStatusStringEnum {
  Draft = 'Draft',
  Accredited = 'Accredited',
  NotAccredited = 'Not Accredited',
  Removed = 'Removed',
  Withdrawn = 'Withdrawn',
  Lapsed = 'Lapsed',
  Exempt = 'Exempt'
}

export enum AccreditationStatusEnum {
  Draft = 7001,
  Accredited = 7002,
  NotAccredited = 7003,
  Removed = 7005,
  Withdrawn = 7006,
  Lapsed = 7007,
  Exempt = 7009
}

export enum AccreditationFundingStatusEnum {
  Approved = 7501,
  NotApproved = 7502,
  ApprovalWithdrawn = 7503
}

export const AccreditationFundingStatusLookup: Lookup<number>[] = [
  { text: 'Approved', value: AccreditationFundingStatusEnum.Approved },
  { text: 'Not Approved', value: AccreditationFundingStatusEnum.NotApproved },
  { text: 'Approval Withdrawn', value: AccreditationFundingStatusEnum.ApprovalWithdrawn }
];

export enum AccreditationStudentLoanBorrowingEnum {
  Approved = 7601,
  NotApproved = 7602,
  ApprovalWithdrawn = 7603
}

export const AccreditationStudentLoanBorrowingLookup: Lookup<number>[] = [
  { text: 'Approved', value: AccreditationStudentLoanBorrowingEnum.Approved },
  { text: 'Not Approved', value: AccreditationStudentLoanBorrowingEnum.NotApproved },
  { text: 'Approval Withdrawn', value: AccreditationStudentLoanBorrowingEnum.ApprovalWithdrawn }
];

export enum AccreditationEligStudentLoanAllowanceEnum {
  Approved = 7701,
  NotApproved = 7702,
  ApprovalWithdrawn = 7703
}

export const AccreditationEligStudentLoanAllowanceLookup: Lookup<number>[] = [
  { text: 'Approved', value: AccreditationEligStudentLoanAllowanceEnum.Approved },
  { text: 'Not Approved', value: AccreditationEligStudentLoanAllowanceEnum.NotApproved },
  { text: 'Approval Withdrawn', value: AccreditationEligStudentLoanAllowanceEnum.ApprovalWithdrawn }
];

export enum MonitoringStatusEnum {
  AnnualProgrammeEvaluation = 7301,
  MonitorWithIndependantEvaluator = 7302,
  MonitorOnly = 7303,
  NotRequired = 7304,
  Unknown = 7305
}
