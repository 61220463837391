import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ControlValueAccessorConnector } from '../control-value-accessor-connector';

@Component({
  selector: 'app-date',
  templateUrl: './date.component.html',
  styleUrls: ['./date.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DateComponent),
      multi: true
    }
  ]
})
export class DateComponent extends ControlValueAccessorConnector implements ControlValueAccessor {
  _min: string;
  @Input() set min(val: string) {
    this._min = val;
  }

  get min() {
    return this._min;
  }
  @Input() max: string = this.toPartedDateString(new Date('9999-12-31'));
  @Input() placeholder: string = '';
  @Input() hideWarning: boolean = false;
  @Input() dateMininumErrorMessage: string = 'You must select a date in the future.';
  @Input() attributeId = this.formControlName;
  @Input() isEditMode: boolean = true;

  /**
   * The value property is used to set the value using a one way binding to display the value in read only mode.
   * If this property is set you should not include a formGroup
   *
   * @type {(string | null)}
   * @memberof DateComponent
   */
  @Input() value: string | null = null;

  isWarningMessageShown: boolean = true;

  constructor() {
    super();
  }

  closeWarningMessage(): void {
    this.isWarningMessageShown = false;
  }

  toPartedDateString(date: Date): string {
    return [date.getFullYear(), ('0' + (date.getMonth() + 1)).slice(-2), ('0' + date.getDate()).slice(-2)].join('-');
  }
}
