import { GenericForm } from '@core/forms/form-group-extensions';
import { DepthLevel as CfTypeDepthLevel } from '@core/services/models/classification/depth-level';

export enum ClassificationStatus {
  Draft = 4001,
  Registered = 4002,
  Expiring = 4003,
  Expired = 4004
}

export class ClassificationStatusString {
  public static get(status: ClassificationStatus): string {
    switch (status) {
      case ClassificationStatus.Draft:
        return 'Draft';
      case ClassificationStatus.Registered:
        return 'Registered';
      case ClassificationStatus.Expiring:
        return 'Expiring';
      case ClassificationStatus.Expired:
        return 'Expired';
    }
  }
}

export enum CfSystemType {
  NZSCED = 4601,
  SCUNQ = 4602
}

export enum DepthLevelEnum {
  Root = 4400,
  Depth1 = 4401,
  Depth2 = 4402,
  Depth3 = 4403
}

export enum NoRootDepthLevelEnum {
  Depth1 = DepthLevelEnum.Depth1,
  Depth2 = DepthLevelEnum.Depth2,
  Depth3 = DepthLevelEnum.Depth3
}

export class Cftype {
  primaryName: string | null = null;
  qualityAssuranceBody: string | null = null;
  depthLevels: CfTypeDepthLevel[];
}

export type CftypeForm = GenericForm<Cftype>;

export enum ErrorDictionary {
  DuplicateNzscedClassification = 'Classification external Id is not unique.'
}
