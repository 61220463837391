import { RecordTypeEnum } from '@components/constants';
import { IBaseModel } from './base/base.model';
import { GetClassification } from './classification/get-classification.model';

export class RecordBrokerModel {
  constructor(init?: Partial<RecordBrokerModel>) {
    Object.assign(this, init);
  }

  recordType: RecordTypeEnum;
  records: object[];
  versions: VersionMap[];
}

export class VersionMap {
  constructor(init?: Partial<VersionMap>) {
    Object.assign(this, init);
  }

  verNo: number;
  recordId: string;
}

export class RecordBrokerVersionModel {
  constructor(init?: Partial<RecordBrokerVersionModel>) {
    Object.assign(this, init);
  }

  recordType: RecordTypeEnum;
  record: object;
  verNo: number;
  recordId: string;
  recordNo: string;
}

export class VersionMapFactory {
  static fromRecords<T extends IBaseModel>(
    records: T[],
    getId: (record: T) => string | undefined | null
  ): VersionMap[] {
    return records.map(x => {
      return new VersionMap({
        verNo: x.verNo ?? 1,
        recordId: getId(x)!
      });
    });
  }

  static fromClassification(classifications: GetClassification[]): VersionMap[] {
    return classifications.map(x => {
      return new VersionMap({
        //This is one because there aren't versions for classifications.  There is one version version 1
        verNo: 1,
        recordId: x.id!
      });
    });
  }
}
