import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RecordHeaderComponent } from './record-header.component';
import { PipesModule } from '@core/pipes/pipes.module';
import { RecordHeaderContentComponent } from './record-header-content/record-header-content.component';
import { BubbleTimelineModule } from '@components/bubble-timeline/bubble-timeline.module';

@NgModule({
  imports: [CommonModule, PipesModule, BubbleTimelineModule],
  declarations: [RecordHeaderComponent, RecordHeaderContentComponent],
  exports: [RecordHeaderComponent, RecordHeaderContentComponent]
})
export class RecordHeaderModule {}
