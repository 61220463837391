import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RichWarningMessageComponent } from './rich-warning-message.component';

@NgModule({
  declarations: [RichWarningMessageComponent],
  imports: [CommonModule],
  exports: [RichWarningMessageComponent]
})
export class RichWarningMessageModule {}
