import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-full-screen-modal',
  templateUrl: './full-screen-modal.component.html',
  styleUrls: ['./full-screen-modal.component.scss']
})
export class FullScreenModalComponent {
  @Input() modalRef: NgbModalRef;
  @Input() headerTitle: string;
  @Input() subTitle: string;
  @Input() version: number;
  @Input() hasCustomCloseEvent: boolean = false;
  @Input() fullwidth: boolean = false;
  @Input() saveText: string = 'Save';
  @Input() disableSave?: boolean;
  @Input() displayFooter: boolean = false;
  @Input() showResetButton: boolean = false;
  @Input() newHeader: boolean = false;

  recordType: string = this.router.getRecordType()!;
  @Input() recordNumber: string;

  @Output() closeEvent: EventEmitter<void> = new EventEmitter();
  @Output() saveClickedEvent: EventEmitter<void> = new EventEmitter();
  @Output() closeClickedEvent: EventEmitter<void> = new EventEmitter();
  @Output() saveAndCloseClickedEvent: EventEmitter<void> = new EventEmitter();

  @Output() resetClickedEvent: EventEmitter<void> = new EventEmitter();
  @Input() noMargin: boolean = false;

  get showRecordNumber(): boolean {
    return !(this.recordType === 'accreditation' || this.recordType === 'consent');
  }

  constructor(
    private modalService: NgbModal,
    private router: Router
  ) {}

  onClose(): void {
    if (this.hasCustomCloseEvent) {
      this.closeEvent.emit();
      return;
    }
    if (this.modalRef) {
      this.modalRef.close();
    } else {
      this.modalService.dismissAll();
    }
  }

  onResetClicked(): void {
    this.resetClickedEvent.emit();
  }

  onCloseClicked(): void {
    this.closeClickedEvent.emit();
    this.onClose();
  }

  onSaveClicked(): void {
    this.saveClickedEvent.emit();
  }

  onSaveAndCloseClicked(): void {
    this.saveAndCloseClickedEvent.emit();
  }
}
