import { Component, Input } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-error-modal',
  templateUrl: './error-modal.component.html',
  styleUrls: ['./error-modal.component.scss']
})
export class ErrorModalComponent {
  @Input() message: string;
  @Input() model: any;
  @Input() modalRef: NgbModalRef | null;

  constructor(private modalService: NgbModal) {}

  onClickOk() {
    if (this.modalRef) {
      this.modalRef?.close();
    } else {
      this.modalService.dismissAll();
    }
  }
}
