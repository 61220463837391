import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-bubble-timeline',
  templateUrl: './bubble-timeline.component.html',
  styleUrls: ['./bubble-timeline.component.scss']
})
export class BubbleTimelineComponent {
  arr: BubbleTimeLineModel[];

  @Input() set params(input: BubbleTimeLineModel[]) {
    this.columnStyleDefinition = `repeat(${input.length - 1}, minmax(0px, 1fr) minmax(10px, 10px)) minmax(10px, 10px)`;
    this.textRowDefinition = `repeat(${input.length - 1}, 1fr) minmax(10px, 10px)`;
    this.arr = input;
  }

  columnStyleDefinition: string;
  textRowDefinition: string;
}

export class BubbleTimeLineModel {
  name: string;
  complete: boolean;
}
