import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { apiRoutes } from '../constants/api-routes.constants';
import { map, Observable } from 'rxjs';
import {
  IPatchStandardGroupAssociations,
  IStandardGroup,
  StandardGroupTypeEnum
} from './models/standard-groups/standard-groups';
import { FolderTreeRootNode, StandardGroupTreeDto } from '@components/folder-tree/folder-tree/FolderTreeRootNode';
import { folderTreeArrayMapper, genericArrayReMapper } from '@core/helpers/utils';
import { ActivatedRoute } from '@angular/router';
import { Classification, Standard } from './models/standard/standard';

@Injectable({
  providedIn: 'root'
})
export class StandardGroupsService {
  /**
   * Get the type of standard group from the route
   */
  get queryType(): StandardGroupTypeEnum | undefined {
    let queryParam = this.route.snapshot.queryParams['type'] as StandardGroupTypeEnum;
    if (queryParam) {
      return +queryParam;
    }
    return undefined;
  }

  /**
   * Get the query type from the route needed to navigate to a standard group
   * @param standardGroup
   * @returns
   */
  getRouterQueryParams(standardGroup: StandardGroupTreeDto) {
    return { queryParams: { type: standardGroup.type } };
  }

  get(recordNo: string, type: StandardGroupTypeEnum): Observable<IStandardGroup[]> {
    return this.http.get<IStandardGroup[]>(apiRoutes.standardGroup.get(recordNo, type));
  }

  getTree(): Observable<FolderTreeRootNode<StandardGroupTreeDto>[]> {
    return this.http
      .get<FolderTreeRootNode<StandardGroupTreeDto>[]>(apiRoutes.standardGroup.getTree())
      .pipe(map(folderTreeArrayMapper<StandardGroupTreeDto>(StandardGroupTreeDto)));
  }

  create(model: IStandardGroup): Observable<IStandardGroup> {
    return this.http.post<IStandardGroup>(apiRoutes.standardGroup.create, model);
  }

  update(model: IStandardGroup): Observable<IStandardGroup> {
    return this.http.put<IStandardGroup>(apiRoutes.standardGroup.update(model.id!), model);
  }

  updateAssociatedStandards(model: IPatchStandardGroupAssociations) {
    return this.http.patch<IStandardGroup>(
      apiRoutes.standardGroup.setAssociatedStandards(model.id!, model.type),
      model
    );
  }

  updateAssociatedClassifications(model: IPatchStandardGroupAssociations) {
    return this.http.patch<IStandardGroup>(
      apiRoutes.standardGroup.setAssociatedClassifications(model.id!, model.type),
      model
    );
  }

  addRecordViewHistory(model: IStandardGroup): Observable<IStandardGroup> {
    return this.http.post<IStandardGroup>(apiRoutes.standardGroup.addRecordViewHistory(model.id!), model);
  }

  getAssociatedClassificationRecords(recordNo: string): Observable<Classification[]> {
    return this.http.get<Classification[]>(apiRoutes.standardGroup.classificationAssociations(recordNo)).pipe(
      map(data => {
        return data.map(item => new Classification(item));
      })
    );
  }

  getAssociatedStandardRecords(stdGroupId: string): Observable<Standard[]> {
    return this.http.get<Standard[]>(apiRoutes.standard.standardsForStandardGroup(stdGroupId)).pipe(
      map(data => {
        return data.map(item => new Standard(item));
      })
    );
  }

  constructor(
    private http: HttpClient,
    private route: ActivatedRoute
  ) {}
}
