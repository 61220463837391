import { allEnums } from '@components/paged-records-select/PagedRecordsBase.component';
import { QueryBase } from '../base/queryBase.model';

export class PagingParameters extends QueryBase {
  limit: number = 100;
  offset: number = 0;
  orderByField: string | undefined;
  orderByDirection: PagingParametersOrderByDirectionEnum = PagingParametersOrderByDirectionEnum.Ascending;
}

export enum PagingParametersOrderByDirectionEnum {
  Ascending,
  Descending
}

export class SearchTextPagingParameters extends PagingParameters {
  searchText: string;
  searchOnlyByNumber: boolean = false;
  verStatus: allEnums;
  onlyLatestVersion: boolean = false;
  getAukahaIds: boolean = false;
  /* Allows the properties to be set dynamically using property names */
  [key: string]: any;
}

export class EdOrgSearchPagingParameters extends SearchTextPagingParameters {
  roles: EdOrgRoleEnum[] | null;
}

export enum EdOrgRoleEnum {
  Provider = 3,
  AssessmentCentre = 4,
  SSB = 7,
  QualityAssuranceBody = 12,
  QualificationDeveloper = 13,
  QESInstitute = 14,
  AustralianTertiaryEntranceVerificationProvider = 21,
  NewZealandTertiaryEntranceVerificationProvider = 22,
  CourseOwner = 24,
  NZQACustomer = 25,
  NZQAVendor = 26,
  ProfessionalBody = 29,
  RegisteringBody = 30,
  QRSInstitution = 200,
  ImmigrationAgent = 201,
  SFPSupplier = 224,
  SFPBulkAttestor = 225,
  MātaurangaMāoriProgrammeProvider = 250,
  MāoriProvider = 423
}
