import { Component, Input } from '@angular/core';
import { ButtonLabels } from '@core/constants/button-labels';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-message-modal',
  templateUrl: './message-modal.component.html',
  styleUrls: ['./message-modal.component.scss']
})
export class MessageModalComponent {
  @Input() message: string;
  @Input() model: any;
  @Input() modalRef: NgbModalRef | null;
  @Input() buttons: string[] = [ButtonLabels.Ok];
  @Input() includeCancelButton: boolean = true;

  constructor(private modalService: NgbModal) {}

  onButtonClick(button: string) {
    if (this.modalRef) {
      this.modalRef?.close(button);
    } else {
      this.modalService.dismissAll();
    }
  }

  onClickCancel() {
    if (this.modalRef) {
      this.modalRef?.close(null);
    } else {
      this.modalService.dismissAll();
    }
  }
}
