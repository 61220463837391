import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { apiRoutes } from '../constants/api-routes.constants';
import { GetPagedEdOrgDto } from './models/organisation/GetPagedEdOrgDto';
import { GetPagedCaseIdDto } from './models/organisation/GetPagedCaseIdDto';
import {
  EdOrgRoleEnum,
  EdOrgSearchPagingParameters,
  SearchTextPagingParameters
} from './models/paging/paging-parameters.model';
import { GetDeliverySiteDto } from './models/organisation/get-delivery-site-dto';
import { Lookup } from './models/shared/lookup';

@Injectable({
  providedIn: 'root'
})
export class OrganisationService {
  constructor(private http: HttpClient) {}

  select(searchText: string, count: number, roles: EdOrgRoleEnum[] | null): Observable<GetPagedEdOrgDto> {
    let params = new EdOrgSearchPagingParameters();
    params.searchText = searchText;
    params.limit = count;
    params.offset = 0;
    params.roles = roles;
    return this.http.get<GetPagedEdOrgDto>(apiRoutes.organisation.get(params.toQueryString()));
  }

  caseids(searchText: string, count: number, getAukahaIds: boolean = false): Observable<GetPagedCaseIdDto> {
    let params = new SearchTextPagingParameters();
    params.searchText = searchText;
    params.limit = count;
    params.offset = 0;
    params.getAukahaIds = getAukahaIds;
    return this.http.get<GetPagedCaseIdDto>(apiRoutes.organisation.caseids(params.toQueryString()));
  }

  getDeliverySites(moeNumber: string): Observable<GetDeliverySiteDto[]> {
    return this.http.get<GetDeliverySiteDto[]>(apiRoutes.organisation.getDeliverySite(moeNumber));
  }

  mapDeliverySitesOption(deliverySites: GetDeliverySiteDto[]): Lookup<string>[] {
    return deliverySites.map(deliverySite => {
      return {
        value: deliverySite.code,
        text: deliverySite.name
      };
    });
  }
}
