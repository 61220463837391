import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ContextMenuService } from '@components/context-menu/context-menu.service';

@Component({
  selector: 'app-context-menu-item',
  templateUrl: './context-menu-item.component.html',
  styleUrls: ['./context-menu-item.component.scss']
})
export class ContextMenuItemComponent {
  @Output() itemClick: EventEmitter<any> = new EventEmitter<any>();
  @Input() clickArgs: any;
  @Input() disabled: boolean;
  @Input() ariaLabel: string;
  constructor(private service: ContextMenuService) {}

  onClick() {
    if (!this.disabled) {
      this.itemClick.next(this.clickArgs);
      this.service.hideMenu.next();
    }
  }
}
