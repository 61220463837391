import { Component, OnDestroy, OnInit } from '@angular/core';
import { RecordTypeEnum } from '@components/constants';
import { SplitPascalCasePipe } from '@core/pipes/split-pascal-case.pipe';
import { AdminService } from '@core/services/admin.service';
import { DepthLevelService } from '@core/services/depth-level.service';
import { IAccreditation } from '@core/services/models/accreditation/accreditation.model';
import { LookupListCategory } from '@core/services/models/admin/lookupList.model';
import { GetClassification } from '@core/services/models/classification/get-classification.model';
import { ICmr } from '@core/services/models/cmr/cmr';
import { IConsentToAssess } from '@core/services/models/consent-to-assess/consent-to-assess';
import { IMicroCredential } from '@core/services/models/micro-credential/micro-credential';
import { IStrictProgramme } from '@core/services/models/programme/programme';
import { IQualification } from '@core/services/models/qualification/qualification';
import { EnumToLookup } from '@core/services/models/shared/lookup';
import { Standard } from '@core/services/models/standard/standard';
import { QualificationService } from '@core/services/qualification.service';
import { RecordBrokerService } from '@core/services/record-broker.service';
import { AccreditationTypeEnum } from '@views/accreditation/constants';
import { CfSystemType } from '@views/classification/constants';
import { ConsentTypeEnum } from '@views/consent-to-assess/constants';
import { Subscription, take } from 'rxjs';
import { RecordDetails } from './record-detail.model';
import { RecordHeaderTabService } from './record-header.service';

@Component({
  selector: 'app-record-header',
  templateUrl: './record-header.component.html',
  styleUrls: ['./record-header.component.scss']
})
export class RecordHeaderComponent implements OnInit, OnDestroy {
  recordDetails: RecordDetails | undefined;
  RecordTypeEnum = RecordTypeEnum;

  $updateHeader: Subscription;

  constructor(
    public recordHeaderTabService: RecordHeaderTabService,
    private recordBrokerService: RecordBrokerService,
    private qualService: QualificationService,
    private adminService: AdminService
  ) {}

  ngOnInit(): void {
    let headerDetails: RecordDetails | undefined;
    this.$updateHeader = this.recordBrokerService.versionUpdated.subscribe(x => {
      if (!x) return;
      switch (x.recordType) {
        case RecordTypeEnum.Accreditation:
          let accreditation = x.record as IAccreditation;
          headerDetails = new RecordDetails({
            type: RecordTypeEnum.Accreditation,
            recordTitle: accreditation!.title,
            accreditationStatus: accreditation!.status!,
            title: accreditation!.title!,
            accType: EnumToLookup.toName(AccreditationTypeEnum, accreditation!.type!),
            developer: accreditation?.organisation?.organisationName
          });
          break;
        case RecordTypeEnum.Consent:
          let consent = x.record as IConsentToAssess;
          let splitPascalCasePipe = new SplitPascalCasePipe();
          let title = `${splitPascalCasePipe.transform(ConsentTypeEnum[consent.type!])} for ${consent.organisation
            ?.organisationName!}`;
          headerDetails = new RecordDetails({
            type: RecordTypeEnum.Consent,
            consentRecordTypeTitle: 'Consent to assess',
            recordTitle: title,
            consentStatus: consent!.status!,
            title: 'test',
            moeNumber: consent!.organisation?.moeNumber!
          });
          break;
        case RecordTypeEnum.Classification:
          let classification = x.record as GetClassification;
          headerDetails = new RecordDetails({
            type: RecordTypeEnum.Classification,
            recordTitle: classification!.primaryName,
            classificationStatus: classification!.status!,
            title: classification!.primaryName,
            cfSystemType: EnumToLookup.toName(CfSystemType, classification!.cfSystemType!),
            depthLevel: DepthLevelService.getDepthLevelDescription(
              classification.cfSystemType!,
              classification.depthLevel!
            ),
            parent: classification.depthHierarchy.depth2Name ?? classification.depthHierarchy.depth1Name
          });
          break;
        case RecordTypeEnum.Programme:
          let programme = x.record as IStrictProgramme;
          headerDetails = new RecordDetails({
            type: RecordTypeEnum.Programme,
            recordTitle: programme!.title,
            progStatus: programme.status,
            title: programme!.title,
            progNo: programme!.progNo,
            owner: programme.owner!
          });
          this.adminService
            .getLookupList(LookupListCategory.ProgrammeType)
            .pipe(take(1))
            .subscribe(types => {
              headerDetails!.progType = types.find(t => t.value === programme!.type!)?.text;
            });
          break;
        case RecordTypeEnum.MicroCredential:
          let mc = x.record as IMicroCredential;
          headerDetails = new RecordDetails({
            type: RecordTypeEnum.MicroCredential,
            recordTitle: mc!.title,
            mcStatus: mc!.status!,
            mcTitle: mc!.title,
            mcNo: mc!.mcNo,
            mcDev: mc.mcDeveloper!
          });
          break;
        case RecordTypeEnum.CMR:
          let cmr = x.record as ICmr;
          headerDetails = new RecordDetails({
            type: RecordTypeEnum.CMR,
            recordTitle: cmr!.title,
            cmrStatus: cmr!.status!,
            cmrTitle: cmr!.title,
            cmrNo: cmr!.cmrNo,
            cmrDev: cmr.ssb!
          });
          break;
        case RecordTypeEnum.Qualification:
          let qual = x.record as IQualification;
          headerDetails = new RecordDetails({
            qualificationCategory: this.qualService.getQualificationCategories().find(t => t.value == qual.qualCategory)
              ?.text,
            qualificationNumber: qual.qualNo,
            qualificationStatus: qual.status,
            qualificationType: this.qualService.getQualificationTypes().find(t => t.value == qual.type)?.text,
            type: RecordTypeEnum.Qualification,
            recordTitle: qual.teHonooTeKahurangiQAProcess == true ? qual.teReoMaoriTitle : qual.englishTitle,
            developer: qual.qualDeveloper
              ? qual.qualDeveloper.moeNumber + ' | ' + qual.qualDeveloper.organisationName
              : undefined
          });
          break;
        case RecordTypeEnum.Standard:
          let standard = x.record as Standard;

          headerDetails = new RecordDetails({
            type: RecordTypeEnum.Standard,
            stdType: standard.stdType,
            recordTitle: standard.title,
            stdNo: standard.stdNo,
            ssb: standard.ssb,
            stdStatus: standard.status
          });
          break;
      }
      this.setRecordDetails(headerDetails);
    });
  }

  setRecordDetails(record: RecordDetails | undefined) {
    this.recordDetails = record;
  }

  ngOnDestroy() {
    this.$updateHeader?.unsubscribe();
  }
}
